.scrollmagic-pin-spacer {
  &:not(:nth-child(2)) {
    .scrollinner.for_ci {
      inset: 80px auto auto !important;
    }
  }
}
.frame {
  &-container {
    &__inner {
      &#fixedScroll_1 {
        height: 100% !important;
        .wrap {
          &[aria-for="section-1"] {
            height: 100%;
            background-color: $--konai-white;
          }
          &.hidden {
            * {
              display: none !important;
            }
          }
        }
      }
    }
    &.for_ci {
      .frame-container-bg {
        height: 100%;
        top: 0;
        background-color: $--konai-white;
        &.active {
          background-color: $--konai-black;
          transition: background-color 0.1s 0.65s ease-in-out;
          &:before {
            opacity: 1;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.dimmed {
          &:before {
            opacity: 0.4;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.fixed {
          position: fixed;
          top: 0;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 0;
          opacity: 0;
          transition: opacity 0.65s ease-in-out;
        }
        &.section {
          &_1 {
            &.type {
              &_1 {
                &:before {
                  background-image: var(--img-ci-bg);
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
        }
      }
      div[class*='sec-'] {
        padding: 0;
        background-color: transparent;
      }
      &.scrollinner {
        height: 100%;
        top: -80px;
        .flex {
          .wrap {
            position: absolute;
            display: block;
            width: auto;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .text_wrap {
              * {
                color: $--konai-white;
              }
              .main_text {
                font-weight: 700 !important;
                span {
                  font-weight: 700 !important;
                }
              }
            }
            &[aria-for='section-2'] {
              white-space: nowrap;
            }
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
    &.ci_cont_01 {
      background-color: $--konai-red;
      height: 100vh;
      .svg_box {
        .ci_line_01 {
          position: absolute;
          top: 0;
          left: 0;
        }

        .ci_line_02 {
          position: absolute;
          left: 0;
        }

        .ci_line_03 {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      .frame-container__inner {
        .wrap {
          position: absolute;
          display: block;
          width: auto;
          height: auto;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .text_wrap {
            .main-title {
              span {
                color: $--konai-white;
              }
            }
          }
        }
      }
    }
    &.ci_cont_02 {
      height: auto;
      background-color: $--konai-white;
      .frame-container__inner {
        .wrap {
          width: 100%;
          height: 100%;
          padding-top: min(180rem, min(8.375vw, 15vh));
          padding-left: 18.75vw;
          padding-right: 18.75vw;
          text-align: left;
          .main-subpoint {
            text-align: left;
          }
          .main-title {
            text-align: left;
            margin: 5px 0 35px;
          }
          .main-text {
            text-align: left;
          }
          .svg_box {
            svg {
              margin: 10.4vw 0 0;
              width: 100%;
            }
          }

        }
      }
    }
    &.ci_cont_03 {
      top: -1px;
      background-color: $--konai-white;
      height: auto;
      padding-bottom: min(180rem, min(8.375vw, 15vh));
      .frame-container__inner {
        .wrap {
          width: 100%;
          height: 100%;
          padding-top: min(180rem, min(8.375vw, 15vh));
          padding-left: 18.75vw;
          text-align: left;
          .main-subpoint {
            text-align: left;
          }
          .main-title {
            text-align: left;
            margin: 5px 0 35px;
          }
          .main-text {
            text-align: left;
          }
          .svg_box {
            svg {
              margin: 10.4vw 0 0;
              width: 100%;
            }
          }
          .color_wrap {
            .color_box {
              height: 90px;

              &.color_box:nth-child(1) {
                background-color: #ff3c42;
              }

              &.color_box:nth-child(2) {
                background-color: #FF6D72;
              }

              &.color_box:nth-child(3) {
                background-color: #FF9DA0;
              }

              &.color_box:nth-child(4) {
                background-color: #FFD8D9;
              }

              &.color_box:nth-child(5) {
                background-color: #FFFFFF;

                .inner {
                  &>div {
                    color: #000000;
                  }
                }
              }

              &.color_box:nth-child(6) {
                background-color: $--konai-black;
              }

              &.color_box:nth-child(7) {
                background-color: #5B5A5A;
              }

              &.color_box:nth-child(8) {
                background-color: #848383;
              }

              &.color_box:nth-child(9) {
                background-color: #ADACAC;
              }

              &.color_box:nth-child(10) {
                background-color: #DEDEDE;
              }

              &.color_box:nth-child(11) {
                background-color: #405CFF;
              }

              .inner {
                display: flex;
                height: 100%;
                align-items: flex-start;
                padding: 7px;

                &>div {
                  color: #FFFFFF;
                  font-size: 18rem;
                  font-weight: 900;
                  line-height: 1;

                  &:first-child {
                    flex: 1;
                  }

                  flex: 0.3;
                }
              }
            }
          }
        }
      }
    }
    &.ci_cont_04 {
      //margin-top: -80px;
      height: 100vh;
      background-color: $--konai-black;
      //overflow: hidden;
      .typing {
        font-family: 'Konai', sans-serif;
        position: absolute;
        width: 100%;
        max-width: 1400rem;
        height: 200px;
        bottom: 0;
        left: 50%;
        color: #ff3c42;
        //transform: translate(0, 0);
        transform: translate(-126rem, 0);
        .text::after {
          content: '';
          margin-left: .4rem;
          border-right: 2px solid #777;
          animation: cursor .9s infinite steps(2);
        }
        h1 {
          position: relative;
          display: inline-block;
          font-size: 80rem;
          font-weight: 400;
          line-height: 1.25;
          word-break: break-word;
        }
      }
      .frame-container__inner {
        .wrap_2 {
          width: 100%;
          height: 100%;
          margin: 0 0 100rem;
          padding-top: min(180rem, min(8.375vw, 15vh));
          padding-left: 18.75vw;
          text-align: left;
          &.wrap {
            position: relative;
            height: auto;
            top: 0;
            left: 0;
            margin-top: 0;
            margin-bottom: 100rem;
            padding-bottom: 100rem;
            transform: unset !important;
          }
          .br_m{
            display: none;
          }
          .main-subpoint {
            text-align: left;
          }
          .main-title {
            text-align: left;
            margin: 5px 0 35px;
            color: #ffffff;
          }
          .main-text {
            text-align: left;
            color: #ffffff;
          }
        }
        .wrap {
          width: 100%;
          position: absolute;
          top: calc(50% + 7vw);
          left: 50%;
          transform: translate(-50%, -50%);
          padding-left: 18.75vw;
          &.type_1, &.type_2, &.type_3 {
            transform: translate(-50%, -100%);
          }
          .text_wrap {
            text-align: left;
          }
        }
      }
    }
    &.ci_cont_05 {
      //margin-top: -80px;
      height: auto;
      min-height: 100vh;
      background-color: $--konai-black;
      //overflow: hidden;
      padding-bottom: 10vw;
      .frame-container__inner {
        background-color: $--konai-black;
        .wrap_2 {
          width: 100%;
          height: 100%;
          margin-bottom: 100rem;
          padding-top: min(180rem, min(8.375vw, 15vh));
          padding-left: 18.75vw;
          text-align: left;
          &.wrap {
            position: relative;
            height: auto;
            top: 0;
            left: 0;
            margin-top: 0;
            margin-bottom: 100rem;
            transform: unset !important;
          }
          .main-subpoint {
            text-align: left;
          }
          .main-title {
            text-align: left;
            margin: 5px 0 35px;
            color: #ffffff;
          }
          .main-text {
            text-align: left;
            color: #ffffff;
          }
        }
        .wrap {
          width: 100%;
          position: absolute;
          margin: 0;
          //bottom: calc(5% - 1vw);
          bottom: 0;
          left: 50%;
          //transform: translate(-50%, -50%);
          transform: translate(-50%, 0);
          padding-left: 18.75vw;
          &.type_1 {
            & > .text_wrap {
              //transform: translate(0, min(60px, 6.2vw));
            }
            svg {
              position: relative;
            }
          }
          &.type_2 {
            & > .text_wrap {
              //transform: translate(0, min(60px, 6.2vw));
            }
            svg{
              position: relative;
            }
          }
          &.type_3, &.type_4 {
            padding-left: 0;
            .text_wrap {
              text-align: center;
              & > div {
                width: 100vw;
              }
            }
          }
          &.type_5 {
            position: relative;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform: translate(0%, 0%);
            text-align: center;
            //margin: 0 auto;
            & > div {
              width: 100vw;
            }
            #svgContainer {
              margin: 4.5vw 0 180rem;
            }
          }
          .text_wrap {
            text-align: left;
          }
        }
      }
    }
    &.ci_cont_06{
      //margin-top: -80px;
      background-color: $--konai-white;
      height: auto;
      .frame-container__inner {
        background-color: $--konai-white;
      }
      .wrap{
        .title{
          padding: 180px 0;
          .main-subpoint {
            font-size: clamp(28px, 3vw, 56px);
          }
          .main-text {
            margin-top: clamp(20px, 2vw, 30px);
            font-size: clamp(16px, 3vw, 18px);
          }
        }
        img{
          width: 100%;
          vertical-align: top;
        }
        .gulde_down{
          &.link{
            .box{
              background-color: $--konai-black;
              .inner{
                &:first-child{
                  a{
                  }
                }
                &:last-child{
                  a{
                  }
                }
              }
            }
          }
          .box{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FF3C42;
            padding: 40px 0;
            position: relative;
            .inner{
              flex: 1;
              padding: 16px 0;
              &::after{
                content: "";
                display: block;
                position: absolute;
                width: 1px;
                height: 92px;
                background-color: $--konai-gray-6;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              a{
                display: flex;
                justify-content: center;
                align-items: center;
                div{

                  img{
                    width: 80px;
                    height: 80px;
                  }
                  h3{
                    display: block;
                    width: 139px;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 28px;
                    color: #ffffff;
                  }
                  p{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 18px;
                    color: #ffffff;
                    margin: 10px 0 0;
                  }
                  &:first-child{}
                  &:last-child{margin-left: 36px;}
                }
              }
            }
          }
        }
      }
    }
  }
}

circle {
  stroke-dasharray: 3141.59;
  stroke-dashoffset: 3141.59;
  transition: stroke-dashoffset 1s; // 부드러운 애니메이션 효과를 위해
}

@keyframes cursor {
  from {
    border-right: 2px solid #000;
  }

  to {
    border-right: 2px solid #fff;
  }
}


// 미디어쿼리 @media
@import './pages/ci_media';