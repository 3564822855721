.frame {
  &-contents {
    &.for_customer {
      * {
        font-family: $--font-family-Pretendard;
      }
      .flex {
        width: 100%;
        height: auto;
        & > .block_wrap {
          width: 100%;
          max-width: 1200rem;
          margin: 0 auto;
          padding: 180rem 0;
          .main {
            &-title {
              width: 100%;
              height: auto;
              margin-bottom: 100rem;
              font-size: 56rem;
              font-weight: 800;
              line-height: 1.29;
              text-align: center;
            }
            &-text {
              font-family: "Pretendard", sans-serif;
              width: 100%;
              height: auto;
              font-size: 28rem;
              font-weight: 800;
              line-height: 1.29;
              text-align: left;
              &:not(:first-child):not(.description) {
                margin-top: 120rem;
              }
              &:not(:last-child):not(.description) {
                margin-bottom: 45rem;
              }
              &.description {
                display: block;
                margin-top: 16rem;
                font-family: "Pretendard", sans-serif;
                font-size: 16rem;
                font-weight: 500;
                line-height: 1.75;
                letter-spacing: -0.2px;
                text-align: left;
                color: $--konai-gray-3;
                &:not(:last-child) {
                  margin-bottom: 0;
                }
                span {
                  display: block;
                  width: 100%;
                }
              }
            }
          }
          ul {
            display: inline-flex;
            flex-wrap: wrap;
            width: 100%;
            height: auto;
            li {
              flex: 1 1 40%;
              display: inline-flex;
              align-items: center;
              width: 50%;
              height: 80rem;
              padding: 15rem 10rem;
              border-top: 1px solid $--konai-gray-4;
              border-bottom: 1px solid $--konai-gray-4;
              &:nth-child(2n-1) {
                margin-right: 20rem;
                &:last-child {
                  max-width: calc(50% - 20rem);
                }
              }
              &:nth-child(2n) {
                margin-left: 20rem;
              }
              &:nth-child(2n-1):not(:nth-child(1)) {
                border-top: 0;
              }
              &:nth-child(2n):not(:nth-child(2)) {
                border-top: 0;
              }
              span {
                width: 50%;
                font-size: 18rem;
                font-weight: 500;
                line-height: 1.44;
                text-align: left;
                &:not(:first-child) {
                  font-size: 18rem;
                  font-weight: 800;
                  line-height: 1.78;
                }
              }
            }
          }
        }
      }
    }
  }
}


// MO SET
@media screen and (max-width: 1239px) {
  .frame {
    &-contents {
      &.for_customer {
        .flex {
          & > .block_wrap {
            max-width: 100%;
            margin: 0 auto;
            padding: 120rem 24rem;
            .main {
              &-title {
                margin-bottom: 70rem;
                font-size: 34rem;
                font-weight: 800;
                line-height: 1.29;
              }
              &-text {
                font-family: "Pretendard", sans-serif;
                font-size: 22rem;
                font-weight: 800;
                line-height: 1.36;
                &:not(:first-child):not(.description) {
                  margin-top: 70rem;
                }
                &:not(:last-child):not(.description) {
                  margin-bottom: 38rem;
                }
                &.description {
                  margin-top: 12rem;
                  font-size: 14rem;
                  font-weight: 500;
                  line-height: 1.72;
                  letter-spacing: -0.2px;
                }
              }
            }
            ul {
              flex-direction: column;
              li {
                flex: 1;
                width: 100%;
                height: 50rem;
                padding: 10rem;
                &:nth-child(2n-1) {
                  margin-right: 0;
                  &:last-child {
                    max-width: 100%;
                  }
                }
                &:nth-child(2n) {
                  margin-left: 0;
                }
                &:nth-child(2n-1):not(:nth-child(1)), &:nth-child(2) {
                  border-top: 0;
                }
                span {
                  width: 50%;
                  font-size: 16rem;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: -0.4px;
                  &:not(:first-child) {
                    font-size: 16rem;
                    font-weight: 800;
                    line-height: 1.38;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}