@media screen and (min-width: 1100px) and (max-width: 1239px) {
}

// MO SET
@media screen and (max-width: 1239px){
  .frame {
    &-container {
      &.for_ci {
        .frame-container-bg {
          &.section {
            &_1 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-ci-bg-mo);
                  }
                }
              }
            }
          }
        }
        &.scrollinner {
          height: 100%;
          top: -60px;
          .flex {
            .wrap {
              width: calc(100% - 48rem);
              .text_wrap {
              }
              .img_wrap {
              }
            }
          }
        }
        .frame-container__inner{
          .flex {
            .wrap{
              .text_wrap{
                .main-text.fs-23{
                  font-weight: 700;
                }
              }
            }
          }
        }
        .for_pc {
          display: none !important;
        }
        .for_mo {
          display: block !important;
        }
      }
      &.ci_cont_01 {
      }
      &.ci_cont_02 {
        height: auto;
        .frame-container__inner {
          .wrap {
            padding: 120rem 24rem 35rem;
            .main-title {
              margin: 5rem 0 22rem;
            }
            .svg_box {
              svg{
                margin: 5vw 0 0;
              }
            }
          }
        }
      }
      &.ci_cont_03 {
        padding-bottom: 120rem;
        .frame-container__inner {
          .wrap {
            padding: 120rem 24rem 0rem;
            &:last-child{
              padding: 80rem 0rem 0rem 24rem;
            }
            .main-title {
              margin: 5rem 0 22rem;
            }
            .color_wrap {
              .color_box{
                height: 55rem;
                .inner{
                  & > div{
                    &:first-child{
                      display: block;
                    }
                    display: none;
                    font-size: 16rem;
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
        & + div {
          & + div {
            top: -1px;
          }
        }
      }
      &.ci_cont_04 {
        .frame-container__inner{
          .wrap{
            padding: 0 24rem;
            .text_wrap{
              svg{
                width: 100%;
              }
            }
            &.type_1, &.type_2, &.type_3 {
              .text_wrap {
                position: relative;
                height: 100%;
                div {
                  height: 100%;
                  svg {
                    max-height: 100%;
                  }
                }
              }
            }
            &.type_1 {
              top: unset;
              bottom: 0;
              transform: translate(-50%, calc(-120% - 22px));
            }
            &.type_2 {
              top: unset;
              bottom: 0;
              transform: translate(-50%, calc(-120% - 22px));
            }
            &.type_3 {
              top: unset;
              bottom: 0;
              //transform: translate(-50%, -120%);
              transform: translate(-50%, calc(-120% - 22px));
              //svg {
              //  transform: translate(0, -20%);
              //}
            }
            &.type_4 {
              transform: translate(-28rem, 0);
              top: unset;
              bottom: 0;
            }
          }
          .wrap_2 {
            padding: min(120rem, 14.6341vh) 24rem 0;
            margin: 0 0 80rem;
            &.wrap {
              margin-bottom: 80rem;
              padding-bottom: 80rem;
            }
            .br_m{
              display: block;
            }
            .main-title{
              margin: 5px 0 22px;
            }
          }
        }
        .typing {
          height: 94px;
          transform: translate(-28rem, 0);
          h1{
            font-size: 25rem;
          }
        }
      }
      &.ci_cont_05 {
        height: 100%;
        .frame-container__inner{
          .wrap{
            padding: 0 24rem;
            .text_wrap{
              svg{
                width: 100%;
              }
              img{
                width: 100%;
              }
            }
            &.type_1, &.type_2, &.type_3, &.type_4 {
              .text_wrap {
                position: relative;
                height: 100%;
                div {
                  height: 100%;
                  svg {
                    max-height: 100%;
                  }
                }
              }
            }
            &.type_1 {
              position: absolute;
              top: unset;
              bottom: 0;
              transform: translate(-50%, -120%);
              svg{
                left: 0;
                max-height: 100%;
              }
            }
            &.type_2 {
              position: absolute;
              top: unset;
              bottom: 0;
              transform: translate(-50%, -120%);
              svg{
                left: 0;
                max-height: 100%;
              }
            }
            &.type_3 ,&.type_4 {
              position: absolute;
              padding: 0 24rem;
              top: unset;
              bottom: 0;
              transform: translate(-50%, -120%);
              .text_wrap {
                & > div {
                  width: calc(100vw - 48rem);
                }
              }
            }
            &.type_5 {
              & > div {
                width: calc(100vw - 48rem);
              }
              #svgContainer {
                margin: 4.5vw auto 120rem;
              }
            }
          }
          .wrap_2 {
            padding: min(120rem, 14.6341vh) 24rem 0;
            margin: 0 0 150rem;
            &.wrap {
              margin-bottom: 150rem;
            }
            .br_m{
              display: block;
            }
            .main-title{
              margin: 5px 0 22px;
            }
          }
        }
      }
      &.ci_cont_04, &.ci_cont_05 {
        .frame-container__inner{
          .wrap{
            &.type_1, &.type_2, &.type_3, &.type_4 {
              margin: unset;
            }
          }
        }
      }
      &.ci_cont_06 {
        // inset: 60px auto auto !important;
        .wrap {
          .title{
            padding: 120rem 24rem;
          }
          .gulde_down{
            &.link{
              .box {
                .inner{
                  &:first-child{
                    a{
                      margin: 0 auto;
                    }
                  }
                  &:last-child{
                    a{
                      margin: 0 auto;
                    }
                  }
                }
              }
            }
            .box{
              padding: 11px 0;
              display: block;
              .inner{
                a{
                  width: 200px;
                  margin: 0 auto;
                  justify-content: flex-start;
                  div{
                    h3{
                      font-size: 18rem;
                      font-weight: 700;
                    }
                    p{
                      font-size: 12rem;
                      margin: 5px 0 0;
                    }
                    &:last-child{
                      margin-left: 20px;
                    }
                    img{
                      width: 60px;
                      height: 60px;
                    }
                  }
                }
                &::after{
                  width: 80%;
                  height: 1px;
                }

                &:first-child{
                  padding: 16px 0 25px;
                  a{
                    margin:0 auto;
                  }
                }
                &:last-child{
                  padding: 25px 0 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// MO SET(SMALL)
@media screen and (max-height: 640px) {
  .frame {
    &-container {
      &.ci_cont_04, &.ci_cont_05 {
        .frame-container__inner{
          .wrap{
            &.type_1, &.type_2, &.type_3, &.type_4 {
            }
          }
        }
      }
    }
  }
}

@media screen and (min-height: 641px) and (max-height: 900px){
  .frame {
    &-container {
      &.ci_cont_04 {
        .frame-container__inner{
          .wrap{
            &.type_1, &.type_2, &.type_3, &.type_4 {
              top: unset;
              bottom: 0;
              transform: translate(-50%, calc(-80% - 92px));
              svg {
                max-width: 100%;
                //max-height: 256px;
              }
            }
            &.type_3 {
              //transform: translate(-50%, calc(-80% - 62px));
            }
            &.type_4 {
              scale: 0.8;
              &.typing {
                h1 {
                  font-size: 60rem !important;
                }
              }
            }
          }
        }
      }
      &.ci_cont_05 {
        .frame-container__inner{
          .wrap{
            &.type_1, &.type_2, &.type_3, &.type_4 {
              top: unset;
              bottom: 0;
              transform: translate(-50%, calc(-80% - 22px));
              svg {
                max-width: 100%;
              }
            }
            &.type_2 {
              //transform: translate(-50%, calc(-80% - 80px));
            }
          }
        }
      }
    }
  }
}
