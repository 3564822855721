.scrollmagic-pin-spacer {
  &:not(:nth-child(2)) {
    .scrollinner.for_smartcard {
      inset: 80px auto auto !important;
    }
  }
}
.frame {
  &-container {
    &__inner {
      &#fixedScroll_1 {
        height: 100% !important;
        .wrap {
          &[aria-for="section-1"] {
            height: 100%;
          }
          &.hidden {
            * {
              display: none !important;
            }
          }
        }
      }
    }
    &.for_smartcard {
      .frame-container-bg {
        height: 100%;
        top: 0;
        &.active {
          background-color: $--konai-black;
          transition: background-color 0.1s 0.65s ease-in-out;
          &:before {
            opacity: 1;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.dimmed {
          &:before {
            opacity: 0.4;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.fixed {
          position: fixed;
          top: 0;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 0;
          opacity: 0;
          transition: opacity 0.65s ease-in-out;
        }
        &.section {
          &_1 {
            &.type {
              &_1 {
                &:before {
                  background-image: var(--img-smartcard-bg);
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
        }
      }
      div[class*='sec-'] {
        padding: 0;
        background-color: transparent;
      }
      &.scrollinner {
        height: 100%;
        top: -80px;
        .flex {
          .wrap {
            position: absolute;
            display: block;
            width: auto;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .text_wrap {
              * {
                color: $--konai-white;
              }
            }
            &[aria-for='section-2'] {
              white-space: nowrap;
            }
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
  }
  &-contents {
    &__inner {
      &.for_smartcard {
        padding: 0;
        & > .flex.vert {
          width: 100%;
          max-width: unset;
          margin: 0;
        }
        .img_wrap {
          .img {
            display: inline-block;
            object-fit: contain;
            &.smart {
              &-card {
                &-01 {
                  display: inline-block;
                  width: 765rem;
                  height: 566rem;
                  content: var(--img-smartcard-01);
                }
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-3'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            background-color: $--konai-white;
            .text_wrap {
              max-width: 1200rem;
              margin: 0 auto;
              text-align: center;
              & > * {
                text-align: center;
              }
              .main {
                &-subtitle {
                  margin-bottom: 20rem;
                }
                &-text {
                  &:not(:first-child) {
                    marign-top: 50rem;
                  }
                }
              }
            }
          }
          &[aria-for='section-4'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            &:before {
              content: '';
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: -1;
              background-color: $--konai-lightgray-3;
            }
            .block_wrap {
              max-width: 1200rem;
              margin: 0 auto 120rem;
              .img_wrap {
                width: auto;
                .img {
                  display: inline-block;
                  object-fit: contain;
                  width: 404rem;
                  height: 256rem;
                  &.smartcard {
                    &-slide-1 {
                      &-01 {
                        content: var(--img-smartcard-02);
                      }
                      &-02 {
                        content: var(--img-smartcard-03);
                      }
                      &-03 {
                        content: var(--img-smartcard-04);
                      }
                      &-04 {
                        content: var(--img-smartcard-05);
                      }
                      &-05 {
                        content: var(--img-smartcard-06);
                      }
                      &-06 {
                        content: var(--img-smartcard-07);
                      }
                    }
                  }
                }
              }
              .wrap {
                display: inline-block;
                width: 100%;
                margin-top: 0;
                margin-left: 81rem;
                &-title {
                  font-size: 32rem;
                  font-weight: 800;
                  line-height: 1.31;
                  letter-spacing: normal;
                  color: $--konai-black;
                }
                &-text {
                  font-size: 18rem;
                  font-weight: 400;
                  line-height: 1.67;
                  letter-spacing: -0.2px;
                  color: $--konai-black;
                  &:not(:first-child) {
                    margin-top: 20rem;
                  }
                }
              }
              .card {
                &-wrap {
                  display: flex;
                  align-items: center;
                  max-width: 1200rem;
                  margin: 120rem auto 0;
                  padding: 0;
                  .swiper {
                    margin: 0 36rem;
                    padding-bottom: 4rem;
                    overflow-y: visible;
                    &-slide {
                      display: flex;
                      width: 1068rem;
                      height: 422rem;
                      padding: 80rem;
                      background-color: $--konai-white;
                      border-radius: 12rem;
                      box-shadow: 0 10px 30px rgba(0,0,0,0.1);
                    }
                    &-button {
                      &-prev {
                        width: 40rem;
                        height: 40rem;
                        background-image: var(--swiper-arr-l-g);
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        &:after {
                          display: none;
                        }
                      }
                      &-next {
                        width: 40rem;
                        height: 40rem;
                        background-image: var(--swiper-arr-r-g);
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        &:after {
                          display: none;
                        }
                      }
                    }
                    &-scrollbar {
                      position: absolute;
                      display: inline-block;
                      width: 100%;
                      height: auto;
                      bottom: 0;
                    }
                  }
                }
                &-elem {
                  width: 908rem;
                  max-height: 262rem;
                  padding: 80rem;
                  background-color: $--konai-white;
                  border-radius: 12rem;
                  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
                  .img_wrap {
                    display: inline-block;
                    width: 404rem;
                    min-width: 404rem;
                    height: 256rem;
                    .img {
                      display: inline-block;
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                }
              }
            }
          }
          &[aria-for='section-5'] {
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            background-color: $--konai-white;
            .block_wrap {
              max-width: 1200rem;
              margin: 0 auto 120rem;
              .img_wrap {
                .img {
                  display: inline-block;
                  object-fit: contain;
                  width: 282rem;
                  height: 446rem;
                  &.smartcard {
                    &-slide-2 {
                      &-01 {
                        content: var(--img-smartcard-08);
                      }
                      &-02 {
                        content: var(--img-smartcard-09);
                      }
                      &-03 {
                        content: var(--img-smartcard-10);
                      }
                    }
                  }
                }
              }
              .card {
                &-wrap {
                  width: 100%;
                  max-width: 1200rem;
                  margin: 120rem 0 0;
                  padding: 0;
                  .swiper {
                    margin: 0;
                    padding-bottom: 4rem;
                    &-slide {
                      //width: 282rem;
                      //max-width: 282rem;
                    }
                  }
                }
                &-elem {
                  width: 282rem;
                  height: auto;
                  padding: 0;
                  border-radius: 0;
                }
              }
              .tag {
                margin-top: 30rem;
                font-size: 16rem;
                font-weight: 700;
              }
              .wrap {
                display: inline-block;
                width: 100%;
                margin-left: 81rem;
                &-title {
                  margin-top: 50rem;
                  padding-bottom: 12rem;
                  border-bottom: 1px solid $--konai-gray-4;
                  font-size: 32rem;
                  font-weight: 800;
                  line-height: 1.31;
                  letter-spacing: normal;
                  color: $--konai-black;
                  text-align: left;
                }
                &-text {
                  font-size: 18rem;
                  font-weight: 400;
                  line-height: 1.67;
                  letter-spacing: -0.2px;
                  color: $--konai-black;
                  text-align: left;
                  word-break: keep-all;
                  &:not(:first-child) {
                    margin-top: 20rem;
                  }
                }
              }
            }
          }
          &[aria-for='section-6'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            &:before {
              content: '';
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: -1;
              background-color: $--konai-lightgray-3;
            }
            .block_wrap {
              & > .text_wrap {
                max-width: 1200rem;
                margin: 0 auto;
                text-align: left;
                * {
                  text-align: left;
                }
              }
              .card {
                &-wrap {
                  margin: 0 auto;
                  padding: 0;
                  .swiper {
                    margin: 120rem 0 0;
                    &-slide {
                      width: 100%;
                      height: 400rem;
                      z-index: 1;
                    }
                    &-pagination {
                      position: relative;
                      display: inline-block;
                      width: 100%;
                      height: auto;
                      margin: 0 0 60rem;
                      top: 0;
                      bottom: unset;
                      text-align: left;
                      &:after {
                        content: '';
                        position: absolute;
                        display: inline-block;
                        width: 100%;
                        height: 1px;
                        left: 0;
                        bottom: 0;
                        background-color: $--konai-gray-4;
                        z-index: -1;
                      }
                      &-bullet {
                        position: relative;
                        display: inline-flex;
                        flex-direction: column;
                        justify-self: center;
                        width: auto;
                        height: 34rem;
                        font-size: 18rem;
                        //font-weight: 500;
                        font-weight: 700;
                        line-height: 1.44;
                        letter-spacing: normal;
                        color: $--konai-gray-3;
                        background: unset;
                        cursor: pointer;
                        opacity: 1;
                        user-select: none;
                        transition: color 0.3s ease-in-out;
                        &:after {
                          content: '';
                          position: absolute;
                          display: inline-block;
                          width: 100%;
                          max-width: 0;
                          height: 2px;
                          left: 0;
                          bottom: 0;
                          background-color: $--konai-red;
                          margin: 8rem 0 0;
                          transition: max-width 0.3s ease-in-out;
                        }
                        &:first-child {
                          margin-left: 0;
                        }
                        &:not(:last-child) {
                          margin-right: 40rem;
                        }
                        &-active {
                          font-weight: 700;
                          color: $--konai-black;
                          &:after {
                            max-width: 100%;
                          }
                        }
                      }
                    }
                  }
                }
                &-elem {
                  display: flex;
                  width: 100%;
                  height: auto;
                  min-height: 340rem;
                  padding: 0;
                  border-radius: 0;
                  .img_wrap {
                    width: auto;
                    margin: 0 58rem 0 auto;
                    .img {
                      display: inline-block;
                      object-fit: contain;
                      width: 404rem;
                      height: 257rem;
                      &.smartcard {
                        &-slide-3 {
                          &-01 {
                            content: var(--img-smartcard-11);
                          }
                          &-02 {
                            content: var(--img-smartcard-12);
                          }
                          &-03 {
                            content: var(--img-smartcard-13);
                          }
                          &-04 {
                            content: var(--img-smartcard-14);
                          }
                        }
                      }
                    }
                  }
                  .text_wrap {
                    max-width: 510rem;
                    text-align: left;
                    .wrap {
                      &-title {
                        font-size: 32rem;
                        font-weight: 800;
                        line-height: 1.31;
                        letter-spacing: normal;
                        color: $--konai-black;
                        text-align: left;
                        word-break: keep-all;
                      }
                      &-text {
                        font-size: 18rem;
                        font-weight: 400;
                        line-height: 1.67;
                        letter-spacing: -0.2px;
                        color: $--konai-black;
                        text-align: left;
                        word-break: keep-all;
                        &:not(:first-child) {
                          margin-top: 32rem;
                        }
                      }
                    }
                    .tag {
                      font-size: 14rem;
                      font-weight: 700;
                      line-height: 1.36;
                      &:not(:first-child) {
                        margin-top: 50rem;
                      }
                    }
                  }
                }
              }
            }
          }
          &[aria-for='section-7'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            background-color: $--konai-white;
            .text_wrap {
              max-width: 1200rem;
              margin: 0 auto;
              text-align: center;
              & > * {
                text-align: center;
              }
              .main {
                &-title {
                  font-size: 48rem;
                  font-weight: 800;
                  line-height: 1.25;
                }
                &-text {
                  //font-size: 22rem;
                  //line-height: 1.64;
                  &:not(:first-child) {
                    marign-top: 50rem;
                  }
                }
              }
            }
            .img_wrap {
              width: 100%;
              max-width: 1200rem;
              height: 400rem;
              margin: 120rem auto 0;
              text-align: center;
              .img {
                display: inline-block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                &.smartcard {
                  &-cont {
                    &-01 {
                      content: var(--img-smartcard-15);
                    }
                  }
                }
              }
            }
          }
        }
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
    }
  }
}


// 미디어쿼리 @media
@import './pages/smartcard_media';