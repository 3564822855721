// MO SET(SMALL)
@media screen and (max-width: 340px) {
}

@media screen and (min-width: 1100px) and (max-width: 1239px) {
}

// MO SET
@media screen and (max-width: 1239px) {
  .scrollmagic-pin-spacer {
    &:not(:nth-child(2)) {
      .scrollinner.for_smartcard {
        inset: 60px auto auto !important;
      }
    }
  }
  .frame {
    &-container {
      &.for_smartcard {
        .frame-container-bg {
          &.section {
            &_1 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-smartcard-bg-mo);
                  }
                }
              }
            }
          }
        }
        &.scrollinner {
          height: 100%;
          top: -60px;
          .flex {
            .wrap {
              width: calc(100% - 48rem);
              .text_wrap {
              }
              .img_wrap {
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-2'] {
            .text_wrap {
              .main-text {
                white-space: normal;
                &.fs-23 {
                  font-weight: 700;
                }
              }
            }
          }
        }
        .for_pc {
          display: none !important;
        }
        .for_mo {
          display: block !important;
        }
      }
    }
    &-contents {
      &__inner {
        &.for_smartcard {
          .wrap {
            &[aria-for='section-3'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: 100%;
                max-width: unset;
                .text_wrap {
                  .main {
                    &-subtitle {
                      margin-bottom: 15rem;
                      font-size: 16rem;
                      font-weight: 800;
                      line-height: 1;
                    }
                    &-title {
                      font-size: 24rem;
                      font-weight: 800;
                      line-height: 1.42;
                      &:not(:last-child) {
                        margin-bottom: 36rem;
                      }
                    }
                    &-text {
                      font-size: 16rem;
                      font-weight: 400;
                      line-height: 1.5;
                      letter-spacing: -0.2px;
                    }
                  }
                }
                .img_wrap {
                  margin: 36rem 0;
                  width: 100%;
                  max-width: unset;
                  height: 240rem;
                  .img {
                    &.smart {
                      &-card {
                        &-01 {
                          display: inline-block;
                          width: 100%;
                          height: auto;
                          max-height: 566rem;
                          content: var(--img-smartcard-01);
                        }
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-4'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: 100%;
                max-width: unset;
                margin: 0;
                .card {
                  &-wrap {
                    width: 100%;
                    max-width: unset;
                    margin-top: 74rem;
                    .swiper {
                      width: calc(100% + 48rem);
                      margin: 0 -24rem;
                      &-slide {
                        flex-direction: column;
                        width: 296rem;
                        height: auto;
                        min-height: 487rem;
                        padding: 28rem;
                        .img_wrap {
                          .img {
                            width: 100%;
                            max-width: 100%;
                            height: 149rem;
                          }
                        }
                        .wrap {
                          margin: 36rem 0 0;
                          &-title {
                            font-size: 24rem;
                            line-height: 1.42;
                            text-align: left;
                          }
                          &-text {
                            font-size: 16rem;
                            line-height: 1.5;
                            letter-spacing: -0.2px;
                            text-align: left;
                            word-break: keep-all;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-5'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: 100%;
                max-width: unset;
                margin: 0;
                .card {
                  &-wrap {
                    width: 100%;
                    max-width: unset;
                    margin-top: 74rem;
                    .swiper {
                      width: calc(100% + 48rem);
                      margin: 0 -24rem;
                      &-slide {
                        flex-direction: column;
                        width: 296rem;
                        height: auto;
                        min-height: 487rem;
                        padding: 0 20rem;
                        text-align: center;
                        .img_wrap {
                          height: 253rem;
                          .img {
                            width: 100%;
                            max-width: 100%;
                            height: 253rem;
                          }
                        }
                        .wrap {
                          margin: 36rem 0 0;
                          &-title {
                            font-size: 24rem;
                            line-height: 1.42;
                            text-align: center;
                          }
                          &-text {
                            font-size: 16rem;
                            line-height: 1.5;
                            letter-spacing: -0.2px;
                            text-align: left;
                            word-break: keep-all;
                          }
                        }
                        .tag {
                          margin-top: 20rem;
                        }
                      }
                      &-button {
                        &-prev {
                          width: 20rem;
                          height: 20rem;
                          left: 0;
                          background-image: var(--swiper-arr-l-g);
                          background-size: contain;
                          background-position: center;
                          background-repeat: no-repeat;
                          &:after {
                            display: none;
                          }
                        }
                        &-next {
                          width: 20rem;
                          height: 20rem;
                          right: 0;
                          background-image: var(--swiper-arr-r-g);
                          background-size: contain;
                          background-position: center;
                          background-repeat: no-repeat;
                          &:after {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-6'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: 100%;
                max-width: unset;
                margin: 0;
                .text_wrap {
                  .main {
                    &-title {
                      text-align: center;
                    }
                  }
                }
                .card {
                  &-wrap {
                    width: 100%;
                    max-width: unset;
                    margin-top: 74rem;
                    .swiper {
                      width: calc(100% + 48rem);
                      margin: 0 -24rem;
                      &-slide {
                        flex-direction: column;
                        width: 296rem;
                        height: auto;
                        min-height: unset;
                        padding: 0 40rem;
                        text-align: center;
                        .img_wrap {
                          height: 253rem;
                          .img {
                            width: 100%;
                            max-width: 100%;
                            height: 253rem;
                          }
                        }
                        .wrap {
                          margin: 36rem 0 0;
                          &-title {
                            font-size: 24rem;
                            line-height: 1.42;
                            text-align: center;
                          }
                          &-text {
                            font-size: 16rem;
                            line-height: 1.5;
                            letter-spacing: -0.2px;
                            text-align: center;
                            word-break: keep-all;
                          }
                        }
                        .tag {
                          margin-top: 20rem;
                        }
                      }
                      &-button {
                        &-prev {
                          width: 20rem;
                          height: 20rem;
                          left: 0;
                          background-image: var(--swiper-arr-l-g);
                          background-size: contain;
                          background-position: center;
                          background-repeat: no-repeat;
                          &:after {
                            display: none;
                          }
                        }
                        &-next {
                          width: 20rem;
                          height: 20rem;
                          right: 0;
                          background-image: var(--swiper-arr-r-g);
                          background-size: contain;
                          background-position: center;
                          background-repeat: no-repeat;
                          &:after {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                  &-elem {
                    flex-direction: column-reverse;
                    padding: 0 20rem;
                    & > .img_wrap {
                      margin-right: 0;
                      margin-left: 0;
                      max-height: 178rem;
                      text-align: center;
                      .img {
                        width: 100%;
                        height: auto;
                        max-height: 178rem;
                      }
                    }
                    & > .text_wrap {
                      margin-top: 36rem;
                      text-align: left;
                      .wrap {
                        &-title {
                          text-align: left;
                        }
                        &-text {
                          margin-top: 18rem;
                          text-align: left;
                        }
                      }
                      .tag {
                        margin-top: 30rem;
                        padding: 10rem 20rem;
                        font-size: 14rem;
                        font-weight: 700;
                        line-height: 1.36;
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-7'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                .text_wrap {
                  margin: 0 auto 74rem;
                  .main {
                    &-title {
                      font-size: 24rem;
                      font-weight: 800;
                      line-height: 1.42;
                      &:not(:last-child) {
                        margin-bottom: 36rem;
                      }
                    }
                    &-text {
                      font-size: 16rem;
                      font-weight: 400;
                      line-height: 1.5;
                      letter-spacing: -0.2px;
                    }
                  }
                }
                .img_wrap {
                  margin-top: 74rem;
                  width: 100%;
                  max-width: unset;
                  height: 180rem;
                  border-radius: 12rem;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
}
