.sr-only {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

strong {
  font-weight: 700 !important;
}

// FONT COLOR
.f-clr {
  &-point {
    color: $--konai-red !important;
  }
  &-black {
    color: $--konai-black !important;
  }
  &-white {
    color: $--konai-white !important;
  }
  &-increase {
    color: $--konai-increase !important;
  }
  &-decrease {
    color: $--konai-decrease !important;
  }
}

// SWIPER
.swiper {
  &-scrollbar {
    width: calc(100% - 24rem) !important;
    height: 3rem !important;
    left: 0 !important;
    background-color: rgba(0,0,0,0) !important;
    border-radius: 0 !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid $--konai-gray-3 !important;
    &-drag {
      height: 3rem !important;
      border-radius: 0 !important;
      background-color: $--konai-gray-3 !important;
    }
  }
  &-container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

// FONT-MAIN
.main {
  &-point {
    font-family: 'Konai', sans-serif;
    font-size: 74rem;
    font-weight: 400;
    line-height: 1.62;
    color: $--konai-red;
    text-align: center;
    word-break: keep-all;
    &.fs {
      &-80 {
        font-size: 80rem;
        font-weight: 400;
        line-height: 1.25;
      }
      &-56 {
        font-size: 56rem;
        font-weight: 400;
        line-height: 1.28;
      }
    }
  }
  &-subpoint {
    font-family: 'Konai', sans-serif;
    font-size: 28rem;
    font-weight: 400;
    line-height: 1.28;
    color: $--konai-black;
    text-align: center;
    word-break: keep-all;
  }
  &-title {
    font-family: 'Pretendard', sans-serif;
    font-size: 56rem;
    font-weight: 800;
    line-height: 1.28;
    color: $--konai-black;
    text-align: center;
    word-break: keep-all;
    &.fs {
      &-50 {
        font-size: 50rem;
        font-weight: 800;
        line-height: 1.12;
      }
      &-48 {
        font-size: 48rem;
        font-weight: 800;
        line-height: 1.25;
      }
      &-40 {
        font-size: 40rem;
        font-weight: 800;
        line-height: 1.4;
      }
    }
  }
  &-mdtitle {
    font-family: 'Pretendard', sans-serif;
    font-size: 40rem;
    font-weight: 700;
    line-height: 1.4;
    color: $--konai-black;
    text-align: center;
    word-break: keep-all;
  }
  &-subtitle {
    font-family: 'Pretendard', sans-serif;
    font-size: 32rem;
    font-weight: 800;
    line-height: 1.5;
    color: $--konai-black;
    text-align: center;
    word-break: keep-all;
    &.fs {
      &-30 {
        font-size: 30rem;
        font-weight: 800;
        line-height: 1.4;
      }
      &-28 {
        font-size: 28rem;
        font-weight: 800;
        line-height: 1.29;
      }
      &-24 {
        font-size: 24rem;
        font-weight: 600;
        line-height: 1.33;
      }
    }
  }
  &-smtitle {
    font-family: 'Pretendard', sans-serif;
    font-size: 24rem;
    font-weight: 600;
    line-height: 1.5;
    color: $--konai-black;
    text-align: center;
    word-break: keep-all;
  }
  &-text {
    font-family: 'Pretendard', sans-serif;
    font-size: 18rem;
    font-weight: 400;
    line-height: 1.67;
    letter-spacing: -0.2rem;
    color: $--konai-black;
    text-align: center;
    word-break: keep-all;
    &.fs {
      &-24 {
        font-size: 24rem;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.5;
      }
      &-23 {
        font-size: 23rem;
        font-weight: 700;
        line-height: 1.48;
      }
    }
  }
}

// BODY FRAME
.header.fixed {
  & + .frame-container {
    //height: 620px;
    margin-top: 80px;
  }
}
.frame {
  &-container {
    position: relative;
    width: 100%;
    //height: 620px;
    height: 540px;
    padding: 0;
    z-index: 5;
    &.fullpage {
      height: calc(100vh - 80px);
    }
    &-bg {
      position: relative;
      width: 100%;
      height: calc(100% + 80px);
      top: -80px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    &-text {
      position: absolute;
      display: flex;
      width: auto;
      height: 620px;
      //margin-top: -40px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      & > *:first-child:last-child {
        margin: auto 0;
      }
      & > *:first-child:not(:last-child) {
        margin: auto 0 0;
      }
      & > *:last-child:not(:first-child) {
        margin: 0 0 auto;
      }
    }
  }
  &-subpoint {
    font-family: 'Konai', sans-serif;
    font-size: 45rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    text-transform: capitalize;
    color: $--konai-white;
    word-break: keep-all;
    &:not(:last-child) {
      margin-bottom: 20rem;
    }
  }
  &-title {
    font-family: 'Pretendard', sans-serif;
    font-size: 72rem;
    font-weight: 800;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: $--konai-white;
    word-break: keep-all;
    white-space: nowrap;
    max-width: 100%;
  }
  &-contents {
    &.for_terms {
      position: relative;
      display: inline-flex;
      width: 100%;
      height: auto;
    }
    &__inner {
      position: relative;
      display: flex;
      width: 100%;
      height: auto;
      margin: 0;
      //padding: 180rem 200px;
      padding: 180rem calc(100vw * 0.104167);
      z-index: 5;
      &:nth-child(2n-1) {
        background-color: $--konai-white;
      }
      &:nth-child(2n) {
        background-color: $--konai-lightgray-5;
      }
      & > * {
        &:first-child:last-child {
          max-width: 1200rem;
          margin: 0 auto;
        }
      }
      &.with_img {
        & > * {
          &:first-child:last-child {
            display: flex;
            align-items: flex-start;
            & > * {
              &:not(:first-child) {
                margin-left: 100rem;
              }
            }
          }
          .img_wrap {
            width: auto;
            height: auto;
            img {
              display: block;
              width: 550rem;
              height: 420rem;
              border-radius: 12rem;
              object-fit: cover;
              overflow: hidden;
            }
          }
          .text_wrap {
            text-align: left;
            .main {
              &-mdtitle {
                text-align: left;
                &:not(:last-child) {
                  margin-bottom: 20rem;
                }
              }
              &-text {
                text-align: left;
              }
            }
          }
        }
      }
      .main {
        &-title {
          &:not(:last-child) {
            margin-bottom: 50rem;
          }
        }
        &-text {
          &:not(:last-child) {
            margin-bottom: 50rem;
          }
        }
      }
    }
  }
}

// CIRCLE
.circle {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 284rem;
  min-width: 284rem;
  height: 284rem;
  min-height: 284rem;
  border-radius: 50%;
  box-shadow: 0 8px 8px rgba(0,0,0,0.1);
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + 66rem);
    height: calc(100% + 66rem);
    padding: 1px;
    top: -33rem;
    left: -33rem;
    border-radius: 50%;
    background-image: var(--img-universe-circle);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -2;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + 66rem);
    height: calc(100% + 66rem);
    padding: 1px;
    top: -33rem;
    left: -33rem;
    border-radius: 50%;
    background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
    border: 1px solid rgba(255,60,66,0.3);
    z-index: -1;
  }
  &:nth-child(1) {
    z-index: 4;
  }
  &:nth-child(2) {
    z-index: 3;
  }
  &:nth-child(3) {
    z-index: 2;
  }
  &:nth-child(4) {
    z-index: 1;
  }
  &.blurred {
    &:before {
      backdrop-filter: blur(4px);
    }
    &:after {
      backdrop-filter: blur(10px);
    }
  }
  &.bg {
    &-red {
      width: 190rem;
      min-width: 190rem;
      height: 190rem;
      min-height: 190rem;
      border-radius: 50%;
      box-shadow: unset;
      &:before, &:after {
        display: none;
        content: '';
        width: 0;
        height: 0;
      }
      &:nth-child(1) {
        background-color: #F9ADAF;
        z-index: 0;
      }
      &:nth-child(2) {
        background-color: #FE9699;
        transform: translate(-35rem, 0);
        z-index: 1;
      }
      &:nth-child(3) {
        background-color: #FF7E82;
        transform: translate(-70rem, 0);
        z-index: 2;
      }
      &:nth-child(4) {
        background-color: #FF5E62;
        transform: translate(-105rem, 0);
        z-index: 3;
      }
      &:nth-child(5) {
        background-color: $--konai-red;
        transform: translate(-140rem, 0);
        z-index: 4;
      }
      &:nth-child(6) {
        background-color: #F8141B;
        transform: translate(-175rem, 0);
        z-index: 5;
      }
      .circle-title {
        font-size: 24rem;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  & > * {
    &:first-child {
      margin-top: auto;
    }
    &:last-child {
      margin-bottom: auto;
    }
  }
  &-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    width: 1200rem;
    height: 350rem;
    margin: 238rem 0 0;
    padding: 33rem 0;
  }
  &-point {
    font-family: 'Konai', sans-serif;
    font-size: 14rem;
    font-weight: 400;
    line-height: 2.57;
    color: $--konai-gray-3;
    text-align: center;
    span {
      color: $--konai-gray-3 !important;
    }
  }
  &-title {
    font-size: 30rem;
    font-weight: 700;
    line-height: 1.2;
    color: $--konai-white;
    text-align: center;
  }
}

// BUTTON
.btn {
  font-family: 'Pretendard', sans-serif;
  display: inline-block;
  width: auto;
  height: auto;
  &-md {
    display: inline-flex;
    align-items: center;
    height: 50px;
    padding: 14px 24px;
    font-size: 18rem;
    font-weight: 600;
    // line-height: 1.22;
    line-height: 1;
    text-align: center;
    word-break: keep-all;
    &.round {
      border-radius: 50px;
    }
  }
  &-sm {
    display: inline-flex;
    align-items: center;
    height: 39px;
    padding: 10px 20px;
    font-size: 14rem;
    font-weight: 600;
    // line-height: 1.35;
    line-height: 1;
    text-align: center;
    word-break: keep-all;
    &.round {
      border-radius: 39px;
    }
  }
  &-shortcut {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-image: var(--icon-shortcut-w);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &.btn-sm, &.btn-md {
      display: flex;
      align-items: center;
      width: auto;
      height: 43px;
      padding: 0 15rem;
      &:after {
        content: '';
        display: inline-block;
        width: 12rem;
        height: 12rem;
        margin-left: 5rem;
        background-image: var(--icon-shortcut-r);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  &-arrow {
    width: auto;
    height: auto;
    padding: 0;
    color: $--konai-red;
    text-align: left;
    &::after {
      content: '';
      display: inline-block;
      width: 9px;
      height: 13px;
      margin: 0 0 -1px 7px;
      background-image: var(--icon-arr-rt-r);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    &.ico-only {
      &::before, &::after {
        display: none;
      }
      &.for_main {
        position: absolute;
        display: block;
        width: 30rem;
        height: 15rem;
        bottom: calc(8.15vh - 15rem);
        left: 50%;
        transform: translate(-50%, 0);
        background-image: var(--icon-arr-dn-w);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        //pointer-events: auto;
        //cursor: pointer;
        pointer-events: none;
        cursor: default;
        opacity: 0;
        animation: mainReveal 0.5s 0.3s ease-out forwards, mainArrow 3s 0.8s ease-in-out infinite;
      }
    }
  }
  &-text {
    display: inline-block;
    width: auto;
    height: auto;
    font-size: 16rem;
    font-weight: 400;
    line-height: 1.63;
    letter-spacing: normal;
    color: $--konai-gray-3 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    &:not(:first-child) {
      margin-left: 8rem;
    }
  }
  &.red {
    background-color: $--konai-red;
    color: $--konai-white;
  }
  &.white {
    background-color: $--konai-white;
    color: $--konai-red;
  }
  &.darkgray {
    background-color: $--konai-gray-3;
    color: $--konai-white;
  }
  &.gray {
    background-color: $--konai-gray-5;
    color: $--konai-white;
  }
  &.line {
    background-color: transparent;
    border: 1px solid transparent;
    &.red {
      border-color: $--konai-red;
      color: $--konai-red;
    }
    &.gray {
      border-color: $--konai-gray-5;
      color: $--konai-gray-5;
    }
  }
}

// TAG
.tag {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 10px 15px;
  font-size: 18rem;
  font-weight: 400;
  // line-height: 2.11;
  line-height: 1;
  text-align: center;
  word-break: keep-all;
  text-transform: capitalize;
  border-radius: 100px;
  &-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    .tag {
      padding: 10px 20px;
      font-weight: 600;
      cursor: pointer;
      pointer-events: auto;
      &:not(:last-child) {
        margin-right: 15rem;
      }
      &.line {
        color: $--konai-gray-3;
        border-color: $--konai-gray-3;
      }
      &.active {
        color: $--konai-white;
        background-color: $--konai-red;
        border: 1px solid $--konai-red;
      }
    }
  }
  &.red {
    background-color: $--konai-red;
    color: $--konai-white;
  }
  &.white {
    background-color: $--konai-white;
    color: $--konai-black;
  }
  &.gray {
    background-color: $--konai-gray-5;
    color: $--konai-white;
  }
  &.black {
    background-color: $--konai-black;
    color: $--konai-white;
  }
  &.line {
    background-color: transparent;
    border: 1px solid transparent;
    &-red {
      border-color: $--konai-red;
      color: $--konai-red;
    }
    &-gray {
      border-color: $--konai-gray-5;
      color: $--konai-gray-5;
    }
    &-darkgray {
      border-color: $--konai-dimgray-1;
      color: $--konai-dimgray-1;
    }
    &-black {
      border-color: $--konai-black;
      color: $--konai-black;
    }
  }
}

// BOX
.block_box {
  width: auto;
  height: auto;
  margin: 0;
  padding: 28px 30px;
  &.red {
    background-color: $--konai-red;
    color: $--konai-white;
  }
  &.black {
    background-color: $--konai-black;
    color: $--konai-white;
  }
  &.dimgray {
    background-color: $--konai-gray-1;
    color: $--konai-white;
  }
  &.lightgray {
    background-color: $--konai-lightgray-1;
    color: $--konai-black;
  }
  &.white {
    background-color: $--konai-white;
    color: $--konai-black;
  }
  &.r {
    &-12 {
      border-radius: 12px;
    }
    &-16 {
      border-radius: 16px;
    }
    &-24 {
      border-radius: 24px;
    }
  }
}

// TAB
.tab {
  &-wrap {
    //position: relative;
    position: sticky;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100px;
    max-height: 100px;
    top: 0;
    text-align: center;
    background-color: $--konai-white;
    border-bottom: 1px solid $--konai-gray-6;
    z-index: 6;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 80px;
      top: -1px;
      left: 0;
      z-index: -1;
      background-color: $--konai-white;
      opacity: 1;
      pointer-events: none;
    }
    &.fixed {
      transform: translate(0, 80rem);
      transition: transform 0.3s 0.15s ease-in-out;
      &:before {
        height: 100px;
        transform: translate(0, -80px);
        z-index: -1;
        opacity: 1;
        pointer-events: auto;
        transition: all 0.15s 0.15s ease-in-out;
      }
    }
    &.fixedTop {
      position: fixed;
      top: 0;
      & + .tab-contents {
        margin-top: 80rem;
      }
    }
    &.inner {
      height: auto;
    }
    &__inner {
      width: auto;
      height: auto;
      margin: 0 auto;
    }
  }
  &-contents {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    z-index: 5;
    background-color: $--konai-white;
    &__inner {
      position: relative;
      display: none;
      width: 100%;
      max-width: 1200px;
      height: auto;
      margin: 0 auto;
      padding: 180rem 0;
    }
  }
  &-list {
    display: flex;
    width: auto;
    height: 100%;
  }
  &-elem {
    display: block;
    width: auto;
    height: auto;
    margin: 0 30rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    & > a {
      pointer-events: auto;
      label {
        display: flex;
        flex-direction: column;
        font-family: 'Pretendard', sans-serif;
        font-size: 18rem;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        //text-transform: capitalize;
        color: $--konai-dimgray-1;
        pointer-events: none;
        cursor: pointer;
        transition: color 0.3s ease-in-out;
        &:after {
          content: '';
          display: block;
          position: relative;
          width: 100%;
          max-width: 0;
          height: 2rem;
          min-height: 2px;
          margin-top: 24rem;
          background-color: $--konai-trans;
          transition: max-width 0.3s ease-in-out;
        }
      }
      &.active {
        label {
          font-weight: 800;
          color: $--konai-red;
          transition: color 0.3s ease-in-out;
          &:after {
            max-width: 100%;
            background-color: $--konai-red;
            transition: max-width 0.3s ease-in-out;
          }
        }
      }
    }
  }
  &-input {
    display: none;
    &:checked {
      & + label {
        font-weight: 800;
        color: $--konai-red;
        &:after {
          max-width: 100%;
          background-color: $--konai-red;
        }
      }
    }
  }
  &-label {
    display: flex;
    flex-direction: column;
    font-family: 'Pretendard', sans-serif;
    font-size: 18rem;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    //text-transform: capitalize;
    color: $--konai-dimgray-1;
    pointer-events: auto;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:after {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      max-width: 0;
      height: 2rem;
      min-height: 2px;
      margin-top: 24rem;
      background-color: $--konai-trans;
      transition: max-width 0.3s ease-in-out;
    }
  }
  &-title {
    font-family: 'Pretendard', sans-serif;
    font-size: 45rem;
    font-weight: 700;
    line-height: normal;
    color: $--konai-black;
    margin: 0 0 120rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: normal;
    & + .tab-subtitle {
      margin: -95rem 0 120rem;
    }
  }
  &-subtitle {
    font-family: 'Pretendard', sans-serif;
    font-size: 18rem;
    font-weight: 600;
    line-height: normal;
    color: $--konai-gray-0;
    margin: 0 0 120rem;
  }
}

// TABLE
.table {
  &-wrap {
    display: block;
    //width: 100%;
    width: calc(100% + 24rem);
    height: auto;
    overflow-x: auto;
    &:not(:first-child) {
      margin-top: 120rem;
    }
  }
  &-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    font-size: 20rem;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    word-break: keep-all;
    white-space: nowrap;
    color: $--konai-black;
    .unit {
      font-size: 18rem;
      color: $--konai-gray-3;
    }
    &.absolute {
      position: absolute;
      width: calc(100% - 48rem);
      & + .table-elem {
        &:not(:first-child) {
          margin-top: 45rem;
        }
      }
    }
  }
  &-elem {
    position: relative;
    display: table;
    width: calc(100% - 24rem);
    height: auto;
    margin-right: 24rem;
    &:not(:first-child) {
      margin-top: 25rem;
    }
    &.list {
      tr {
        &:first-child {
          td {
            border-top: 1px solid $--konai-gray-4;
          }
        }
        td {
          padding: 32rem 20rem;
          text-align: center;
          &:not(:first-child):not(:last-child) {
            padding: 0;
            text-align: left;
          }
        }
      }
      a {
        &.tag {
          display: flex;
          align-items: center;
          width: auto;
          height: 34rem;
          padding: 10rem 15rem;
          border-radius: 34rem;
          font-size: 14rem;
          font-weight: 500;
          line-height: 1;
          text-align: center;
        }
        &[onclick] {
          font-size: 20rem;
          font-weight: 600;
          line-height: 1.7;
          color: $--konai-black;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          word-break: break-all;
        }
      }
      .date {
        font-size: 20rem;
        font-weight: 400;
        line-height: 1.8;
        color: $--konai-gray-3;
      }
    }
    b {
      &.price {
        font-size: 13rem;
        font-weight: 700;
        line-height: 1;
      }
    }
    * {
      font-family: 'Pretendard', sans-serif;
      text-align: center;
      vertical-align: middle;
    }
    thead {
      tr {
        th {
          background-color: $--konai-lightgray-3;
          border-top: 1px solid $--konai-black;
          border-bottom: 1px solid $--konai-gray-4;
          &[rowspan] {
            &:not(:last-child) {
              border-right: 1px solid $--konai-gray-4;
            }
          }
          &.depth_2 {
            background-color: $--konai-white;
            &:not(:last-child) {
              border-right: 1px solid $--konai-gray-4;
            }
          }
        }
      }
    }
    tbody {
      &.with_line {
        tr {
          td {
            &:not(:last-child) {
              border-right: 1px solid $--konai-gray-4;
            }
            &:not(:first-child) {
              border-left: 1px solid $--konai-gray-4;
            }
          }
        }
      }
      tr {
        th {
          background-color: $--konai-lightgray-3;
          border-bottom: 1px solid $--konai-gray-4;
        }
      }
    }
    tr {
      th {
        display: table-cell;
        width: auto;
        height: auto;
        margin: 0;
        padding: 15px 20px;
        font-size: 16rem;
        font-weight: 700;
        //line-height: 2.12;
        line-height: normal;
        word-break: keep-all;
        white-space: nowrap;
        color: $--konai-black;
      }
      td {
        display: table-cell;
        width: auto;
        height: auto;
        margin: 0;
        padding: 15px 20px;
        font-size: 15rem;
        font-weight: 400;
        //line-height: 2.4;
        line-height: normal;
        word-break: keep-all;
        color: $--konai-black;
        border-bottom: 1px solid $--konai-gray-4;
        &.number {
          text-align: right;
          &.wd80 {
            .flex {
              & > span {
                max-width: 80px;
              }
            }
          }
          &.wd140 {
            .flex {
              & > span {
                max-width: 140px;
              }
            }
          }
          .flex {
            & > span {
              width: 100%;
              text-align: right;
              &:first-child:last-child {
                margin: 0 auto;
              }
              &:first-child:not(:last-child) {
                margin: 0 0 0 auto;
              }
              &:last-child:not(:first-child) {
                margin: 0 auto 0 0;
              }
            }
          }
        }
      }
    }
  }
}

// ACCORDION
.accordion {
  &-wrap {
    display: block;
    width: 100%;
    height: auto;
    .img_wrap {
      display: inline-block;
      width: 400rem;
      min-width: 400rem;
      height: 280rem;
      margin-left: 112rem;
      border-radius: 12rem;
      //background-color: $--konai-gray-6;
    }
  }
  &-list {
    position: relative;
    display: inline-block;
    width: 100%;
    height: auto;
  }
  &-elem {
    position: relative;
    display: inline-block;
    width: 100%;
    height: auto;
    border-top: 1px solid $--konai-gray-4;
    pointer-events: auto;
    cursor: pointer;
    &:last-child {
      border-bottom: 1px solid $--konai-gray-4;
    }
    & > .flex {
      align-items: flex-start;
      max-height: 138rem;
      padding: 50rem 0;
      overflow: hidden;
      transition: max-height 1.5s cubic-bezier(0, 1, 0, 1);
      & > .wrap {
        display: inline-flex;
      }
      & > .accordion-title {
        width: auto;
        min-width: 40rem;
        margin-right: 22rem;
      }
      .img_wrap {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s cubic-bezier(0, 1, 0, 1);
      }
    }
    &:hover, &.active {
      border-top: 1px solid $--konai-red;
      & > .flex {
        max-height: 1000rem;
        transition: max-height 1.4s ease-in-out;
        .img_wrap {
          max-height: 1000rem;
          transition: max-height 1s ease-in-out;
        }
      }
    }
  }
  &-title {
    display: inline-block;
    width: 100%;
    height: auto;
    font-size: 30rem;
    font-weight: 800;
    line-height: 1.27;
    letter-spacing: normal;
    color: $--konai-black;
    word-break: keep-all;
    text-align: left;
  }
  &-text {
    display: inline-block;
    width: 100%;
    height: auto;
    font-size: 22rem;
    font-weight: 400;
    line-height: 1.64;
    letter-spacing: normal;
    color: $--konai-black;
    word-break: keep-all;
    text-align: left;
    &:not(:first-child) {
      margin-top: 45rem;
    }
  }
}

// BOARD
.board {
  &-wrap {
    display: block;
    width: 100%;
    height: auto;
    .img_wrap {
      width: 100%;
      height: auto;
      max-height: 196px;
    }
  }
  &-img {
    width: 100%;
    max-width: 300px;
    height: 196rem;
    max-height: 196px;
    border-radius: 12rem;
    overflow: hidden;
    object-fit: cover;
  }
  &_img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &-wrap {
      display: flex;
      justify-content: center;
      width: 100%;
      height: auto;
      max-height: 534px;
      padding: 0 100rem;
      &:not(:last-child) {
        margin-bottom: 95rem;
      }
    }
  }
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    font-size: 18rem;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    word-break: keep-all;
    white-space: nowrap;
    color: $--konai-black;
    .unit {
      font-size: 18rem;
      color: $--konai-gray-3;
    }
    .board-txt {
      font-size: 20rem;
    }
  }
  &-txt {
    font-size: 18rem;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    word-break: keep-all;
    white-space: nowrap;
    color: $--konai-black;
  }
  &-elem {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: auto;
    &:not(:first-child) {
      margin-top: 80rem;
    }
    &.with_img {
      & + .pagination-wrap {
        margin-top: 90rem;
      }
      tr {
        td {
          padding: 30rem;
          & > span {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            white-space: normal;
          }
          & > p {
            text-align: left;
            &.date {
              margin-top: 10rem;
              font-size: 18rem;
              font-weight: 400;
              line-height: 1.67;
              letter-spacing: -0.2px;
              color: $--konai-gray-3;
            }
          }
        }
      }
    }
    * {
      font-family: 'Pretendard', sans-serif;
      text-align: center;
      vertical-align: middle;
    }
    &.no_result {
      thead {
        tr {
          &:first-child:last-child {
            th, td {
              border-top: 1px solid $--konai-gray-4;
              border-bottom: 0;
              padding: 0;
              .flex.vert {
                min-height: 230rem;
                padding-top: 180rem;
                & > *:first-child {
                  margin-top: auto;
                }
                & > *:last-child {
                  margin-bottom: auto;
                }
                & > span {
                  font-size: 20rem;
                  font-weight: 700;
                  line-height: 1.7;
                }
                .board-txt {
                  color: $--konai-gray-3;
                  font-weight: 400;
                  &:not(:first-child) {
                    margin-top: 13rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    thead {
      tr {
        th {
          background-color: $--konai-white;
          border-top: 1px solid $--konai-red;
          border-bottom: 1px solid $--konai-gray-4;
        }
      }
      & + tbody {
        tr {
          &:first-child {
            th, td {
              border-top: 0;
            }
          }
        }
      }
    }
    tbody {
      tr {
        * {
          &[onclick] {
            pointer-events: auto;
            cursor: pointer;
          }
        }
        &:first-child {
          th, td {
            border-top: 1px solid $--konai-red;
          }
        }
        td {
          border-bottom: 1px solid $--konai-gray-4;
          transition: color 0.15s ease-in-out;
          &:hover {
            &:not(.date):not(a):not(button):not(.center) {
              color: $--konai-red;
              transition: color 0.3s ease-in-out;
            }
          }
          .tag {
            display: block;
            max-width: fit-content;
            padding: 6rem 12rem;
            border-radius: 32rem;
            font-size: 14rem;
            font-weight: 400;
            pointer-events: none;
            cursor: default;
            &.line {
              border-color: $--konai-gray-1;
              color: $--konai-gray-1;
            }
            &:not(:last-child) {
              margin-bottom: 22rem;
            }
          }
        }
      }
    }
    tr {
      th {
        display: table-cell;
        width: auto;
        //min-width: 76px;
        height: auto;
        margin: 0;
        padding: 30px 20px;
        font-size: 20rem;
        font-weight: 700;
        //line-height: 2.12;
        line-height: normal;
        text-align: left;
        word-break: keep-all;
        color: $--konai-black;
      }
      td {
        display: table-cell;
        width: auto;
        //min-width: 76px;
        height: auto;
        margin: 0;
        padding: 30px 20px;
        font-size: 18rem;
        font-weight: 600;
        //line-height: 2.12;
        line-height: normal;
        text-align: left;
        word-break: break-all;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $--konai-black;
        &.date {
          max-width: 203px;
          font-size: 18rem;
          font-weight: 400;
          white-space: nowrap;
          overflow: unset;
        }
        &:not(.center):not(.date) {
          & > a, & > span, & > span strong {
            //display: block;
            width: 100%;
            text-align: left;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
  &-detail {
    display: block;
    width: 100%;
    height: auto;
    font-size: 16rem;
    font-weight: 400;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: $--konai-black;
    &:last-child {
      margin-bottom: 120rem;
    }
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 534px;
      object-fit: contain;
      margin-bottom: 95rem;
    }
    a[href]:not(.attachment-elem) {
      //display: inline-flex;
      //align-items: center;
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }
      &:after {
        content: "";
        display: inline-block;
        width: 14px;
        min-width: 14px;
        height: 14px;
        min-height: 14px;
        margin-left: 4px;
        margin-bottom: -3px;
        background-image: var(--icon-shortcut);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    iframe, video {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 534px;
      z-index: 10;
    }
    figure {
      &.image {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 534px;
        text-align: center;
        figcaption {
          font-size: 14rem;
        }
      }
      &.media, iframe, video {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 534px;
        z-index: 10;
      }
      &.table{
        width: 100%;
        table {
          width: 100%;
          thead {
            & + tbody {
              tr {
                &:first-child {
                  th, td {
                    border-top: 1px solid $--konai-gray-4;
                  }
                }
                &:not(:last-child) {
                  th, td {
                    &[rowspan] {
                      border-bottom: 1px solid $--konai-gray-4;
                    }
                  }
                }
              }
            }
          }
          tbody {
            tr {
              &:not(:last-child) {
                th, td {
                  &[rowspan] {
                    border-bottom: 1px solid $--konai-gray-4;
                  }
                }
              }
            }
          }
          tr {
            &:first-child{
              th, td {
                border-top: 1px solid $--konai-black;
              }
            }
            &:not(:first-child) {
              th, td {
                border-top: 1px solid $--konai-gray-4;
              }
            }
            &:last-child {
              th, td {
                border-bottom: 1px solid $--konai-gray-4;
              }
            }
          }
          th {
            width: auto;
            height: 60px;
            padding: 15px 20px;
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
            letter-spacing: normal;
            vertical-align: middle;
            background-color: $--konai-lightgray-3;
            color: $--konai-black;
            text-align: center;
            border-left: 1px solid $--konai-gray-4;
            border-right: 1px solid $--konai-gray-4;
            &:first-child {
              border-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
          td {
            width: auto;
            height: 60px;
            padding: 15px 20px;
            //font-size: 15px;
            font-weight: 400;
            //line-height: normal;
            letter-spacing: normal;
            vertical-align: middle;
            background-color: $--konai-white;
            color: $--konai-black;
            //text-align: center;
            border-top: 1px solid $--konai-black;
            border-left: 1px solid $--konai-gray-4;
            border-right: 1px solid $--konai-gray-4;
            &:first-child {
              border-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
        }
      }
    }
  }
  &-attachment {
    padding: 32rem 0;
    border-top: 1px solid $--konai-gray-4;
    border-bottom: 1px solid $--konai-gray-4;
    &:not(:first-child) {
      margin-top: 120rem;
    }
    .attachment-elem {
      display: flex;
      align-items: center;
      width: auto;
      margin: 0;
      padding: 0;
      & + .attachment-elem {
        margin-top: 16rem;
      }
      & > span {
        font-size: 15rem;
        line-height: 1;
        font-weight: 500;
        color: $--konai-gray-1;
        text-decoration: underline;
        &:hover, &:focus, &:active {
          color: $--konai-black;
        }
        &:last-child {
          margin: 0 auto 0 0;
        }
      }
      &:before {
        content: '';
        display: inline-block;
        width: 17rem;
        height: 17rem;
        margin: 0 11rem 0 0;
        background-image: var(--btn-download);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  &-navigator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding-top: 25rem;
    border-top: 1px solid $--konai-gray-4;
    &:not(:first-child) {
      margin-top: 25rem;
    }
    & > .flex {
      width: auto;
    }
    .btn {
      font-size: 18rem;
      font-weight: 600;
      line-height: 1;
      color: $--konai-black;
      &.navigator {
        &-prev {
          display: inline-flex;
          align-items: center;
          width: auto;
          height: auto;
          &.disabled, &:disabled {
            opacity: 0.3;
            pointer-events: none;
            cursor: default;
          }
          &:before {
            content: '';
            display: block;
            //width: 25rem;
            //height: 25rem;
            //margin-right: 10rem;
            //background-image: var(--btn-nav-arr-lt);
            width: 16rem;
            height: 16rem;
            margin-right: 4rem;
            background-image: var(--swiper-arr-l-g);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
          &:not(:last-child) {
            margin-right: 66rem;
          }
        }
        &-next {
          display: inline-flex;
          align-items: center;
          width: auto;
          height: auto;
          &.disabled, &:disabled {
            opacity: 0.3;
            pointer-events: none;
            cursor: default;
          }
          &:after {
            content: '';
            display: block;
            //width: 25rem;
            //height: 25rem;
            //margin-left: 10rem;
            //background-image: var(--btn-nav-arr-rt);
            width: 16rem;
            height: 16rem;
            margin-left: 4rem;
            background-image: var(--swiper-arr-r-g);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  &-other {
    display: block;
    width: 100%;
    height: auto;
    margin: 93rem 0 0;
    &-title {
      padding: 0 100rem;
      font-size: 23rem;
      font-weight: 700;
      line-height: 1.47;
      color: $--konai-black;
      &:not(:last-child) {
        margin-bottom: 32rem;
      }
      &.list {
        padding: 0 20rem;
      }
    }
    &-text {
      font-size: 16rem;
      font-weight: 600;
      line-height: 1.43;
      color: $--konai-black;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      white-space: normal;
    }
    &-date {
      font-size: 16rem;
      font-weight: 400;
      line-height: 2.25;
      color: $--konai-gray-3;
    }
    &-img {
      display: inline-block;
      width: 100%;
      height: 200rem;
      border-radius: 12rem;
      object-fit: cover;
      overflow: hidden;
    }
    .img_wrap {
      display: block;
      width: 100%;
      height: auto;
      &:not(:last-child) {
        margin-bottom: 20rem;
      }
    }
    .swiper {
      padding: 0 100rem;
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 100rem;
        height: 100%;
        top: 0;
        left: 0;
        background: $--konai-white;
        z-index: 2;
      }
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100rem;
        height: 100%;
        top: 0;
        right: 0;
        background: $--konai-white;
        z-index: 2;
      }
      &-button {
        &-prev {
          width: 32rem;
          height: 32rem;
          margin-right: 68rem;
          left: 0;
          //background-image: var(--btn-nav-arr-lt);
          background-image: var(--swiper-arr-l-g);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          transform: translate(0, -47rem);
          opacity: 1;
          z-index: 3;
          &:after {
            display: none;
          }
        }
        &-next {
          width: 32rem;
          height: 32rem;
          margin-left: 68rem;
          right: 0;
          //background-image: var(--btn-nav-arr-rt);
          background-image: var(--swiper-arr-r-g);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          transform: translate(0, -47rem);
          opacity: 1;
          z-index: 3;
          &:after {
            display: none;
          }
        }
        &-disabled {
          opacity: 0.3 !important;
        }
      }
      &-slide {
        & > a {
          .board-other-text {
            transition: color 0.3s ease-in-out;
          }
        }
        &:hover {
          & > a {
            .board-other-text {
              color: $--konai-red;
            }
          }
        }
      }
      &-pagination {
        position: absolute;
        display: none;
      }
    }
  }
}

// PAGINATION
.pagination {
  &-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 120rem;
  }
  &-btn {
    display: block;
    width: 21rem;
    height: 21rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    & + .pagination-btn {
      margin-left: 15rem;
    }
    &.toFront {
      background-image: var(--btn-page-arr-first);
    }
    &.toPrev {
      background-image: var(--btn-page-arr-lt);
    }
    &.toNext {
      background-image: var(--btn-page-arr-rt);
    }
    &.toBack {
      background-image: var(--btn-page-arr-last);
    }
  }
  &-list {
    display: block;
    width: auto;
    height: auto;
    margin: 0 30rem;
  }
  &-elem {
    display: inline-block;
    width: 21rem;
    height: 21rem;
    * {
      font-size: 16rem;
      font-weight: 600;
      line-height: 1;
      color: $--konai-gray-3;
    }
    &.active, .active {
      * {
        color: $--konai-red;
      }
    }
    button, a {
      display: inline-flex;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      pointer-events: auto;
      cursor: pointer;
      span {
        margin: auto;
        pointer-events: none;
      }
    }
    & + .pagination-elem {
      margin-left: 23rem;
    }
  }
}

// SELECT
.select-wrap:not(.for_footer) {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow: visible;
  .select {
    &-input {
      width: 100%;
      height: auto;
      padding: 15rem 20rem;
      background-color: $--konai-white;
      color: $--konai-black;
      border: 1px solid $--konai-gray-4;
      border-radius: 4rem;
      outline: 0;
      font-size: 16rem;
      font-weight: 400;
      //line-height: 1.63;
      line-height: 1;
      &.hidden {
        display: none;
      }
    }
  }
  .btn {
    &-input {
      display: none;
      position: absolute;
      width: 0;
      height: 0;
      &:checked {
        & + .btn-select {
          border-radius: 4rem 4rem 0 0;
          &::after {
            background-image: var(--icon-arr-dn-b);
          }
        }
        & ~ .option-list {
          max-height: 800px;
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
    &-select {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: auto;
      padding: 15rem 20rem;
      background-color: $--konai-white;
      color: $--konai-black;
      border: 1px solid $--konai-gray-4;
      border-radius: 4rem;
      outline: 0;
      font-size: 16rem;
      font-weight: 400;
      //line-height: 1.63;
      line-height: 1;
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      &::after {
        content: '';
        display: inline-block;
        width: 13px;
        min-width: 13px;
        height: 9px;
        margin-left: $--spacing-s;
        background-image: var(--icon-arr-dn-b);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  .option-list {
    position: absolute;
    display: block;
    width: inherit;
    max-width: 371rem;
    height: auto;
    max-height: 0;
    //margin-top: 47rem;
    background-color: $--konai-white;
    color: $--konai-black;
    border: 1px solid $--konai-gray-4;
    border-radius: 0 0 4rem 4rem;
    transform: translate(0, 47rem);
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition: max-height 0.5s ease-in-out;
    .btn {
      &-option {
        width: 100%;
        height: auto;
        max-height: 48rem;
        padding: 15rem 20rem;
        outline: 0;
        font-size: 16rem;
        font-weight: 400;
        //line-height: 1.63;
        line-height: 1;
        word-break: keep-all;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        & > a {
          display: inline-block;
          width: 100%;
          span {
            pointer-events: none;
          }
        }
        &:not(:first-child) {
          border-top: 1px solid $--konai-gray-6;
        }
      }
    }
  }
}

// INPUT SEARCH
.search {
  &-wrap {
    display: block;
    position: relative;
    width: auto;
    height: auto;
    .btn-search {
      display: inline-block;
      width: 20rem;
      min-width: 20rem;
      max-width: 20rem;
      height: 20rem;
      min-height: 20rem;
      max-height: 20rem;
      background-image: var(--btn-search-g);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  &-elem {
    display: inline-flex;
    align-items: center;
    width: 100%;
    max-width: 242px;
    height: auto;
    min-height: 27px;
    border-bottom: 1px solid $--konai-gray-4;
  }
  &-input {
    flex: 1;
    display: inline-block;
    width: auto;
    max-width: 242px;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 16rem;
    font-weight: 500;
    line-height: 1.6;
    color: $--konai-black;
    &::placeholder {
      font-weight: 400;
      color: $--konai-gray-3;
    }
  }
}

// TITLE 관련
.title {
  &-detail {
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
    vertical-align: middle;
    &:not(:last-child) {
      margin-bottom: 25rem;
    }
    & > span {
      display: inline-flex;
      align-items: center;
      font-size: 18rem;
      font-weight: 400;
      line-height: 1.67;
      letter-spacing: -0.2rem;
      white-space: nowrap;
      color: $--konai-gray-3;
      &:not(:first-child) {
        &:before {
          content: '';
          display: inline-block;
          width: 1px;
          height: 50%;
          min-height: 15rem;
          margin: auto 15rem;
          padding: 0;
          background-color: $--konai-gray-3;
        }
      }
    }
    & + .tab-title {
      line-height: 1.4;
    }
  }
}

// STEP
.step {
  &-wrap {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }
  &-elem {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 50rem 0 50rem 62rem;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      width: 100%;
      height: 1px;
      transform: translate(-50%, 50rem);
      background-color: $--konai-gray-4;
    }
    &:first-child {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 1px;
        transform: translate(-50%, 0);
        background-color: $--konai-red;
      }
    }
    &:nth-child(1) {
      .step-title {
        &:before {
          content: '01';
        }
      }
    }
    &:nth-child(2) {
      .step-title {
        &:before {
          content: '02';
        }
      }
    }
    &:nth-child(3) {
      .step-title {
        &:before {
          content: '03';
        }
      }
    }
    &:nth-child(4) {
      .step-title {
        &:before {
          content: '04';
        }
      }
    }
    &:nth-child(5) {
      .step-title {
        &:before {
          content: '05';
        }
      }
    }
    &:nth-child(6) {
      .step-title {
        &:before {
          content: '06';
        }
      }
    }
    &:nth-child(7) {
      .step-title {
        &:before {
          content: '07';
        }
      }
    }
    &:nth-child(8) {
      .step-title {
        &:before {
          content: '08';
        }
      }
    }
    &:nth-child(9) {
      .step-title {
        &:before {
          content: '09';
        }
      }
    }
    &:nth-child(10) {
      .step-title {
        &:before {
          content: '10';
        }
      }
    }
  }
  &-title {
    position: relative;
    font-size: 30rem;
    font-weight: 800;
    line-height: 1.27;
    color: $--konai-black;
    &:before {
      content : '';
      position: absolute;
      display: inline-block;
      width: 40rem;
      height: auto;
      left: -62rem;
      margin-right: 22rem;
      color: $--konai-red;
    }
    & + .step-text {
      margin-top: 45rem;
    }
  }
  &-text {
    font-size: 22rem;
    font-weight: 400;
    line-height: 1.64;
    color: $--konai-black;
  }
}

// CARD
.card {
  &-wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    height: auto;
    margin: 180rem auto;
    padding: 0;
  }
  &-elem {
    display: block;
    flex: 1 1 30%;
    width: 100%;
    height: 292rem;
    margin: 0;
    padding: 30rem 25rem;
    background-color: $--konai-lightgray-3;
    border-radius: 12rem;
    &:not(:last-child) {
      margin-right: 12rem;
    }
    &:not(:first-child) {
      margin-left: 12rem;
    }
    &.konadaum {
      flex: unset;
      width: 369rem;
      height: 520rem;
      border-radius: 6rem;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      &.card-text {
        font-size: 20rem;
        font-weight: 700;
        text-align: center;
      }
    }
    &.with_img {
      width: 276rem;
      height: auto;
      min-height: 376rem;
      margin: 0 0 35px;
      padding: 0;
      background-color: $--konai-white;
      border-radius: 12px;
      box-shadow: 0 10px 25px rgba(0,0,0,0.1);
      overflow: visible;
      .img_wrap {
        display: inline-block;
        width: 100%;
        height: 240rem;
        border-radius: 12px 12px 0 0;
        overflow: hidden;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .text_wrap {
        display: inline-block;
        width: 100%;
        height: auto;
        padding: 22px 30px;
        border-radius: 0 0 12px 12px;
        .card {
          &-text {
            font-size: 16rem;
            font-weight: 600;
            line-height: normal;
            word-break: break-all;
            color: $--konai-red;
            text-align: left;
            & + .card-title {
              margin-top: 8rem;
            }
          }
          &-title {
            font-size: 24rem;
            font-weight: 800;
            line-height: 1.33;
            word-break: break-all;
            color: $--konai-black;
            text-align: left;
          }
        }
      }
    }
    &.with_text {
      flex: 1 1 30%;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 464rem;
      margin: 0;
      padding: 0;
      border-radius: 12rem;
      background-color: $--konai-white;
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
      &.dummy {
        opacity: 0;
        pointer-events: none;
      }
      &:nth-child(3n-2) {
        margin-right: 12rem;
      }
      &:nth-child(3n-1) {
        margin-right: 12rem;
        margin-left: 12rem;
      }
      &:nth-child(3n) {
        margin-left: 12rem;
      }
      &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
        margin-top: 84rem;
      }
      .img_wrap {
        position: relative;
        width: 100%;
        height: 228rem;
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .text_wrap {
        position: relative;
        width: 100%;
        height: auto;
        padding: 20rem;
        .card {
          &-title {
            font-size: 30rem;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            color: $--konai-black;
          }
          &-text {
            font-size: 16rem;
            font-weight: 300;
            line-height: 1.5;
            letter-spacing: -0.2px;
            text-align: left;
            color: $--konai-black;
          }
        }
      }
    }
  }
  &-title {
    font-size: 28rem;
    font-weight: 800;
    line-height: 1.29;
    word-break: break-all;
    color: $--konai-black;
    & + .card-text {
      margin-top: 15rem;
    }
  }
  &-text {
    font-size: 15rem;
    font-weight: 400;
    line-height: 1.63;
    word-break: break-all;
    color: $--konai-black;
  }
}

// popup
.popup {
  position: fixed;
  display: inline-block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 997;
  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: $--konai-black;
    opacity: 0.3;
  }
  &-wrap {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 500rem;
    height: auto;
    min-height: min(360rem, calc(100vh - 60rem));
    max-height: min(640rem, calc(100vh - 60rem));
    margin: 0;
    padding: 0 0 50rem;
    top: 50%;
    left: 50%;
    background-color: $--konai-white;
    box-shadow: 6px 6px 8px rgba(0,0,0,0.15);
    transform: translate(-50%, -50%);
    z-index: 998;
  }
  &-contents {
    position: relative;
    display: inline-block;
    flex: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 35rem 30rem 30rem;
    overflow-x: hidden;
    overflow-y: auto;
    & > p {
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.63;
      letter-spacing: normal;
      color: $--konai-black;
      &:first-child {
        width: 100%;
        height: auto;
        font-size: 30rem;
        font-weight: 800;
        word-break: break-all;
        text-align: center;
      }
    }
    & > hr {
      display: inline-block;
      width: calc(100% + 60rem);
      height: 1px;
      margin: 30rem 0 30rem -30rem;
      background-color: $--konai-gray-4;
      border: 0;
    }
    & > figure {
      position: relative;
      width: 100%;
      height: auto;
      margin: 0 0 30rem;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
      &:first-child:last-child {
        width: 100%;
        height: auto;
      }
    }
  }
  &-btm {
    position: absolute;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50rem;
    left: 0;
    bottom: 0;
    padding: 0 20rem;
    background-color: $--konai-red;
    * {
      color: $--konai-white;
    }
    .check {
      &-box {
        position: relative;
        display: inline-flex;
        align-items: center;
        width: auto;
        text-align: left;
        label {
          display: flex;
          align-items: center;
          width: auto;
          height: 26rem;
          font-size: 16rem;
          font-weight: 400;
          line-height: 1.63;
          text-align: left;
          color: $--konai-white;
          cursor: pointer;
          &:before {
            content: '';
            display: inline-block;
            width: 16rem;
            min-width: 16rem;
            height: 16rem;
            margin-right: 10rem;
            background-image: var(--icon-pop-chk-box);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
      &-input {
        display: none;
        width: 0;
        height: 0;
        &:checked {
          & + label {
            &:before {
              background-image: var(--icon-pop-chk-box-on);
            }
          }
        }
      }
    }
    .btn {
      position: relative;
      display: inline-block;
      font-size: 18rem;
      font-weight: 500;
      line-height: 1.67;
      letter-spacing: -0.2px;
      color: $--konai-white;
      cursor: pointer;
      &:last-child {
        text-align: right;
      }
    }
  }
}

// agree
.agree {
  &-container {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 1200rem;
    height: auto;
    margin: 180rem auto;
    & > .flex {
      justify-content: flex-end;
      width: 100%;
      height: auto;
      margin: 100rem 0;
      .select-wrap {
        width: 327rem;
        z-index: 1;
        .btn-select, .option-list {
          width: 327rem;
          max-width: 327rem;
        }
      }
    }
  }
  &-wrap {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 1200rem;
    height: auto;
    margin: 0 auto;
    h5 {
      display: inline-block;
      width: 100%;
      font-size: 24rem;
      font-weight: 700;
      line-height: 1.33;
      letter-spacing: -0.2px;
      color: $--konai-black;
      word-break: keep-all;
      text-align: left;
    }
    p {
      display: inline-block;
      width: 100%;
      font-size: 18rem;
      font-weight: 400;
      line-height: 1.78;
      letter-spacing: -0.2px;
      color: $--konai-black;
      word-break: break-all;
      text-align: left;
      & > a {
        display: block;
      }
    }
    br {
      line-height: 1;
    }
    table {
      position: relative;
      display: inline-table;
      table-layout: auto;
      width: 100%;
      height: auto;
      border-top: 1px solid $--konai-black;
      tr {
        th {
          display: table-cell;
          width: auto;
          padding: 20rem;
          font-size: 16rem;
          font-weight: 700;
          line-height: 1.63;
          letter-spacing: normal;
          color: $--konai-black;
          word-break: break-all;
          text-align: center;
          vertical-align: middle;
          background-color: $--konai-lightgray-3;
          border-bottom: 1px solid $--konai-gray-4;
          border-left: 1px solid $--konai-gray-4;
          border-right: 1px solid $--konai-gray-4;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
        }
        td {
          display: table-cell;
          width: auto;
          padding: 15rem;
          font-size: 16rem;
          font-weight: 400;
          line-height: 1.63;
          letter-spacing: normal;
          color: $--konai-black;
          word-break: break-all;
          text-align: center;
          vertical-align: middle;
          background-color: $--konai-white;
          border-bottom: 1px solid $--konai-gray-4;
          border-left: 1px solid $--konai-gray-4;
          border-right: 1px solid $--konai-gray-4;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
        }
        p {
          font-size: 16rem;
          font-weight: 400;
          line-height: 1.625;
          letter-spacing: normal;
        }
      }
    }
  }
  &-title {
    display: inline-block;
    width: 100%;
    font-size: 56rem;
    font-weight: 800;
    line-height: 1.29;
    color: $--konai-black;
    text-align: center;
  }
}

// MO SET
@media screen and (max-width: 1239px) {
  .main {
    &-point {
      font-size: 28rem;
      font-weight: 400;
      line-height: normal;
      &.fs {
        &-80 {
          font-size: 34rem;
          font-weight: 400;
          line-height: 1.24;
        }
        &-56 {
          font-size: 28rem;
          font-weight: 400;
          line-height: 1.35;
        }
      }
    }
    &-subpoint {
      font-size: 14rem;
      font-weight: 400;
      line-height: normal;
    }
    &-title {
      font-size: 28rem;
      font-weight: 800;
      line-height: 1.35;
      &.fs {
        &-50 {
          font-size: 34rem;
          font-weight: 800;
          line-height: 1.12;
        }
        &-48 {
          font-size: 24rem;
          font-weight: 800;
          line-height: 1.42;
        }
        &-40 {
          font-size: 28rem;
          font-weight: 800;
          line-height: 1.36;
        }
      }
    }
    &-mdtitle {
      font-size: 20rem;
      font-weight: 700;
      line-height: 1.5;
    }
    &-subtitle {
      font-size: 18rem;
      font-weight: 800;
      line-height: 1.44;
      &.fs {
        &-30 {
          font-size: 18rem;
          font-weight: 800;
          line-height: 1.4;
        }
        &-28 {
          font-size: 14rem;
          font-weight: 800;
          line-height: normal;
        }
        &-24 {
          font-size: 24rem;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
    &-smtitle {
      font-family: 'Pretendard', sans-serif;
      font-size: 18rem;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: -0.2px;
    }
    &-text {
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: -0.2px;
      &.fs {
        &-23 {
          font-size: 16rem;
          font-weight: 400;
          line-height: 1.5;
        }
      }
    }
  }

  .tag {
    padding: 8rem 13rem;
    font-size: 14rem;
    font-weight: 400;
    line-height: 1;
    &-wrap {
      .tag {
        min-width: 65rem;
        padding: 10rem 12rem;
        font-size: 14rem;
        font-weight: 600;
        &:not(:last-child) {
          margin-right: 8rem;
        }
      }
    }
  }

  .circle {
    width: 132rem;
    min-width: 132rem;
    max-width: 132rem;
    height: 132rem;
    min-height: 132rem;
    max-height: 132rem;
    &:before {
      width: calc(100% + 22rem);
      height: calc(100% + 22rem);
      top: -11rem;
      left: -11rem;
    }
    &:after {
      width: calc(100% + 22rem);
      height: calc(100% + 22rem);
      top: -11rem;
      left: -11rem;
    }
    &:nth-child(1) {
      z-index: 4;
    }
    &:nth-child(2) {
      z-index: 3;
    }
    &:nth-child(3) {
      z-index: 2;
    }
    &:nth-child(4) {
      z-index: 1;
    }
    &.bg {
      &-red {
        width: 90rem;
        min-width: 90rem;
        height: 90rem;
        min-height: 90rem;
        &:nth-child(1) {
          z-index: 0;
        }
        &:nth-child(2) {
          transform: translate(-10rem, 0);
          z-index: 1;
        }
        &:nth-child(3) {
          transform: translate(-20rem, 0);
          z-index: 2;
        }
        &:nth-child(4) {
          margin-top: 17rem;
          transform: translate(0, 0);
          z-index: 3;
        }
        &:nth-child(5) {
          margin-top: 17rem;
          transform: translate(-10rem, 0);
          z-index: 4;
        }
        &:nth-child(6) {
          margin-top: 17rem;
          transform: translate(-20rem, 0);
          z-index: 5;
        }
        .circle-title {
          font-size: 14rem;
          font-weight: 500;
          line-height: 1.29;
        }
      }
    }
    &-wrap {
      flex-wrap: wrap;
      //justify-content: unset;
      justify-content: center;
      align-items: center;
      width: calc(100% - 45rem);
      max-width: 380rem;
      height: 284rem;
      margin: 99rem auto auto;
      padding: 11rem 0;
      & > * {
        flex: 1 1 40%;
      }
    }
    &-point {
      font-size: 8rem;
      font-weight: 400;
      line-height: 1;
      &:not(:last-child) {
        margin-bottom: 6rem;
      }
    }
    &-title {
      font-size: 15rem;
      font-weight: 700;
      line-height: 1;
    }
  }

  .btn {
    &-md {
      max-height: 39rem;
      padding: 10rem 20rem;
      font-size: 14rem;
      line-height: 1.35;
      &.round {
        border-radius: 39px;
      }
    }
    &-sm {
      max-height: 30rem;
      padding: 8rem 16rem;
      font-size: 14rem;
      line-height: 1;
      &.round {
        border-radius: 30rem;
      }
    }
    &-arrow {
      padding: 0;
      &.ico-only {
        &.for_main {
          width: 20rem;
          height: 11rem;
          bottom: calc(4.878vh - 11rem);
          left: 50%;
        }
      }
    }
  }

  .tab {
    &-wrap {
      height: 60rem;
      max-height: 60rem;
      padding: 0 24rem;
      overflow-x: auto;
      &:before {
        height: 59rem;
      }
      &.fixed {
        transform: translate(0, 60rem);
        &:before {
          transform: translate(0, -60rem);
        }
      }
      &.fixedTop {
        & + .tab-contents {
          margin-top: 60rem;
        }
      }
    }
    &-contents {
      &__inner {
        padding: 80rem 24rem;
      }
    }
    &-elem {
      display: block;
      width: auto;
      height: auto;
      margin: 0 12rem;
      white-space: nowrap;
      & > a {
        label {
          //font-size: 16rem;
          font-size: min(16rem, calc(100vw * 0.04));
          &:after {
            margin-top: 11rem;
          }
        }
      }
    }
    &-label {
      //font-size: 16rem;
      font-size: min(16rem, calc(100vw * 0.04));
      &:after {
        margin-top: 11rem;
      }
    }
    &-title {
      font-size: 22rem;
      font-weight: 700;
      line-height: 1.27;
      margin: 0 0 80rem;
      & + .tab-subtitle {
        margin: -80rem 0 80rem;
      }
    }
    &-subtitle {
      display: none;
    }
  }

  .table {
    &-wrap {
      padding-bottom: 16px;
      &:not(:first-child) {
        margin-top: 70rem;
      }
    }
    &-elem {
      &.list {
        tr {
          td {
            padding: 20rem 10rem;
            &:not(:first-child):not(:last-child) {
              padding: 0;
            }
          }
        }
        a {
          &.tag {
            height: 20rem;
            padding: 5rem 10rem;
            border-radius: 20rem;
            font-size: 10rem;
          }
          &[onclick] {
            font-size: 14rem;
            line-height: 1;
          }
        }
        .date {
          font-size: 12rem;
          line-height: 1;
        }
      }
      tr {
        th {
          font-size: 14rem;
          white-space: nowrap;
          background-color: $--konai-lightgray-3;
        }
        td {
          font-size: 14rem;
          font-weight: 400;
          white-space: nowrap;
        }
      }
      b {
        &.price {
          font-size: 12rem;
          font-weight: 700;
          line-height: 1;
        }
      }
    }
    &-title {
      .unit {
        font-size: 14rem;
      }
    }
  }

  .header.fixed {
    & + .frame-container {
      //height: 363rem;
      margin-top: 60rem;
    }
  }
  .frame {
    &-container {
      position: relative;
      width: 100%;
      //height: 363px;
      height: 303rem;
      &.fullpage {
        height: calc(100vh - 60rem);
      }
      &-bg {
        height: calc(100% + 60rem);
        top: -60rem;
      }
      &-text {
        width: 100%;
        height: 363rem;
        margin-top: -30rem;
        .text_wrap {
          width: 100%;
        }
      }
    }
    &-contents {
      &__inner {
        padding: 120rem 24rem 0;
        &:last-child {
          padding: 120rem 24rem;
        }
        &.with_img {
          &:nth-child(2n) {
            background-color: $--konai-white;
          }
          &:nth-child(2n-1) {
            & > * {
              &:first-child:last-child {
                flex-direction: column-reverse;
                .text_wrap {
                  margin-top: 36rem;
                }
                .img_wrap {
                  margin-top: 0;
                }
              }
            }
          }
          & > * {
            &:first-child:last-child {
              flex-direction: column;
              align-items: unset;
              & > * {
                &:not(:first-child) {
                  margin-left: unset;
                  margin-top: 36rem;
                }
              }
            }
            .img_wrap {
              img {
                width: 100%;
                //height: 213rem;
                height: calc(100vw * 0.568);
              }
            }
            .text_wrap {
              text-align: left;
              .main {
                &-mdtitle {
                  text-align: left;
                  &:not(:last-child) {
                    margin-bottom: 12rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    &-subpoint {
      font-size: 22rem;
      &:not(:last-child) {
        margin-bottom: 10rem;
      }
    }
    &-title {
      font-size: 34rem;
      line-height: 1.29;
      white-space: normal;
    }
  }

  .accordion {
    &-wrap {
      .img_wrap {
        width: 100%;
        min-width: unset;
        height: 176rem;
        max-height: 0;
        margin-top: 30rem;
        margin-left: 0;
        border-radius: 12rem;
      }
    }
    &-elem {
      border-top: 1px solid $--konai-gray-4;
      &:last-child {
        border-bottom: 1px solid $--konai-gray-4;
      }
      & > .flex {
        display: inline-flex;
        width: auto;
        max-width: unset;
        max-height: 120rem;
        padding: 30rem 24rem;
        & > .wrap {
          flex-direction: column;
          width: 100%;
          max-width: unset;
          margin: 0;
          .text_wrap {
            display: inline-block;
            max-width: unset !important;
            margin: 0 !important;
          }
          .img_wrap {
            transition: unset;
          }
          &:not(:first-child) {
            margin-left: 10rem;
          }
        }
        .accordion-title {
          display: inline-block;
          width: auto;
          min-width: unset;
          margin-right: 0;
        }
        .accordion-text {
          display: inline-block;
          width: 100%;
          min-width: unset;
          margin-right: 0;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }
      }
      &:hover, &.active {
        border-top: 1px solid $--konai-red;
        & > .flex {
          max-height: 1000rem;
          transition: max-height 0.7s ease-in-out;
          .accordion-text {
            opacity: 1;
          }
          .img_wrap {
            height: 176rem;
          }
        }
      }
    }
    &-title {
      font-size: 20rem;
      font-weight: 800;
      line-height: 1.5;
      letter-spacing: normal;
      color: $--konai-black;
      word-break: keep-all;
      text-align: left;
    }
    &-text {
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: normal;
      color: $--konai-black;
      word-break: keep-all;
      text-align: left;
      &:not(:first-child) {
        margin-top: 13rem;
      }
    }
  }

  .board {
    &-wrap {
      .img_wrap {
        max-height: 136px;
      }
    }
    &-img {
      max-width: 100px;
      height: 80rem;
      max-height: 80px;
      border-radius: 8rem;
      object-fit: cover;
    }
    &_img {
      //max-height: 175px;
      max-height: 46.67vw;
      &-wrap {
        //max-height: 175px;
        max-height: 46.67vw;
        padding: 0;
        &:not(:last-child) {
          margin-bottom: 50rem;
        }
      }
    }
    &-title {
      flex-direction: column;
      justify-content: unset;
      align-items: flex-start;
      font-size: 20rem;
      font-weight: 700;
      line-height: 1;
      .unit {
        font-size: 18rem;
        color: $--konai-gray-3;
      }
    }
    &-elem {
      &:not(:first-child) {
        margin-top: 46rem;
      }
      &.with_img {
        & + .pagination-wrap {
          margin-top: 40rem;
        }
        tr {
          td {
            padding: 20rem 10rem;
            & > span, & > p {
              &.date {
                margin-top: 10rem;
                font-size: 12rem;
              }
            }
          }
        }
      }
      &.no_result {
        margin-top: 0;
        thead {
          tr {
            &:first-child:last-child {
              th, td {
                border-top: 0;
                .flex.vert {
                  min-height: 210rem;
                  padding-top: 120rem;
                  padding-bottom: 40rem;
                  & > span {
                    font-size: 16rem;
                    font-weight: 400;
                    word-break: keep-all;
                    white-space: normal;
                  }
                  .board-txt {
                    font-size: 16rem;
                    font-weight: 400;
                    word-break: keep-all;
                    white-space: normal;
                    &:not(:first-child) {
                      margin-top: 8rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .for_pc {
        display: none !important;
      }
      .for_mo {
        display: block !important;
      }
      tbody {
        tr {
          &:hover {
            td {
              &:not(.date):not(a):not(button):not(.center) {
                color: inherit;
              }
            }
          }
          td {
            .tag {
              font-size: 9rem;
              font-weight: 600;
              border-radius: 20rem;
              padding: 5rem 9rem;
              &:not(:last-child) {
                margin-bottom: 10rem;
              }
            }
          }
        }
      }
      tr {
        th {
          padding: 20px 6px;
          font-size: 14rem;
          font-weight: 700;
          //line-height: 2.12;
          line-height: normal;
        }
        td {
          padding: 20px 6px;
          font-size: 14rem;
          font-weight: 400;
          //line-height: 2.12;
          line-height: normal;
          &.date {
            font-size: 12rem;
            font-weight: 400;
          }
        }
      }
    }
    &-detail {
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: -0.2rem;
      &:last-child {
        margin-bottom: 80rem;
      }
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 175px;
        object-fit: contain;
      }
      iframe, video{
        width: 100%;
        height: 175px;
        z-index: 10;
      }
      figure{
        &.image{
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 175px;
          text-align: center;
          figcaption{
            font-size: 12rem;
          }
        }
        &.media, iframe, video{
          width: 100%;
          height: 175px;
          z-index: 10;
        }
        &.table{
        }
      }
    }
    &-attachment {
      padding: 32rem 0;
      &:not(:first-child) {
        margin-top: 80rem;
      }
      .attachment-elem {
        & + .attachment-elem {
          margin-top: 16rem;
        }
        & > span {
          font-size: 14rem;
        }
        &:before {
          width: 16rem;
          height: 16rem;
        }
      }
    }
    &-navigator {
      padding-top: 15rem;
      &:not(:first-child) {
        margin-top: 15rem;
      }
      .btn {
        font-size: 16rem;
        font-weight: 600;
        &.navigator {
          &-prev {
            &:before {
              width: 14rem;
              height: 14rem;
              margin-right: 4rem;
            }
            &:not(:last-child) {
              margin-right: 15rem;
            }
          }
          &-next {
            &:after {
              width: 14rem;
              height: 14rem;
              margin-left: 4rem;
            }
          }
        }
      }
    }
    &-other {
      margin: 55rem 0 40rem;
      &-title {
        padding: 0;
        font-size: 20rem;
        font-weight: 700;
        line-height: 1.7;
        &:not(:last-child) {
          margin-bottom: 32rem;
        }
        &.list {
          padding: 0;
          margin-bottom: 25rem;
        }
      }
      &-text {
        font-size: 16rem;
        font-weight: 600;
        line-height: 1.43;
      }
      &-date {
        font-size: 14rem;
        font-weight: 400;
        line-height: 2.25;
      }
      &-img {
        height: 200rem;
        border-radius: 12rem;
      }
      .img_wrap {
        &:not(:last-child) {
          margin-bottom: 20rem;
        }
      }
      .swiper {
        width: calc(100% + 24rem);
        padding: 0 24rem 0 0;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
        &-button {
          &-prev {
            display: none;
          }
          &-next {
            display: none;
          }
        }
      }
    }
  }

  .pagination {
    &-wrap {
      margin-top: 46rem;
      .for_pc {
        display: none !important;
      }
    }
    &-list {
      margin: 0 16rem;
    }
    &-elem {
      width: 18rem;
      height: 18rem;
      * {
        font-size: 14rem;
      }
      & + .pagination-elem {
        margin-left: 16rem;
      }
    }
  }

  .select-wrap:not(.for_footer) {
    .option-list {
      max-width: calc(100% - 48rem);
      .btn {
        &-option {
          width: 100%;
        }
      }
    }
  }

  .select-wrap {
    .select {
      &-input {
        font-size: 14rem;
      }
    }
    .btn {
      &-input {
        &:checked {
          & ~ .option-list {
            max-height: 800px;
          }
        }
      }
      &-select {
        font-size: 14rem;
        min-height: 48rem;
        &::after {
          width: 13px;
          min-width: 13px;
          height: 9px;
          margin-left: $--spacing-s;
        }
      }
    }
    .option-list {
      max-width: calc(100% - 48rem);
      //margin-top: 47rem;
      transform: translate(0, 47rem);
      .btn {
        &-option {
          min-height: 48rem;
          max-height: 48rem;
          font-size: 14rem;
        }
      }
    }
  }

  .search {
    &-wrap {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      margin-top: 24rem;
    }
    &-elem {
      max-width: unset;
    }
    &-input {
      max-width: unset;
    }
  }

  .title {
    &-detail {
      &:not(:last-child) {
        margin-bottom: 15rem;
      }
      & > span {
        font-size: 12rem;
        font-weight: 400;
        &:not(:first-child) {
          &:before {
            height: 50%;
            min-height: 11rem;
            margin: auto 8rem;
          }
        }
      }
      & + .tab-title {
        line-height: 1.27;
      }
    }
  }

  .step {
    &-elem {
      padding: 30rem 0 30rem 34rem;
      &:after {
        transform: translate(-50%, 30rem);
      }
    }
    &-title {
      font-size: 20rem;
      font-weight: 800;
      line-height: 1.9;
      &:before {
        width: 24rem;
        left: -34rem;
        margin-right: 10rem;
      }
      & + .step-text {
        margin-top: 13rem;
      }
    }
    &-text {
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: -0.2px;
    }
  }

  .card {
    &-wrap {
      max-width: unset;
      margin: 70rem auto;
    }
    &-elem {
      flex: 1 1 30%;
      height: 194rem;
      &.konadaum {
        flex: unset;
        width: 180rem;
        height: 253rem;
        border-radius: 12rem;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
      }
      &.with_text {
        flex: unset;
        display: flex;
        flex-direction: column;
        width: 100%;
        //height: 432rem;
        height: auto;
        max-height: 432rem;
        &:nth-child(3n-2) {
          margin-right: 0;
        }
        &:nth-child(3n-1) {
          margin-right: 0;
          margin-left: 0;
        }
        &:nth-child(3n) {
          margin-left: 0;
        }
        &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
          margin-top: 24rem;
        }
        &:not(:nth-child(1)) {
          margin-top: 24rem;
        }
        .img_wrap {
          height: unset;
          border-radius: 12rem 12rem 0 0;
        }
        .text_wrap {
          padding: 20rem;
          .card {
            &-title {
              font-size: 25rem;
              font-weight: 700;
              line-height: 1.4;
            }
            &-text {
              font-size: 14rem;
              font-weight: 300;
              line-height: 1.57;
              letter-spacing: -0.035px;
            }
          }
        }
      }
    }
    &-title {
      font-size: 18rem;
      font-weight: 800;
      line-height: 1.44;
      & + .card-text {
        margin-top: 13rem;
      }
    }
    &-text {
      font-size: 14rem;
      font-weight: 400;
      line-height: 1.43;
      letter-spacing: -0.2px;
    }
  }

  .popup {
    &-wrap {
      width: calc(100% - 54rem);
      max-width: 320rem;
      min-height: min(300rem, calc(100vh - 520rem));
      max-height: min(480rem, calc(100vh - 340rem));
      padding: 0 0 42rem;
    }
    &-contents {
      padding: 20rem;
      & > p {
        font-size: 14rem;
        font-weight: 300;
        line-height: 1.57;
        &:first-child {
          font-size: 20rem;
          font-weight: 800;
          line-height: 1.4;
        }
      }
      & > hr {
        width: calc(100% + 40rem);
        margin: 20rem 0 20rem -20rem;
      }
      & > figure {
        margin: 0 0 20rem;
      }
    }
    &-btm {
      height: 42rem;
      padding: 0 20rem;
      .check {
        &-box {
          label {
            height: 22rem;
            font-size: 14rem;
            font-weight: 300;
            line-height: 1.57;
            letter-spacing: -0.035px;
            &:before {
              width: 14rem;
              min-width: 14rem;
              height: 14rem;
              margin-right: 6rem;
            }
          }
        }
      }
      .btn {
        font-size: 16rem;
        font-weight: 500;
        line-height: 1.88;
      }
    }
  }

  .agree {
    &-container {
      max-width: 1200rem;
      margin: 120rem auto;
      padding: 0 24rem;
      & > .flex {
        margin: 70rem 0;
        .select-wrap {
          justify-content: flex-end;
          width: 100%;
          .btn-select {
            width: 100%;
            max-width: 100%;
          }
          .option-list {
            width: 100%;
            max-width: calc(100% - 48rem);
            transform: translate(0, calc(100% - 1px));
          }
        }
      }
    }
    &-wrap {
      max-width: 100%;
      h5 {
        font-size: 20rem;
        font-weight: 700;
        line-height: 1.33;
        letter-spacing: -0.2px;
      }
      p {
        font-size: 16rem;
        font-weight: 400;
        line-height: 1.75;
        letter-spacing: -0.2px;
      }
      .table {
        width: 100%;
        overflow-x: auto;
      }
      table {
        tr {
          th {
            padding: 20rem;
            font-size: 14rem;
            font-weight: 700;
            line-height: 1.57;
            white-space: nowrap;
            vertical-align: middle;
          }
          td {
            padding: 15rem;
            font-size: 14rem;
            font-weight: 400;
            line-height: 1.57;
            letter-spacing: -0.035px;
            white-space: nowrap;
            vertical-align: middle;
          }
        }
      }
    }
    &-title {
      font-size: 34rem;
      font-weight: 800;
      line-height: 1.29;
    }
  }
}