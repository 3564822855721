.scrollmagic-pin-spacer {
  &:not(:nth-child(2)) {
    .scrollinner.for_payment, .scrollinner.for_local {
      inset: 80px auto auto !important;
    }
  }
}
.frame {
  &-container {
    &__inner {
      &#fixedScroll_1 {
        height: 100% !important;
        .wrap {
          &[aria-for="section-1"] {
            height: 100%;
          }
          &.hidden {
            * {
              display: none !important;
            }
          }
        }
      }
    }
    &.for_payment, &.for_local {
      .frame-container-bg {
        height: 100%;
        top: 0;
        &.active {
          background-color: $--konai-black;
          transition: background-color 0.1s 0.65s ease-in-out;
          &:before {
            opacity: 1;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.dimmed {
          &:before {
            opacity: 0.4;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.fixed {
          position: fixed;
          top: 0;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 0;
          opacity: 0;
          transition: opacity 0.65s ease-in-out;
        }
        &.section {
          &_1 {
            &.type {
              &_1 {
                &:before {
                  background-image: var(--img-payment-bg);
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
          &_2 {
            &.type {
              &_1 {
                &:before {
                  background-image: var(--img-payment-local-bg);
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
        }
      }
      div[class*='sec-'] {
        padding: 0;
        background-color: transparent;
      }
      &.scrollinner {
        height: 100%;
        top: -80px;
        .flex {
          .wrap {
            position: absolute;
            display: block;
            width: auto;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .text_wrap {
              * {
                color: $--konai-white;
              }
            }
            &[aria-for='section-2'] {
              white-space: nowrap;
            }
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
  }
  &-contents {
    &__inner {
      &.for_payment {
        padding: 0;
        & > .flex.vert {
          width: 100%;
          max-width: unset;
          margin: 0;
        }
        .img_wrap {
          .img {
            &.payment {
              &-card {
                &-01 {
                  content: var(--img-payment-card-01);
                }
                &-02 {
                  content: var(--img-payment-card-02);
                }
                &-03 {
                  content: var(--img-payment-card-03);
                }
                &-04 {
                  content: var(--img-payment-card-04);
                }
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-3'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0 0;
            background-color: $--konai-white;
            .text_wrap {
              max-width: 1200rem;
              margin: 0 auto;
              text-align: left;
              & > * {
                text-align: left;
              }
              .main {
                &-subtitle {
                  margin-bottom: 20rem;
                }
                &-text {
                  &:not(:first-child) {
                    marign-top: 50rem;
                  }
                }
              }
            }
            .img_wrap{
              .img {
                position: relative;
                display: inline-block;
                width: 445rem;
                height: 280rem;
                object-fit: contain;
                &.payment-01{
                  content: var(--img-payment-01-01);
                }
                &.payment-02{
                  content: var(--img-payment-01-02);
                }
                &.payment-03{
                  content: var(--img-payment-01-03);
                }
              }
            }
            .accordion {
              &-wrap {
                margin-top: 180rem;
              }
              &-elem {
                & > .flex {
                  max-width: 1200rem;
                  margin: 0 auto;
                }
              }
            }
          }
          &[aria-for='section-4'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0 0;
            &:before {
              content: '';
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: -1;
              background-color: $--konai-lightgray-3;
            }
            .block_wrap {
              & > .text_wrap {
                max-width: 1200rem;
                margin: 0 auto;
                .main-text {
                  font-size: 22rem;
                  font-weight: 400;
                  line-height: 1.64;
                }
              }
              .card-wrap {
                max-width: unset;
                margin-bottom: 145rem;
                .swiper {
                  &-wrapper {
                    justify-content: center;
                  }
                  &-slide {
                    max-width: 276rem;
                  }
                }
              }
            }
          }
        }
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
      &.for_local {
        padding: 0;
        & > .flex.vert {
          width: 100%;
          max-width: unset;
          margin: 0;
        }
        .img_wrap {
          .img {
            &.local {
              &-cont {
                &-01 {
                  content: var(--img-local-01);
                  max-width: 1200rem;
                  max-height: 500rem;
                }
                &-02 {
                  content: var(--img-local-02);
                  max-width: 417rem;
                  max-height: 500rem;
                }
                &-03 {
                  content: var(--img-local-09);
                }
                &-04 {
                  content: var(--img-local-10);
                }
                &-05 {
                  content: var(--img-local-11);
                }
                &-06 {
                  content: var(--img-local-12);
                }
                &-07 {
                  content: var(--img-local-13);
                }
                &-08 {
                  content: var(--img-local-14);
                }
                &-09 {
                  content: var(--img-local-15);
                }
                &-10 {
                  content: var(--img-local-16);
                }
                &-11 {
                  content: var(--img-local-17);
                }
                &-12 {
                  content: var(--img-local-18);
                }
                &-13 {
                  content: var(--img-local-19);
                }
              }
              &-icon {
                &-01 {
                  content: var(--img-local-03);
                }
                &-02 {
                  content: var(--img-local-04);
                }
                &-03 {
                  content: var(--img-local-05);
                }
                &-04 {
                  content: var(--img-local-06);
                }
                &-05 {
                  content: var(--img-local-07);
                }
                &-06 {
                  content: var(--img-local-08);
                }
              }
              &-card {
                &-01 {
                  content: var(--img-local-card-09);
                }
                &-02 {
                  content: var(--img-local-card-10);
                }
                &-03 {
                  content: var(--img-local-card-11);
                }
                &-04 {
                  content: var(--img-local-card-12);
                }
                &-05 {
                  content: var(--img-local-card-13);
                }
                &-06 {
                  content: var(--img-local-card-14);
                }
                &-07 {
                  content: var(--img-local-card-15);
                }
                &-08 {
                  content: var(--img-local-card-16);
                }
                &-09 {
                  content: var(--img-local-card-17);
                }
                &-10 {
                  content: var(--img-local-card-18);
                }
                &-11 {
                  content: var(--img-local-card-19);
                }
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-3'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            background-color: $--konai-white;
            .text_wrap {
              max-width: 1200rem;
              margin: 0 auto;
              .main {
                &-subtitle {
                  margin-bottom: 20rem;
                }
                &-text {
                  &:not(:first-child) {
                    marign-top: 50rem;
                  }
                }
              }
            }
          }
          &[aria-for='section-4'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            &:before {
              content: '';
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: -1;
              background-color: $--konai-lightgray-3;
            }
            .block_wrap {
              & > .text_wrap {
                max-width: 1200rem;
                margin: 0 auto;
              }
              & > .flex {
                max-width: 1042rem;
                margin: 0 auto;
                &:not(:first-child) {
                  margin-top: 120rem;
                }
                & > .img_wrap {
                  width: auto;
                  margin: 0 auto;
                }
                & > .flex.vert {
                  width: auto;
                  min-width: 272rem;
                  .block_box {
                    min-height: 154rem;
                    padding: 0;
                    &:not(:first-child) {
                      margin-top: 143rem;
                    }
                    & > .text_wrap {
                      text-align: left;
                      .main {
                        &-title {
                          font-size: 56rem;
                          font-weight: 800;
                          line-height: 1.29;
                          text-align: left;
                          white-space: nowrap;
                          &:not(:last-child) {
                            margin-bottom: 14rem;
                          }
                        }
                        &-text {
                          font-size: 23rem;
                          font-weight: 700;
                          line-height: 1.48;
                          word-break: keep-all;
                          text-align: left;
                          white-space: nowrap;
                          color: $--konai-red;
                          span {
                            color: $--konai-red;
                          }
                          &.description {
                            margin-top: 68rem;
                            font-size: 16rem;
                            font-weight: 400;
                            line-height: 1.63;
                            color: $--konai-gray-3;
                            span {
                              color: $--konai-gray-3;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .card-wrap {
                max-width: unset;
                margin-bottom: 85rem;
                .swiper {
                  &-wrapper {
                    justify-content: center;
                  }
                  &-slide {
                    max-width: 276rem;
                  }
                }
              }
            }
          }
          &[aria-for='section-5'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            background-color: $--konai-white;
            .block_wrap {
              max-width: 1200rem;
              margin: 0 auto;
              & + .block_wrap {
                margin-top: 180rem;
              }
              & > .flex {
                &:not(:first-child) {
                  margin-top: 120rem;
                }
                .block_box {
                  width: 100%;
                  min-width: 368rem;
                  margin: 0 24rem;
                  padding: 0;
                  &:first-child {
                    margin-left: 0;
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  & > .img_wrap {
                    width: auto;
                    text-align: center;
                    .img {
                      max-width: 156rem;
                      max-height: 156rem;
                      object-fit: contain;
                    }
                  }
                  & > .text_wrap {
                    &:not(:first-child) {
                      margin-top: 24rem;
                    }
                    //.main-subtitle {
                    //  white-space: nowrap;
                    //}
                    .main-text {
                      &:not(:first-child) {
                        margin-top: 16rem;
                      }
                    }
                  }
                }
              }
            }
          }
          &[aria-for='section-6'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            &:before {
              content: '';
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: -1;
              background-color: $--konai-lightgray-3;
            }
            & > .block_wrap {
              width: 100%;
              //max-width: 1200rem;
              margin: 0 auto;
              & > .flex {
                & > .block_box:not(.red):not(.black):not(.white) {
                  padding: 0;
                  margin-bottom: 200rem;
                }
              }
              .flex {
                width: 100%;
                max-width: 1200rem;
                margin: 0 auto;
                & > .text_wrap {
                  //max-width: 1200rem;
                  //margin: 0 auto;
                  text-align: left;
                  & > * {
                    text-align: left;
                  }
                }
                & > .block_box {
                  position: relative;
                  display: inline-flex;
                  flex-direction: column;
                  width: 100%;
                  max-width: 368rem;
                  height: auto;
                  margin: 0 auto;
                  padding: 30rem;
                  text-align: left;
                  &:first-child {
                    margin-left: 0;
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  & > * {
                    text-align: left;
                  }
                  &.red, &.black, &.white {
                    height: 486rem;
                    box-shadow: 0 16rem 40rem rgba(112,144,176,0.2);
                  }
                  &.red, &.black {
                    .title, .text {
                      color: $--konai-white;
                      span {
                        color: $--konai-white;
                      }
                    }
                  }
                  & > .flex {
                    position: relative;
                    height: auto;
                    .tag {
                      display: flex;
                      align-items: center;
                      max-height: 43rem;
                      pointer-events: auto;
                      cursor: pointer;
                      &.white {
                        color: $--konai-red;
                        span {
                          color: $--konai-red;
                        }
                        .with_shortcut {
                          &:after {
                            background-image: var(--icon-shortcut-arr-r);
                          }
                        }
                      }
                      &.red {
                        .with_shortcut {
                          &:after {
                            background-image: var(--icon-shortcut-arr-w);
                          }
                        }
                      }
                      .with_shortcut {
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        &:after {
                          content: '';
                          display: inline-block;
                          width: 14px;
                          height: 14px;
                          margin-left: 6px;
                          background-size: contain;
                          background-position: center;
                          background-repeat: no-repeat;
                        }
                      }
                    }
                    .img_wrap {
                      text-align: right;
                      .img {
                        max-height: 148rem;
                      }
                    }
                    &.vert {
                      min-height: 174rem;
                      margin-top: auto;
                      .title {
                        font-size: 30rem;
                        font-weight: 800;
                        line-height: 1.27;
                        word-break: keep-all;
                        color: $--konai-black;
                        text-align: left;
                        &:not(:last-child) {
                          margin-bottom: 15rem;
                        }
                      }
                      .text {
                        font-size: 18rem;
                        font-weight: 400;
                        line-height: 1.67;
                        letter-spacing: -0.2px;
                        word-break: keep-all;
                        color: $--konai-black;
                        text-align: left;
                      }
                    }
                  }
                  .main {
                    &-title {
                      margin: 0;
                      font-size: 140rem;
                      font-weight: 800;
                      line-height: normal;
                      text-align: center;
                      .f-clr-point {
                        margin-left: 8rem;
                        display: inline-block;
                        font-size: 100rem;
                        font-weight: 800;
                        line-height: 1;
                        transform: translate(0, -60%);
                      }
                    }
                    &-text {
                      font-size: 18rem;
                      font-weight: 700;
                      line-height: normal;
                      text-align: center;
                      word-break: keep-all;
                    }
                  }
                }
              }
              .card {
                &-wrap {
                  max-width: unset;
                  margin: 100rem 0 0;
                  .swiper {
                    overflow-y: visible;
                    &-wrapper {
                      transition-timing-function: linear;
                      justify-content: center;
                    }
                    &-slide {
                      max-width: 366rem;
                    }
                  }
                }
                &-elem {
                  display: flex;
                  flex-direction: column;
                  width: 366rem;
                  height: 366rem;
                  padding: 30rem;
                  box-shadow: 0 16rem 40rem rgba(112,144,176,0.2);
                  .img_wrap {
                    text-align: right;
                    .img {
                      max-width: 160rem;
                      max-height: 160rem;
                    }
                  }
                  .text_wrap {
                    height: auto;
                    min-height: 141rem;
                    margin-top: auto;
                    text-align: left;
                    .title {
                      font-size: 26rem;
                      font-weight: 800;
                      line-height: 1.38;
                      word-break: keep-all;
                      color: $--konai-black;
                      text-align: left;
                      &:not(:last-child) {
                        margin-bottom: 15rem;
                      }
                    }
                    .text {
                      font-size: 18rem;
                      font-weight: 400;
                      line-height: 1.67;
                      color: $--konai-gray-3;
                      text-align: left;
                      word-break: keep-all;
                    }
                  }
                }
              }
            }
          }
        }
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
    }
    .tab-contents {
      &__inner[aria-for*='payment_tab_'] {
        .img_wrap {
          .img {
            &.payment {
              &-cont {
                &-01 {
                  content: var(--img-payment-01);
                }
                &-02 {
                  content: var(--img-payment-02);
                }
                &-03 {
                  content: var(--img-payment-03);
                }
                &-04 {
                  content: var(--img-payment-04);
                }
                &-05 {
                  content: var(--img-payment-05);
                }
                &-06 {
                  content: var(--img-payment-06);
                }
                &-07 {
                  content: var(--img-payment-07);
                }
                &-08 {
                  content: var(--img-payment-08);
                }
                &-09 {
                  content: var(--img-payment-09);
                }
                &-10 {
                  content: var(--img-payment-10);
                }
                &-11 {
                  content: var(--img-payment-11);
                }
                &-12 {
                  content: var(--img-payment-12);
                }
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-6'] {
            margin: 80rem 0 0 !important;
            padding: 100rem 0;
          }
          &[aria-for='section-7'], &[aria-for='section-8'] {
            margin: 0 !important;
            padding: 100rem 0;
          }
          &[aria-for='section-9'] {
            margin: 0 !important;
            padding: 100rem 0;
            & > .block_wrap {
              flex-direction: column;
              & > .text_wrap {
              }
              & > .img_wrap {
                width: 100% !important;
                max-width: unset;
                margin-top: 100rem;
                margin-left: 0 !important;
                margin-right: 0 !important;
                text-align: center;
                .img {
                  max-width: 168rem;
                  max-height: 265rem;
                  &.payment-cont-04 {
                    position: absolute;
                    //max-width: 280rem;
                    max-width: 300rem;
                    max-height: 582rem;
                    left: calc(50% + 4rem);
                    //transform: translate(-50%, 0);
                    transform: translate(-50%, -10rem);
                  }
                }
                .swiper {
                  padding-top: 140rem;
                  &-slide {
                    width: 184rem;
                    transform: translate(0, 0);
                    transition: transform 1s ease-in-out;
                    .img {
                      transition: all 1s ease-in-out;
                    }
                    &-active {
                      transform: translate(0, -38rem);
                      .img {
                        max-width: 184rem;
                        max-height: 291rem;
                      }
                    }
                  }
                }
              }
            }
          }
          &.with_img {
            & > .block_wrap {
              display: flex;
              align-items: flex-start;
              width: 100%;
              max-width: 1200rem;
              padding: 0 78rem;
              & > .text_wrap {
                display: inline-block;
                width: 100%;
                //max-width: 410rem;
                max-width: 480rem;
                margin: auto 0;
                text-align: left;
                * {
                  text-align: left;
                  &:nth-child(2){
                    margin-top: 20rem;
                  }
                  &:nth-child(3){
                    margin-top: 30rem;
                  }
                  &:nth-child(4){
                    margin-top: 50rem;
                  }
                }
              }
              & > .img_wrap {
                width: 436rem;
                min-width: 436rem;
                height: 727rem;
                &:first-child {
                  margin-right: auto;
                }
                &:last-child {
                  margin-left: auto;
                }
                .img {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  border-radius: 12rem;
                }
              }
            }
            &:not(:first-child) {
              margin-top: 200rem;
            }
          }
        }
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
    }
  }
}


// 미디어쿼리 @media
@import './pages/payment_media';