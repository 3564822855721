.scrollmagic-pin-spacer {
  &:not(:nth-child(2)) {
    .scrollinner.for_appmeter {
      inset: 80px auto auto !important;
    }
  }
}
.main {
  &-contacts {
    &.for_appmeter {
      position: relative;
      z-index: 5;
      background-color: $--konai-white;
      .text_wrap {
        .text {
          text-align: left;
          &:not(:last-child) {
            margin-bottom: 10rem;
          }
        }
        .title {
          text-align: left;
        }
      }
      .img {
        &.inquiry {
          &-1 {
            content: var(--img-appmeter-11);
          }
          &-2 {
            content: var(--img-appmeter-12);
          }
        }
      }
    }
  }
}
.frame {
  &-container {
    &__inner {
      &#fixedScroll_1 {
        height: 100% !important;
        .wrap {
          &[aria-for="section-1"] {
            height: 100%;
          }
          &.hidden {
            * {
              display: none !important;
            }
          }
        }
      }
    }
    &.for_appmeter {
      .frame-container-bg {
        height: 100%;
        top: 0;
        &.active {
          background-color: $--konai-black;
          transition: background-color 0.1s 0.65s ease-in-out;
          &:before {
            opacity: 1;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.dimmed {
          &:before {
            opacity: 0.4;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.fixed {
          position: fixed;
          top: 0;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 0;
          opacity: 0;
          transition: opacity 0.65s ease-in-out;
        }
        &.section {
          &_1 {
            &.type {
              &_1 {
                &:before {
                  background-image: var(--img-appmeter-bg);
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
        }
      }
      div[class*='sec-'] {
        padding: 0;
        background-color: transparent;
      }
      &.scrollinner {
        height: 100%;
        top: -80px;
        .flex {
          .wrap {
            position: absolute;
            display: block;
            width: auto;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .text_wrap {
              * {
                color: $--konai-white;
              }
            }
            &[aria-for='section-2'] {
              white-space: nowrap;
            }
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
  }
  &-contents {
    &__inner {
      &.for_appmeter {
        padding: 0;
        & > .flex.vert {
          width: 100%;
          max-width: unset;
          margin: 0;
        }
        .img_wrap {
          .img {
            display: inline-block;
            object-fit: contain;
            &.appmeter {
              &-cont {
                &-01 {
                  content: var(--img-appmeter-01);
                }
                &-02 {
                  display: inline-block;
                  width: 550rem;
                  height: 450rem;
                  content: var(--img-appmeter-02);
                }
                &-03 {
                  display: inline-block;
                  width: 550rem;
                  height: 450rem;
                  content: var(--img-appmeter-03);
                }
                &-04 {
                  display: inline-block;
                  width: 426rem;
                  height: 260rem;
                  content: var(--img-appmeter-10);
                }
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-3'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            background-color: $--konai-white;
            & > .block_wrap {
              & > .text_wrap {
                max-width: 1200rem;
                margin: 0 auto;
                text-align: center;
                & > * {
                  text-align: center;
                }
                .main {
                  &-subtitle {
                    margin-bottom: 20rem;
                  }
                  &-text {
                    &:not(:first-child) {
                      marign-top: 50rem;
                    }
                  }
                }
                &:not(:last-child) {
                  margin-bottom: 120rem;
                }
              }
              & > .flex {
                max-width: 1200rem;
                margin: 0 auto;
                justify-content: center;
                .img_wrap {
                  width: 590rem;
                  min-width: 590rem;
                  height: 494rem;
                  .img {
                    position: absolute;
                    display: inline-block;
                    left: 0;
                    width: 650rem;
                    height: 540rem;
                  }
                }
                .flex.vert {
                  width: auto;
                  align-self: flex-end;
                  margin-left: 80rem;
                  .block_box {
                    padding: 0;
                    & + .block_box {
                      margin-top: 48rem;
                    }
                    .text_wrap {
                      width: auto;
                      text-align: left;
                      * {
                        text-align: left;
                      }
                      .main {
                        &-title {
                          margin: 0;
                        }
                        &-text {
                          color: $--konai-red;
                          margin-top: 16rem;
                          margin-bottom: 0;
                          font-size: 23rem;
                          font-weight: 700;
                          line-height: 1.48;
                          &.small {
                            font-size: 16rem;
                            font-weight: 400;
                            line-height: 1.63;
                            color: $--konai-gray-3;
                            span {
                              color: $--konai-gray-3;
                            }
                          }
                        }
                      }
                    }
                    &:last-child {
                      .text_wrap {
                        .main {
                          &-text {
                            &.small {
                              margin-top: 48rem;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &[aria-for='section-4'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            &:before {
              content: '';
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: -1;
              background-color: $--konai-lightgray-3;
            }
            .block_wrap {
              & > .text_wrap {
                max-width: 1200rem;
                margin: 0 auto;
                text-align: left;
                * {
                  text-align: left;
                }
              }
              & > .flex {
                max-width: 1200rem;
                margin: 180rem auto 0;
                text-align: left;
                * {
                  text-align: left;
                }
                .img_wrap {
                  width: auto;
                  &:first-child {
                    margin-right: 74rem;
                  }
                  &:last-child {
                    margin-left: 74rem;
                  }
                }
                .text_wrap {
                  width: 100%;
                  text-align: left;
                  .wrap {
                    &-title {
                      width: 100%;
                      font-size: 40rem;
                      font-weight: 700;
                      line-height: 1.4;
                      color: $--konai-black;
                      &:not(:last-child) {
                        margin-bottom: 36rem;
                      }
                    }
                    &-text {
                      display: inline-flex;
                      align-content: flex-start;
                      width: 100%;
                      font-size: 23rem;
                      font-weight: 700;
                      line-height: 1.47;
                      color: $--konai-black;
                      .f-clr-point {
                        margin-right: 16rem;
                        font-weight: 800;
                      }
                      &:not(:last-child) {
                        margin-bottom: 24rem;
                      }
                    }
                  }
                }
              }
            }
          }
          &[aria-for='section-5'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            background-color: $--konai-white;
            .block_wrap {
              max-width: 1200rem;
              margin: 0 auto;
              & + .block_wrap {
                margin-top: 180rem;
              }
              & > .flex {
                &:not(:first-child) {
                  margin-top: 120rem;
                }
                .block_box {
                  width: 100%;
                  min-width: 368rem;
                  margin: 0 24rem;
                  padding: 0;
                  &:first-child {
                    margin-left: 0;
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  & > .img_wrap {
                    width: auto;
                    text-align: center;
                    .img {
                      max-width: 156rem;
                      max-height: 156rem;
                      object-fit: contain;
                      &.appmeter {
                        &-icon {
                          &-01 {
                            content: var(--img-appmeter-04);
                          }
                          &-02 {
                            content: var(--img-appmeter-05);
                          }
                          &-03 {
                            content: var(--img-appmeter-06);
                          }
                          &-04 {
                            content: var(--img-appmeter-07);
                          }
                          &-05 {
                            content: var(--img-appmeter-08);
                          }
                          &-06 {
                            content: var(--img-appmeter-09);
                          }
                        }
                      }
                    }
                  }
                  & > .text_wrap {
                    &:not(:first-child) {
                      margin-top: 24rem;
                    }
                    .main-text {
                      &:not(:first-child) {
                        margin-top: 16rem;
                      }
                    }
                  }
                }
              }
            }
          }
          &[aria-for='section-6'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            //padding: 180rem 0;
            padding: 85rem 0;
            &:before {
              content: '';
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: -1;
              background-color: $--konai-lightgray-3;
            }
            .block_wrap {
              display: flex;
              width: 100%;
              max-width: 1200rem;
              margin: 0 auto;
              & > .img_wrap {
                width: auto;
                text-align: center;
                &:not(:first-child) {
                  margin-left: auto;
                }
              }
              & > .text_wrap {
                width: auto;
                text-align: left;
                * {
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}


// 미디어쿼리 @media
@import './pages/appmeter_media';