@keyframes mainReveal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mainArrow {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  10% {
    transform: translate(-50%, -10px);
    opacity: 1;
  }
  45% {
    transform: translate(-50%, 10px);
    opacity: 1;
  }
  60% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}