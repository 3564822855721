// MO SET(SMALL)
@media screen and (max-width: 340px) {
}

@media screen and (min-width: 1100px) and (max-width: 1239px) {
}

// MO SET
@media screen and (max-width: 1239px) {
  .scrollmagic-pin-spacer {
    &:not(:nth-child(2)) {
      .scrollinner.for_chip {
        inset: 60px auto auto !important;
      }
    }
  }
  .frame {
    &-container {
      &.for_chip {
        .frame-container-bg {
          &.section {
            &_1 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-chip-bg-mo);
                  }
                }
              }
            }
          }
        }
        &.scrollinner {
          height: 100%;
          top: -60px;
          .flex {
            .wrap {
              width: calc(100% - 48rem);
              .text_wrap {
              }
              .img_wrap {
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-2'] {
            .text_wrap {
              .main-text {
                white-space: normal;
                &.fs-23 {
                  font-weight: 700;
                }
              }
            }
          }
        }
        .for_pc {
          display: none !important;
        }
        .for_mo {
          display: block !important;
        }
      }
    }
    &-contents {
      &__inner {
        &.for_chip {
          .wrap {
            &[aria-for='section-3'] {
              padding: 120rem 0;
              .text_wrap {
                text-align: center;
                * {
                  text-align: center;
                }
                .main {
                  &-subtitle {
                    margin-bottom: 15rem;
                    font-size: 16rem;
                    font-weight: 800;
                    line-height: 1;
                  }
                  &-title {
                    &:not(:last-child) {
                      margin-bottom: 36rem;
                    }
                  }
                }
              }
              .card {
                &-wrap {
                  height: 112rem;
                  margin: 74rem 0 0;
                  .swiper {
                    // 임시 dummy
                    & > .img_wrap {
                      width: 100%;
                      height: 100%;
                      max-height: 106rem;
                      & > img {
                        &.chip-card-dummy {
                          content: var(--img-did-card-dummy-mo);
                          width: 100%;
                          height: 100%;
                          max-height: 106rem;
                          object-fit: contain;
                        }
                      }
                    }
                    &-wrapper {
                      align-items: center;
                    }
                    &-slide {
                      max-width: 91rem;
                      max-height: 67rem;
                      &:nth-child(5n-4), &:nth-child(5n) {
                        &:not(.swiper-slide-active):not(.swiper-slide-prev):not(.swiper-slide-next) {
                          z-index: 0;
                        }
                      }
                      .card-elem {
                        width: 91rem;
                      }
                      &-active {
                        max-width: 155rem;
                        max-height: 108rem;
                        .card-elem {
                          width: 155rem;
                          height: 108rem;
                        }
                      }
                      &-prev, &-next {
                        max-width: 115rem;
                        .card-elem {
                          width: 115rem;
                        }
                      }
                    }
                  }
                }
                &-elem {
                  width: 91rem;
                  height: 67rem;
                }
              }
            }
            &[aria-for='section-4'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                .text_wrap {
                  margin: 0 auto 74rem;
                }
                & > .flex {
                  flex-direction: column;
                  .wrap {
                    margin: 0;
                    & > .img_wrap {
                      margin-bottom: 36rem;
                    }
                    &-title {
                      margin-top: 10rem;
                    }
                    &-text {
                      min-height: unset;
                      margin-top: 18rem;
                      word-break: break-all;
                      :not(:last-child) {
                        margin-bottom: 30rem;
                      }
                    }
                    .btn {
                      &-sm {
                        margin-top: 0;
                        height: 39rem;
                        max-height: 39rem;
                        padding: 10rem 20rem;
                        font-size: 14rem;
                        font-weight: 600;
                        line-height: 1;
                      }
                    }
                    & + .wrap {
                      margin: 120rem 0 0;
                    }
                  }
                }
                .img_wrap {
                  width: 100%;
                  min-width: unset;
                  max-width: unset;
                  height: 213rem;
                  margin-left: unset !important;
                  margin-right: unset !important;
                }
              }
            }
            &[aria-for='section-5'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                .text_wrap {
                  margin: 0 auto 74rem;
                  .main {
                    &-title {
                      font-size: 24rem;
                      font-weight: 800;
                      line-height: 1.42;
                      &:not(:last-child) {
                        margin-bottom: 36rem;
                      }
                    }
                    &-text {
                      font-size: 16rem;
                      font-weight: 400;
                      line-height: 1.5;
                      letter-spacing: -0.2px;
                    }
                  }
                }
                .img_wrap {
                  margin-top: 74rem;
                  width: 100%;
                  max-width: unset;
                  height: 180rem;
                  border-radius: 12rem;
                  overflow: hidden;
                }
              }
            }
          }
          .for_pc {
            display: none !important;
          }
          .for_mo {
            display: block !important;
          }
        }
      }
    }
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
}
