// MO SET(SMALL)
@media screen and (max-width: 340px) {
}

@media screen and (min-width: 1100px) and (max-width: 1239px) {
}

// MO SET
@media screen and (max-width: 1239px) {
  .scrollmagic-pin-spacer {
    &:not(:nth-child(2)) {
      .scrollinner.for_careers {
        inset: 60px auto auto !important;
      }
    }
  }
  .frame {
    &-container {
      &.for_careers {
        .frame-container-bg {
          &.section {
            &_1 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-careers-bg01-01-mo);
                  }
                }
                &_2 {
                  &:before {
                    background-image: var(--img-careers-bg01-02-mo);
                  }
                }
              }
            }
          }
        }
        &.scrollinner {
          height: 100%;
          top: -60px;
          .flex {
            .wrap {
              width: calc(100% - 48rem);
              .text_wrap {
                .main-text {
                  &.fs-23 {
                    font-weight: 700;
                  }
                }
              }
              .img_wrap {
              }
            }
          }
        }
        .for_pc {
          display: none !important;
        }
        .for_mo {
          display: block !important;
        }
      }
    }
    &-contents {
      .tab-contents {
        &__inner[aria-for*='careers_tab_'] {
          .for_pc {
            display: none !important;
          }
          .for_mo {
            display: block !important;
          }
          .wrap {
            max-width: unset;
            &[aria-for='section-2'] {
              .text_wrap {
                .main {
                  &-text {
                    white-space: normal;
                  }
                }
              }
            }
            &[aria-for='section-3'] {
              margin-top: 40rem;
              margin-bottom: 80rem;
            }
            &[aria-for='section-4'] {
              width: calc(100% + 48rem);
              margin-left: -24rem;
              .step {
                &-wrap {
                  display: flex;
                }
                &-elem {
                  width: calc(100% - 48rem);
                  margin: 0 24rem;
                  &:before, &:after {
                    width: calc(100% + 48rem);
                  }
                  & > * {
                    max-width: unset;
                  }
                  .circle {
                    &-wrap {
                      flex-direction: row;
                      justify-content: flex-start;
                      width: 100%;
                      margin: 17rem 0 0;
                      & > * {
                        flex: 1 1 30%;
                      }
                    }
                    &.bg-red {
                      //min-width: 132rem;
                      //max-width: 132rem;
                      //min-height: 132rem;
                      //max-height: 132rem;
                      width: 24vw;
                      min-width: 24vw;
                      max-width: 24vw;
                      height: 24vw;
                      min-height: 24vw;
                      max-height: 24vw;
                      .circle-title {
                        font-size: min(3.73vw, 14rem);
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-5'] {
              width: 100%;
              .card {
                &-wrap {
                  width: calc(100% + 24rem);
                  max-width: unset;
                  .swiper {
                    margin: 0;
                    padding: 0 24rem 16rem 0;
                    &-wrapper {
                      justify-content: center;
                    }
                    &-slide {
                      width: auto;
                      max-width: 287rem;
                      &:last-child {
                        margin-right: 0;
                      }
                    }
                  }
                }
                &-elem {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  max-width: 287rem;
                  .img_wrap {
                    width: 50rem;
                    min-width: 50rem;
                    height: 50rem;
                    min-height: 50rem;
                    &:not(:first-child) {
                      margin-left: 24rem;
                    }
                  }
                  .btn-shortcut {
                    width: auto;
                    align-self: flex-start;
                  }
                }
              }
            }
            &[aria-for='section-6'] {
              .frame-contents__inner-bg {
                height: 196rem;
                &:before {
                  height: 196rem;
                  background-image: var(--img-careers-bg01-02-mo);
                }
                &:after {
                  height: 196rem;
                }
                & > .text_wrap {
                  .main-title {
                    font-size: 18rem;
                    font-weight: 800;
                    line-height: 1.44;
                    &:not(:last-child) {
                      margin-bottom: 14rem;
                    }
                  }
                }
              }
            }
            &[aria-for='section-7'] {
              width: calc(100% + 48rem);
              margin-left: -24rem;
              & > .flex {
                flex-direction: column;
              }
              .flex {
                max-width: unset;
                margin: 120rem auto;
                padding: 0 24rem;
                .text_wrap {
                  min-width: 279rem;
                  margin-right: 0;
                  .main-title {
                    margin-top: 15rem;
                  }
                  .btn {
                    margin-top: 46rem;
                  }
                }
                .card {
                  &-wrap {
                    width: calc(100% + 24rem);
                    max-width: unset;
                    .swiper {
                      margin-left: -10rem;
                      padding: 55rem 24rem 55rem 10rem;
                      &-slide {
                        width: 180rem;
                        &:last-child {
                          margin-right: 0;
                        }
                      }
                    }
                  }
                  &-elem {
                    &.konadaum {
                      .text_wrap {
                        width: 180rem;
                        height: 100%;
                        padding: 20rem;
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-8'] {
              & > .text_wrap {
                margin-top: 120rem;
                .main {
                  &-text {
                    &:not(:first-child) {
                      margin-top: 20rem;
                    }
                  }
                }
              }
              .card {
                &-wrap {
                  margin-top: 70rem;
                }
                &-elem {
                  &.with_text {
                    & > .img_wrap {
                      height: 212rem;
                    }
                    & > .text_wrap {
                      //min-height: 220rem;
                      min-height: unset;
                      padding: 17rem 17rem 30rem;
                    }
                  }
                }
                &-text {
                  font-weight: 300;
                }
              }
            }
            &[aria-for='section-9'] {
              width: calc(100% + 48rem);
              margin-left: -24rem;
              .block_wrap {
                max-width: unset;
                margin: 120rem auto;
                padding: 0 24rem;
                .text_wrap {
                  .main {
                    &-text {
                      font-size: 14rem;
                      font-weight: 300;
                      letter-spacing: -0.035px;
                      &:not(:first-child) {
                        margin-top: 20rem;
                      }
                    }
                  }
                }
                .benefit {
                  &-wrap {
                    margin-top: 70rem;
                  }
                  &-elem {
                    flex: unset;
                    width: 100%;
                    align-items: flex-start;
                    &:not(:nth-child(1)):not(:nth-child(2)) {
                      margin-top: 45rem;
                    }
                    &:nth-child(1) {
                      margin-top: 0;
                    }
                    &:nth-child(2) {
                      margin-top: 45rem;
                    }
                    &:nth-child(2n) {
                      margin-left: 0;
                    }
                    .img_wrap {
                      width: 50rem;
                      min-width: 50rem;
                      height: 50rem;
                      &:not(:last-child) {
                        margin-right: 23rem;
                      }
                      .img {
                        min-width: unset;
                      }
                    }
                  }
                  &-title {
                    font-size: 18rem;
                    font-weight: 800;
                    line-height: 1.44;
                  }
                  &-text {
                    font-size: 14rem;
                    font-weight: 300;
                    line-height: 1.57;
                    letter-spacing: -0.035px;
                    &:not(:first-child) {
                      margin-top: 7rem;
                    }
                  }
                }
              }
            }
            &[aria-for='section-10'] {
              width: calc(100% + 48rem);
              margin-left: -24rem;
              .block_wrap {
                height: 550rem;
                .text_wrap {
                  width: auto;
                  max-width: unset;
                  margin: 50rem auto 0;
                  padding: 0 24rem;
                  .main-point {
                    display: inline-block;
                    width: auto;
                    font-size: 25rem;
                    line-height: 1.52;
                    span {
                      &:not(:first-child) {
                        //transform: translate(168rem, 0);
                        transform: unset;
                        margin-left: 168rem;
                      }
                    }
                  }
                }
                .falling-wrap {
                  height: 550rem;
                }
              }
            }
          }
          .main {
            &-title {
              &:not(:first-child) {
                margin-top: 20rem;
              }
            }
          }
        }
      }
    }
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
  .frame {
    &-contents {
      .tab-contents {
        &__inner[aria-for*='careers_tab_'] {
          .wrap {
            &[aria-for='section-4'] {
              .step {
                &-elem {
                  .circle {
                    &-wrap {
                      flex-direction: row;
                      justify-content: flex-start;
                      width: 100%;
                      margin: 17rem 0 0;
                      & > * {
                        flex: unset;
                      }
                    }
                    &.bg-red {
                      width: 11.8752vw;
                      min-width: 11.8752vw;
                      height: 11.8752vw;
                      min-height: 11.8752vw;
                      &:nth-child(1) {
                        z-index: 0;
                      }
                      &:nth-child(2) {
                        transform: translate(-2.1876vw, 0);
                        z-index: 1;
                      }
                      &:nth-child(3) {
                        transform: translate(-4.3752vw, 0);
                        z-index: 2;
                      }
                      &:nth-child(4) {
                        margin-top: 0;
                        transform: translate(-6.5632vw, 0);
                        z-index: 3;
                      }
                      &:nth-child(5) {
                        margin-top: 0;
                        transform: translate(-8.7504vw, 0);
                        z-index: 4;
                      }
                      &:nth-child(6) {
                        margin-top: 0;
                        transform: translate(-10.9384vw, 0);
                        z-index: 5;
                      }
                      .circle-title {
                        font-size: 1.5vw;
                        font-weight: 500;
                        line-height: normal;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
