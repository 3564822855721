
// MO & TABLET SET
@media screen and (max-width: 1239px){
  .scrollmagic-pin-spacer {
    &:nth-child(2) {
      .scrollinner.for_about {
        inset: 0 auto auto !important;
      }
    }
  }
  .frame {
    &-container {
      &.for_about {
        .frame-container-bg {
          top: -60px;
          background-image: var(--img-about-bg-mo);
        }
        &.scrollinner {
          top: -60px;
        }
        .for_pc {
          display: none;
        }
        .for_mo {
          display: block;
        }
        .wrap {
          &.type_3, &.type_4 {
            .text_wrap {
              .main-text {
                font-size: 14rem;
                font-weight: 700;
                line-height: 1.43;
              }
            }
          }
        }
        .frame-container__inner{
          .flex {
            .wrap{
              .text_wrap{
                .main-title{
                  margin: 12rem 0 22rem !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .about-contents{
    margin-top: -60px;
    .for_pc {
      display: none;
    }
    .for_mo {
      display: block;
    }
    .ceo-container{
      padding: 120rem 24rem 120rem;
      .inner_wrap{
        .flex{
          flex-direction: column;
          .wrap{
            .main-title{
              .br_m{
                display: block;
              }
            }
            .main-text{
              .sign_box{
                display: flex;
                align-items: flex-end;
                span{
                  font-size: 16rem;
                  white-space: nowrap;
                }
                img{
                  width: auto;
                  max-width: 93px;
                  margin-left: min(20px, 4vw);
                }
              }
            }
          }
        }
      }
    }
    .kona-container{
      padding: 120rem 24rem 120rem;
      .inner_wrap{
        .flex{
          .wrap{
            &.flex{
              margin-top:50rem;
              justify-content: center;
              .box{
                flex-direction: column;
                &:last-child{
                  margin: 30rem 0 0;
                }
                div{
                  &:first-child{
                    margin: 0 0 30rem;
                  }
                }
                img{
                  width: 200px;
                }
                .main-subpoint{
                  font-size: 12rem;
                }
                .main-title{
                  font-size: 22rem;
                  line-height: 30px;
                  margin: 10px 0;
                }
                .main-text{
                  font-size: 14rem;
                  line-height: 22px;
                }
              }
            }
          }
        }
      }
      .for_pc {
        display: none !important;
      }
      .for_mo {
        display: block;
      }
    }
    .history-container{
      padding: 120rem 24rem 120rem;
      overflow-x: hidden;
      .container{
        padding: 0;
        .history-list{
          .history-list-item{
            padding-top: 100rem;
            display: block;
            .sticky{
              position: relative;
              top: 0;
              z-index: 1;
              .tit_box{
                span{
                  font-size: 14rem;
                }
                h2{
                  margin: 0 0 10rem;
                }
              }
            }
            .year-content{
              //max-width: 80%;
              width: 100%;
              padding-top: 50rem;
              & > li{
                & + li{
                  margin-top: 25rem;
                }
                p{
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }
    .swiper-container{
      padding: 0 0 120rem;
      .title_box{
        padding: 120rem 24px 100rem;
        .main-text{
          margin: 22rem 0 0;
        }
      }
      .swiper{
        width: 100%;
        padding: 0 0 100rem;
        .swiper-wrapper{
          .swiper-slide{
            width: 224px !important;
            height: 224px;
            .item{
              min-height: 100%;

              .top{
                h5{
                  font-size: 17rem;
                  line-height: 22px;
                }
              }
              .bottom{
                p{
                  font-size: 17rem;
                  line-height: 22px;
                  word-break: break-word;
                }
              }
            }
          }
        }
        .swiper_button_box{
          padding: 0rem 0rem 0rem 24rem;
          grid-template-columns:106px 1fr;
          gap: 17px;
          .box_1{
            .swiper-button-prev{
              &::after{
                background-size: 42px;
                font-size: 0;
                width: 42px;
                height: 42px;
              }
            }
            .swiper-button-next{
              &::after{
                background-size: 42px;
                font-size: 0;
                width: 42px;
                height: 42px;
              }
            }
          }
        }
      }
    }
    .about_attached{
      .footer-attached__inner{
        .footer-attached-title{
          br{
            display: block;
          }
        }
      }
    }
  }

  .parent{
    position: absolute;
    display: block;
    width: 100%;
    //height: 100%;
    height: auto;
    min-height: 255px;
    max-height: 320px;
    &.parent_1{
      right: 0;
      .child{
        display: block;
        width: 100%;
        height: 100%;
        max-width: 260px;
        background: url(../../image/about/about_04_01.png) no-repeat;
        background-size: contain;
        position: absolute;
        right: 0;
        opacity: 0.3;
      }
    }
    &.parent_2{
      right: 0;
      .child{
        display: block;
        width: 100%;
        height: 100%;
        max-width: 230px;
        background: url(../../image/about/about_04_02.png) no-repeat;
        background-size: contain;
        position: absolute;
        left: 35%;
        top: 0;
        opacity: 0.3;

      }
    }
    &.parent_3{
      right: 0;
      .child{
        display: block;
        width: 100%;
        height: 100%;
        max-width: 260px;
        background: url(../../image/about/about_04_03.png) no-repeat;
        background-size: contain;
        position: absolute;
        top: -100%;
        right: 0;
        opacity: 0.3;
      }
    }
  }

}

// MO SET
@media (min-width: 992px) {
  .history-list .sticky {
    position:-webkit-sticky;
    position: sticky;
    top: 188px;
    left: 0;
    -ms-flex-item-align: start;
    align-self: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }
  .history-list .dot {
    width: 10px;
    height: 10px;
    background: #fff;
    position: relative;
    border-radius: 100%;
    margin: calc(2.15rem - 5px) 12% 0 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
  }
  .history-list .dot.on {

  }

  .history-list .dot.on:before,.history-list .dot.on:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(39,196,213,0.5);
    border-radius: 100%;
    -webkit-animation: waves 1s linear infinite forwards;
    animation: waves 1s linear infinite forwards;
    z-index: 1
  }
  .history-list .dot.on:after {
    -webkit-animation-delay: .7s;
    animation-delay: .7s
  }
}


