// MO SET(SMALL)
@media screen and (max-width: 340px) {
}

@media screen and (min-width: 1100px) and (max-width: 1239px) {
}

// MO SET
@media screen and (max-width: 1239px) {
  .scrollmagic-pin-spacer {
    &:not(:nth-child(2)) {
      .scrollinner.for_taxicall {
        inset: 60px auto auto !important;
      }
    }
  }
  .frame {
    &-container {
      &.for_taxicall {
        .frame-container-bg {
          &.section {
            &_1 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-taxicall-bg-mo);
                  }
                }
              }
            }
          }
        }
        &.scrollinner {
          height: 100%;
          top: -60px;
          .flex {
            .wrap {
              width: calc(100% - 48rem);
              .text_wrap {
              }
              .img_wrap {
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-2'] {
            .text_wrap {
              .main-text {
                white-space: normal;
                &.fs-23 {
                  font-weight: 700;
                }
              }
            }
          }
        }
        .for_pc {
          display: none !important;
        }
        .for_mo {
          display: block !important;
        }
      }
    }
    &-contents {
      &__inner {
        &.for_taxicall {
          .wrap {
            &[aria-for='section-3'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: 100%;
                max-width: unset;
                .text_wrap {
                  .main {
                    &-subtitle {
                      margin-bottom: 15rem;
                      font-size: 16rem;
                      font-weight: 800;
                      line-height: 1;
                    }
                    &-title {
                      &:not(:last-child) {
                        margin-bottom: 36rem;
                      }
                    }
                    &-text {
                      margin-bottom: 0;
                      //font-size: 18rem;
                      font-weight: 400;
                      line-height: 1.67;
                      letter-spacing: -0.2px;
                      word-break: break-all;
                    }
                  }
                }
                .img_wrap {
                  margin: 74rem 0 0;
                  width: 100%;
                  max-width: 100%;
                  height: 213rem;
                  .img {
                    &.taxicall {
                      &-cont {
                        &-01 {
                          margin-top: 0;
                          width: 100%;
                          max-width: 100%;
                          height: 100%;
                          max-height: 213rem;
                          border-radius: 12rem;
                          object-fit: cover;
                        }
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-4'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: 100%;
                max-width: unset;
                margin: 0;
                .text_wrap {
                  .main {
                    &-title {
                      text-align: center;
                    }
                  }
                }
                .card {
                  &-wrap {
                    width: 100%;
                    max-width: unset;
                    margin-top: 74rem;
                    .swiper {
                      width: calc(100% + 48rem);
                      margin: 0 -24rem;
                      &-slide {
                        flex-direction: column;
                        width: 280rem;
                        height: auto;
                        min-height: unset;
                        padding: 0 40rem;
                        text-align: center;
                        .img_wrap {
                          height: 202rem;
                          .img {
                            width: 100%;
                            max-width: 100%;
                            height: 202rem;
                          }
                        }
                        .wrap {
                          margin: 36rem 0 0;
                          &-title {
                            font-size: 24rem;
                            line-height: 1.42;
                            text-align: center;
                          }
                          &-text {
                            font-size: 16rem;
                            line-height: 1.5;
                            letter-spacing: -0.2px;
                            text-align: center;
                            word-break: break-all;
                            .small, .small span {
                              margin-top: 10rem;
                              font-size: 12rem;
                              font-weight: 400;
                              line-height: 1.5;
                              word-break: break-word;
                            }
                          }
                        }
                        .tag {
                          margin-top: 20rem;
                        }
                      }
                      &-button {
                        &-prev {
                          width: 20rem;
                          height: 20rem;
                          left: 0;
                          background-image: var(--swiper-arr-l-g);
                          background-size: contain;
                          background-position: center;
                          background-repeat: no-repeat;
                          &:after {
                            display: none;
                          }
                        }
                        &-next {
                          width: 20rem;
                          height: 20rem;
                          right: 0;
                          background-image: var(--swiper-arr-r-g);
                          background-size: contain;
                          background-position: center;
                          background-repeat: no-repeat;
                          &:after {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                  &-elem {
                    flex-direction: column-reverse;
                    padding: 0 20rem;
                    height: auto;
                    min-height: unset;
                    & > .img_wrap {
                      margin-left: 0;
                      margin-right: 0;
                      max-height: 202rem;
                      text-align: center;
                      .img {
                        width: 100%;
                        height: auto;
                        max-height: 202rem;
                      }
                    }
                    & > .text_wrap {
                      margin-top: 36rem;
                      text-align: left;
                      .wrap {
                        &-title {
                          text-align: left;
                        }
                        &-text {
                          margin-top: 18rem;
                          text-align: left;
                          & > p {
                            font-size: 16rem;
                            line-height: 1.5;
                            letter-spacing: -0.2px;
                            &.small {
                              margin-top: 6rem;
                              font-size: 12rem;
                              font-weight: 400;
                              line-height: 1.5;
                              span {
                                font-size: 14rem;
                                font-weight: 500;
                                line-height: 1.29;
                              }
                            }
                            & > span {
                              font-size: 16rem;
                              line-height: 1.5;
                              letter-spacing: -0.2px;
                            }
                          }
                        }
                      }
                      .tag {
                        margin-top: 30rem;
                        padding: 10rem 20rem;
                        font-size: 14rem;
                        font-weight: 700;
                        line-height: 1.36;
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-5'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: unset;
                max-width: unset;
                margin: 0;
                & > .text_wrap {
                  .main {
                    &-title {
                      font-size: 24rem;
                      font-weight: 800;
                      line-height: 1.42;
                    }
                  }
                  & + .flex {
                    margin-top: 74rem;
                  }
                }
                & > .flex {
                  flex-direction: column;
                  .block_box {
                    min-width: unset;
                    margin: 0;
                    &:not(:first-child) {
                      margin-top: 48rem;
                    }
                    .img_wrap {
                      .img {
                        max-width: 108rem;
                        max-height: 108rem;
                      }
                    }
                    .text_wrap {
                      margin-top: 20rem;
                      .main {
                        &-subtitle {
                          &.fs-28 {
                            font-size: 20rem;
                            font-weight: 700;
                            line-height: 1.5;
                            text-align: center;
                          }
                        }
                        &-text {
                          font-size: 14rem;
                          font-weight: 400;
                          line-height: 1.57;
                          text-align: center;
                          &:not(:first-child) {
                            margin-top: 16rem;
                          }
                        }
                      }
                    }
                  }
                }
                & + .block_wrap {
                  margin-top: 120rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
}
