.scrollmagic-pin-spacer {
  &:not(:nth-child(2)) {
    .scrollinner.for_pBlockchain, .scrollinner.for_pNcp {
      inset: 80px auto auto !important;
    }
  }
}
.frame {
  &-container {
    &__inner {
      &#fixedScroll_1 {
        height: 100% !important;
        .wrap {
          &[aria-for="section-1"] {
            height: 100%;
          }
          &.hidden {
            * {
              display: none !important;
            }
          }
        }
      }
    }
    &.for_pBlockchain, &.for_pNcp {
      .frame-container-bg {
        height: 100%;
        top: 0;
        &.active {
          background-color: $--konai-black;
          transition: background-color 0.1s 0.65s ease-in-out;
          &:before {
            opacity: 1;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.dimmed {
          &:before {
            opacity: 0.4;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.fixed {
          position: fixed;
          top: 0;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 0;
          opacity: 0;
          transition: opacity 0.65s ease-in-out;
        }
        &.section {
          &_1 {
            &.type {
              &_1 {
                &:before {
                  background-image: var(--img-pblockchain-bg);
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
          &_2 {
            &.type {
              &_1 {
                &:before {
                  background-image: var(--img-pncp-bg);
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
        }
      }
      div[class*='sec-'] {
        padding: 0;
        background-color: transparent;
      }
      &.scrollinner {
        height: 100%;
        top: -80px;
        .flex {
          .wrap {
            position: absolute;
            display: block;
            width: auto;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .text_wrap {
              * {
                color: $--konai-white;
              }
            }
            &[aria-for='section-2'] {
              white-space: nowrap;
            }
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
  }
  &-contents {
    &__inner {
      &.for_pBlockchain {
        padding: 0;
        & > .flex.vert {
          width: 100%;
          max-width: unset;
          margin: 0;
        }
        .tab-wrap {
          .tab-elem {
            &:first-child {
              .tab-label {
                text-transform: none;
              }
            }
          }
        }
        .img_wrap {
          .img {
            &.blockchain {
              &-cont {
                &-01 {
                  content: var(--img-pblockchain-01);
                  position: relative;
                  display: inline-block;
                  width: 445rem;
                  height: 280rem;
                  object-fit: contain;
                }
                &-02 {
                  content: var(--img-pblockchain-02);
                  position: relative;
                  display: inline-block;
                  width: 445rem;
                  height: 280rem;
                  object-fit: contain;
                }
                &-03 {
                  content: var(--img-pblockchain-03);
                  position: relative;
                  display: inline-block;
                  width: 445rem;
                  height: 280rem;
                  object-fit: contain;
                }
              }
              &-card {
                &-01 {
                  content: var(--img-pblockchain-04);
                }
                &-02 {
                  content: var(--img-pblockchain-05);
                }
                &-03 {
                  content: var(--img-pblockchain-06);
                }
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-3'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0 0;
            background-color: $--konai-white;
            .text_wrap {
              max-width: 1200rem;
              margin: 0 auto;
              text-align: left;
              & > * {
                text-align: left;
              }
              .main {
                &-subtitle {
                  margin-bottom: 20rem;
                }
                &-text {
                  &:not(:first-child) {
                    marign-top: 50rem;
                  }
                }
              }
            }
            .accordion {
              &-wrap {
                margin-top: 180rem;
              }
              &-elem {
                & > .flex {
                  max-width: 1200rem;
                  margin: 0 auto;
                }
              }
            }
          }
          &[aria-for='section-4'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0 0;
            &:before {
              content: '';
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: -1;
              background-color: $--konai-lightgray-3;
            }
            .block_wrap {
              & > .text_wrap {
                max-width: 1200rem;
                margin: 0 auto;
              }
              .card-wrap {
                margin-top: 114rem;
                margin-bottom: 145rem;
                .swiper {
                  &-wrapper {
                    justify-content: center;
                  }
                  &-slide {
                    max-width: 276rem;
                  }
                }
              }
            }
          }
        }
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
      &.for_pNcp {
        padding: 0;
        & > .flex.vert {
          width: 100%;
          max-width: unset;
          margin: 0;
        }
        .img_wrap {
          .img {
            &.ncp {
              &-cont {
                &-01 {
                  content: var(--img-pncp-01);
                  max-width: 1200rem;
                  max-height: 500rem;
                }
                &-02 {
                  content: var(--img-pncp-02);
                  position: relative;
                  display: inline-block;
                  width: 445rem;
                  height: 280rem;
                  object-fit: contain;
                }
                &-03 {
                  content: var(--img-pncp-03);
                  position: relative;
                  display: inline-block;
                  width: 445rem;
                  height: 280rem;
                  object-fit: contain;
                }
                &-04 {
                  content: var(--img-pncp-04);
                  position: relative;
                  display: inline-block;
                  width: 445rem;
                  height: 280rem;
                  object-fit: contain;
                }
                &-05 {
                  content: var(--img-pncp-07);
                }
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-3'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            background-color: $--konai-white;
            .text_wrap {
              max-width: 1200rem;
              margin: 0 auto;
              text-align: center;
              & > * {
                text-align: center;
              }
              .main {
                &-subtitle {
                  margin-bottom: 20rem;
                }
                &-text {
                  &:not(:first-child) {
                    margin-top: 50rem;
                  }
                  &:not(:last-child) {
                    margin-bottom: 120rem;
                  }
                }
              }
            }
            .card {
              &-wrap {
                margin: 0 auto;
                padding: 0;
                .swiper {
                  margin: 150rem 0 0;
                  &-wrapper {
                    justify-content: center;
                    align-items: center;
                  }
                  &-slide {
                    max-width: 232rem;
                    max-height: 270rem;
                    //max-height: 161rem;
                    z-index: 1;
                    transition: all 0.5s ease-in-out;
                    .card-elem {
                      width: 232rem;
                      //height: 161rem;
                      background-color: unset;
                      transition: all 0.5s ease-in-out;
                    }
                    &-active {
                      max-width: 374rem;
                      max-height: 270rem;
                      margin: 0 -28rem;
                      z-index: 5;
                      .card-elem {
                        width: 374rem;
                        height: 270rem;
                      }
                    }
                    &-prev, &-next {
                      max-width: 268rem;
                      //max-height: 197rem;
                      margin: 0 -17rem;
                      z-index: 3;
                      .card-elem {
                        width: 268rem;
                        //height: 197rem;
                      }
                    }
                  }
                }
              }
              &-elem {
                width: 374rem;
                height: 270rem;
                padding: 0;
                border-radius: 0;
                .img_wrap {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  .img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
            }
          }
          &[aria-for='section-4'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            &:before {
              content: '';
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: -1;
              background-color: $--konai-lightgray-3;
            }
            .block_wrap {
              & > .text_wrap {
                max-width: 1200rem;
                margin: 0 auto 120rem;
                text-align: left;
                * {
                  text-align: left;
                }
              }
              & > .flex {
                max-width: 1200rem;
                margin: 0 auto;
                .wrap {
                  margin: 0 15rem;
                  text-align: left;
                  &:first-child {
                    margin-left: 0;
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  .img_wrap {
                    width: 100%;
                    max-width: 380px;
                    height: 256px;
                    margin-bottom: 30rem;
                    border-radius: 12rem;
                    overflow: hidden;
                    .img {
                      display: inline-block;
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                  &-title {
                    max-width: 380px;
                    font-size: 30rem;
                    font-weight: 800;
                    line-height: 1.27;
                    letter-spacing: normal;
                    color: #000;
                    &:not(:last-child) {
                      margin-bottom: 8rem;
                    }
                  }
                  &-text {
                    max-width: 380px;
                    min-height: 90px;
                    font-size: 18rem;
                    font-weight: 400;
                    line-height: 1.67;
                    letter-spacing: -0.2px;
                    color: #000;
                    &:not(:last-child) {
                      margin-bottom: 40rem;
                    }
                  }
                }
              }
            }
          }
          &[aria-for='section-5'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            background-color: $--konai-black;
            background-image: var(--img-pncp-05);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            .text_wrap {
              max-width: 1200rem;
              margin: 0 auto;
              text-align: center;
              color: $--konai-white;
              & > * {
                text-align: center;
                color: $--konai-white;
              }
              .main {
                &-title {
                  font-size: 48rem;
                  font-weight: 800;
                  line-height: 1.25;
                }
                &-text {
                  //font-size: 22rem;
                  //line-height: 1.64;
                  &:not(:first-child) {
                    marign-top: 50rem;
                  }
                }
              }
            }
            .img_wrap {
              width: 100%;
              max-width: 1200rem;
              height: 400rem;
              margin: 120rem auto 0;
              text-align: center;
              .img {
                display: inline-block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                &.ncp {
                  &-cont {
                    &-04 {
                      content: var(--img-ncp-04);
                    }
                  }
                }
              }
            }
          }
        }
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
    }
    .tab-contents {
      &__inner[aria-for*='blockchain_tab_'] {
        .img_wrap {
          .img {
            &.blockchain {
              &-cont {
                &-04 {
                  content: var(--img-pblockchain-07);
                }
                &-05 {
                  content: var(--img-pblockchain-08);
                }
                &-06 {
                  content: var(--img-pblockchain-09);
                }
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-6'] {
            margin: 80rem 0 0 !important;
            padding: 100rem 0;
          }
          &[aria-for='section-7'] {
            margin: 0 !important;
            padding: 100rem 0;
            .flex {
              & > .btn {
                & ~ .btn {
                  margin-top: 0 !important;
                  margin-left: 20rem;
                }
              }
            }
          }
          &[aria-for='section-8'] {
            margin: 0 !important;
            padding: 100rem 0;
          }
          &.with_img {
            & > .block_wrap {
              display: flex;
              align-items: flex-start;
              width: 100%;
              max-width: 1200rem;
              & > .text_wrap {
                display: inline-block;
                width: 100%;
                text-align: left;
                * {
                  text-align: left;
                  &:nth-child(2){
                    margin-top: 20rem;
                  }
                  &:nth-child(3){
                    margin-top: 30rem;
                  }
                  &:nth-child(4){
                    margin-top: 50rem;
                  }
                }
              }
              & > .img_wrap {
                width: 550rem;
                min-width: 550rem;
                height: 450rem;
                &:first-child {
                  margin-right: 100rem;
                }
                &:last-child {
                  margin-left: 100rem;
                }
                .img {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 12rem;
                }
              }
            }
            &:not(:first-child) {
              margin-top: 200rem;
            }
          }
        }
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
    }
  }
}


// 미디어쿼리 @media
@import './pages/blockchain_media';