// MO SET(SMALL)
@media screen and (max-width: 340px) {
}

@media screen and (min-width: 1100px) and (max-width: 1239px) {
}

// MO SET
@media screen and (max-width: 1239px) {
  .scrollmagic-pin-spacer {
    &:not(:nth-child(2)) {
      .scrollinner.for_appmeter {
        inset: 60px auto auto !important;
      }
    }
  }
  //html {
  //  overflow-x: hidden;
  //}
  .frame {
    &-container {
      &.for_appmeter {
        .frame-container-bg {
          &.section {
            &_1 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-appmeter-bg-mo);
                  }
                }
              }
            }
          }
        }
        &.scrollinner {
          height: 100%;
          top: -60px;
          .flex {
            .wrap {
              width: calc(100% - 48rem);
              .text_wrap {
              }
              .img_wrap {
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-2'] {
            .text_wrap {
              .main-text {
                white-space: normal;
                &.fs-23 {
                  font-weight: 700;
                }
              }
            }
          }
        }
        .for_pc {
          display: none !important;
        }
        .for_mo {
          display: block !important;
        }
      }
    }
    &-contents {
      &__inner {
        &.for_appmeter {
          .wrap {
            &[aria-for='section-3'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: 100%;
                max-width: unset;
                .text_wrap {
                  &:not(:last-child) {
                    margin-bottom: 0;
                  }
                  .main {
                    &-subtitle {
                      margin-bottom: 15rem;
                      font-size: 16rem;
                      font-weight: 800;
                      line-height: 1;
                    }
                    &-title {
                      &:not(:last-child) {
                        margin-bottom: 36rem;
                      }
                    }
                    &-text {
                      margin-bottom: 0;
                      //font-size: 18rem;
                      font-weight: 400;
                      line-height: 1.67;
                      letter-spacing: -0.2px;
                      word-break: break-all;
                    }
                  }
                }
                & > .flex {
                  flex-direction: column;
                  .flex.vert {
                    width: 100%;
                    margin-top: 48rem;
                    margin-left: 0;
                    .block_box {
                      & + .block_box {
                        margin-bottom: 24rem;
                      }
                      .text_wrap {
                        .main {
                          &-title {
                            font-size: 36rem;
                            font-weight: 800;
                            line-height: normal;
                            text-align: center;
                            &:not(:last-child) {
                              margin-bottom: 5rem;
                            }
                          }
                          &-text {
                            font-size: 16rem;
                            font-weight: 700;
                            line-height: 1.38;
                            text-align: center;
                            &:not(:last-child) {
                              margin-bottom: 13rem;
                            }
                            &.small {
                              font-size: 14rem;
                              font-weight: 300;
                              line-height: 1.57;
                              letter-spacing: -0.035px;
                              text-align: center;
                            }
                          }
                        }
                      }
                      &:last-child {
                        .text_wrap {
                          .main {
                            &-text {
                              &.small {
                                margin-top: 40rem;
                                margin-bottom: 10rem;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .img_wrap {
                    margin: 74rem 0 0;
                    width: 100%;
                    min-width: unset;
                    max-width: 100%;
                    height: 216rem;
                    .img {
                      &.appmeter {
                        &-cont {
                          &-01 {
                            margin-top: 0;
                            width: 100%;
                            max-width: 100%;
                            height: 100%;
                            max-height: 216rem;
                            border-radius: 12rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-4'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: 100%;
                max-width: unset;
                margin: 0;
                .flex {
                  flex-direction: column;
                  margin-top: 74rem;
                  & + .flex {
                    flex-direction: column-reverse;
                    margin-top: 120rem;
                  }
                  .img_wrap {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                    .img {
                      width: 100%;
                      min-width: unset;
                      max-width: 100%;
                      height: 100%;
                      max-height: 213rem;
                      border-radius: 12rem;
                      object-fit: cover;
                    }
                  }
                  .text_wrap {
                    .wrap {
                      &-title {
                        margin: 36rem 0;
                        font-size: 24rem;
                        font-weight: 800;
                        line-height: 1.42;
                      }
                      &-text {
                        margin: 0;
                        &:not(:last-child) {
                          margin-bottom: 0;
                        }
                        font-size: 20rem;
                        font-weight: 700;
                        line-height: 1.5;
                        & + .wrap-text {
                          margin-top: 16rem;
                        }
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-5'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: unset;
                max-width: unset;
                margin: 0;
                & > .text_wrap {
                  .main {
                    &-title {
                      font-size: 24rem;
                      font-weight: 800;
                      line-height: 1.42;
                    }
                  }
                  & + .flex {
                    margin-top: 74rem;
                  }
                }
                & > .flex {
                  flex-direction: column;
                  .block_box {
                    min-width: unset;
                    margin: 0;
                    &:not(:first-child) {
                      margin-top: 48rem;
                    }
                    .img_wrap {
                      .img {
                        max-width: 108rem;
                        max-height: 108rem;
                      }
                    }
                    .text_wrap {
                      margin-top: 20rem;
                      .main {
                        &-subtitle {
                          &.fs-28 {
                            font-size: 20rem;
                            font-weight: 700;
                            line-height: 1.5;
                            text-align: center;
                          }
                        }
                        &-text {
                          font-size: 14rem;
                          font-weight: 400;
                          line-height: 1.57;
                          text-align: center;
                          &:not(:first-child) {
                            margin-top: 16rem;
                          }
                        }
                      }
                    }
                  }
                  & + .flex {
                    margin-top: 48rem;
                  }
                }
                & + .block_wrap {
                  margin-top: 120rem;
                }
              }
            }
            &[aria-for='section-6'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                flex-direction: column;
                width: 100%;
                max-width: unset;
                margin: 0;
                .text_wrap {
                  text-align: center;
                  .main-title {
                    text-align: center;
                    &:not(:last-child) {
                      margin-bottom: 25rem;
                    }
                  }
                }
                .btn {
                  font-size: 12rem;
                  font-weight: 600;
                  min-width: 80rem;
                }
                .img_wrap {
                  width: 100%;
                  height: auto;
                  margin-top: 48rem;
                  text-align: center;
                  .img {
                    width: 200rem;
                    height: 132rem;
                    border-radius: 12rem;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
}
