// MO SET(SMALL)
@media screen and (max-width: 340px) {
}

@media screen and (min-width: 1100px) and (max-width: 1239px) {
}

// MO SET
@media screen and (max-width: 1239px) {
  .frame {
    &-contents {
      &.for_inquiry {
        .for_pc {
          display: none !important;
        }
        .for_mo {
          display: block !important;
        }
        .flex {
          & > .block_wrap {
            max-width: unset;
            margin: 120rem auto;
            padding: 0 24rem;
          }
          &.spaceBtw {
            flex-direction: column;
            margin-top: 50rem;
            & > *:not(.btn) {
              align-self: flex-start;
              align-items: center;
            }
            & > .btn {
              font-size: 14rem;
              align-self: center;
              margin-top: 50rem;
            }
          }
          &.center {
            flex-direction: column;
            align-items: center;
            justify-content: unset;
            & > .btn {
              &:not(:last-child) {
                margin-bottom: 15rem;
                margin-right: 0;
              }
              &:not(:first-child) {
                margin-top: 15rem;
                margin-left: 0;
              }
            }
          }
        }
        .block {
          &_wrap {
            & > .img_wrap {
              &:not(:last-child) {
                margin-bottom: 80rem;
                & + .main-mdtitle {
                  &:not(:last-child) {
                    margin-bottom: 50rem;
                  }
                  & ~ .main-text {
                    margin-bottom: 80rem;
                  }
                }
              }
              .img {
                max-width: 110rem;
                max-height: 93rem;
              }
              & ~ *:last-child {
                margin-bottom: 120rem;
              }
            }
            .main {
              &-title {
                font-size: 34rem;
                line-height: 1.29;
                margin-bottom: 22rem;
              }
              &-mdtitle {
                margin-bottom: 50rem;
              }
              &-text {
                margin-bottom: 50rem;
              }
            }
          }
          &_elem {
            flex: 1 1 100%;
            display: block;
            width: 100%;
            height: auto;
            &:not(:nth-child(1)) {
              margin-top: 30rem !important;
            }
            &:nth-child(2n-1):not(:nth-last-child(1)):not(:nth-last-child(2)) {
              margin-right: 0;
            }
            &:nth-child(2n):not(:nth-last-child(1)):not(:nth-last-child(2)) {
              margin-left: 0;
            }
            .error-text {
              font-size: 14rem;
            }
          }
          &_label {
            margin-bottom: 10rem;
            font-size: 14rem;
          }
          &_input, &_textarea {
            max-height: 48rem;
            font-size: 14rem;
          }
          &_textarea {
            min-height: 277rem;
          }
          &_check {
            &-wrap {
              .btn {
                font-size: 14rem;
              }
            }
            & + label {
              align-items: flex-start;
              font-size: 14rem;
              font-weight: 400;
              line-height: 1.57;
              &:before {
                width: 12rem;
                height: 12rem;
                margin: 0 10rem 0 0;
                transform: translate(0, 4rem);
              }
            }
          }
        }
      }
    }
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
}
