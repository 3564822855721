// MO SET(SMALL)
@media screen and (max-width: 340px) {
}

@media screen and (min-width: 700px) and (max-width: 1239px) {
  .frame-container.for_universe {
    &#fixedScroll_1 {
      .frame-container-bg.type_2 {
        &:before {
          background-image: var(--img-universe-bg01-02);
        }
      }
    }
    &#fixedScroll_3 {
      .frame-container-bg {
        &:before {
          background-size: 135%;
        }
      }
    }
  }
}

// MO SET
@media screen and (max-width: 1239px) {
  .scrollmagic-pin-spacer {
    &:not(:nth-child(2)) {
      .scrollinner.for_universe {
        //inset: 60px auto auto !important;
      }
    }
    &:nth-child(3), &:nth-child(4) {
      .scrollinner.for_universe {
        //inset: 60px auto auto !important;
      }
    }
  }
  .frame {
    &-container {
      &.for_universe {
        .frame-container-bg {
          //top: -60px;
          &.section {
            &_1 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-universe-bg01-01-mo);
                  }
                }
                &_2 {
                  &:before {
                    background-image: var(--img-universe-bg01-02-mo);
                  }
                }
              }
            }
            &_3 {
              &.type {
                &_1 {
                  &:before {
                    opacity: 0;
                    background-image: var(--img-universe-earth2);
                    background-size: 280%;
                  }
                }
              }
            }
          }
        }
        &.scrollinner {
          //height: 100%;
          //top: -60px;
          &#fixedScroll_2, &#fixedScroll_3 {
            //height: calc(100% + 60px);
          }
          .flex {
            .wrap {
              width: calc(100% - 48rem);
              .text_wrap {
                .main {
                  &-point {
                    &:not(:last-child) {
                      margin-bottom: 12rem;
                    }
                  }
                }
              }
              .img_wrap {
                .img {
                  &.universe {
                    &.bg {
                      width: 213rem;
                      height: 213rem;
                      //margin: 82rem auto 0;
                      margin: calc(100vh * 0.1) auto 0;
                      background-image: var(--img-universe-earth);
                      .img_text {
                        font-size: 6rem;
                        font-weight: 400;
                      }
                    }
                    &.diagram {
                      width: 337rem;
                      height: 337rem;
                      margin: 0;
                      background-image: var(--img-universe-diagram);
                    }
                    &.line {
                      &-1 {
                        width: 358rem;
                        height: 24rem;
                        top: 154rem;
                        background-image: var(--img-universe-line-01-mo);
                      }
                      &-2 {
                        width: 301rem;
                        height: 67rem;
                        top: 157rem;
                        background-image: var(--img-universe-line-02-mo);
                      }
                      &-3 {
                        width: 267rem;
                        height: 82rem;
                        top: 199rem;
                        background-image: var(--img-universe-line-03-mo);
                      }
                      &-4 {
                        width: 233rem;
                        height: 96rem;
                        top: 223rem;
                        background-image: var(--img-universe-line-04-mo);
                      }
                      &-5 {
                        width: 85rem;
                        height: 85rem;
                        background-image: var(--img-universe-line-05);
                        transform: translate(-50%, -50%);
                      }
                      &-6 {
                        width: 166rem;
                        height: 166rem;
                        background-image: var(--img-universe-line-06);
                        transform: translate(-50%, -50%);
                      }
                      &-7 {
                        width: 246rem;
                        height: 246rem;
                        background-image: var(--img-universe-line-07);
                        //transform: translate(-50%, -50%);
                        transform: translate(-50%, calc(-50% - 5px)) scale(1.02);
                      }
                      &-8 {
                        width: 337rem;
                        height: 337rem;
                        background-image: var(--img-universe-line-08);
                        transform: translate(-50%, -50%);
                      }
                    }
                    &.rotate {
                      &-plate {
                        width: 136.53vw;
                        max-width: 512rem;
                        height: 136.53vw;
                        max-height: 512rem;
                        background-image: var(--img-universe-rotate-plate);
                        background-position: center;
                      }
                      &-front {
                        width: 136.53vw;
                        max-width: 512rem;
                        height: 136.53vw;
                        max-height: 512rem;
                        background-image: var(--img-universe-rotate-front);
                      }
                    }
                  }
                }
              }
              &[aria-for='section-1'], &[aria-for='section-2'] {
                .text_wrap {
                  //transform: translate(0, -123rem);
                  transform: translate(0, calc(100vh * -0.15));
                  .main-text {
                    &.fs-23 {
                      font-weight: 700;
                    }
                  }
                }
              }
              &[aria-for='section-3'] {
                .text_wrap {
                  white-space: unset;
                }
              }
              &[aria-for='section-4'] {
                .text_wrap {
                  .circle {
                    &-wrap {
                      //margin: 99rem auto auto;
                      margin: calc(100vh * 0.120732) auto auto;
                    }
                  }
                }
              }
              &[aria-for='section-5'] {
                .text_wrap {
                  //margin-top: 120rem;
                  margin-top: calc(100vh * 0.146341);
                }
              }
              &[aria-for='section-6'] {
                width: auto;
                .img_wrap {
                  //transform: translate(0, 162rem);
                  transform: translate(0, calc((100vh * 0.197561) + 2px));
                  .img {
                    &.universe {
                      &.bg {
                        width: 185rem;
                        height: 185rem;
                        margin: 0 auto;
                      }
                    }
                  }
                }
              }
              &[aria-for='section-7'], &[aria-for='section-8'], &[aria-for='section-9'], &[aria-for='section-10'] {
                width: auto;
                .img_wrap {
                  //transform: translate(0, 162rem);
                  transform: translate(0, calc(100vh * 0.197561));
                }
              }
              &[aria-for='section-11'], &[aria-for='section-12'] {
                width: auto;
                .img_wrap {
                  //transform: translate(0, 162rem);
                  transform: translate(0, calc(100vh * 0.197561));
                }
                .text_wrap {
                  transform: translate(0, -50%);
                  //margin-bottom: 136rem;
                  margin-bottom: calc(100vh * 0.165854);
                }
              }
              &[aria-for='section-11'] {
                min-width: calc(100% - 48rem);
                margin-top: -60rem;
              }
              &[aria-for='section-13'] {
                width: 100%;
                margin-top: 60px;
                .text_wrap {
                  //margin-top: 120rem;
                  margin-top: calc(100vh * 0.146341);
                  transform: unset;
                  &:not(:last-child) {
                    //margin-bottom: 116rem;
                    margin-bottom: calc(100vh * 0.141463);
                  }
                  .main {
                    &-subpoint {
                      font-size: 28rem;
                      font-weight: 400;
                      line-height: 1.36;
                      text-align: center;
                    }
                  }
                  .list-wrap {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    max-width: 100%;
                    text-align: center;
                    &:not(:first-child) {
                      //margin-top: 78rem;
                      margin-top: calc(100vh * 0.095122);
                    }
                    .list_elem {
                      display: inline-flex;
                      align-items: flex-start;
                      width: auto;
                      font-size: 12rem;
                      font-weight: 500;
                      line-height: 1.33;
                      span {
                        display: inline-block;
                        max-width: 75rem;
                        word-break: break-word;
                      }
                      &:not(:first-child) {
                        margin-top: unset;
                        margin-left: 20rem;
                      }
                      &:before {
                        width: 8rem;
                        height: 8rem;
                        margin-top: 4rem;
                        margin-right: 4rem;
                      }
                      &.active {
                        span {
                          font-weight: 700;
                        }
                      }
                    }
                  }
                }
                .img_wrap {
                  width: 136.53vw;
                  height: 136.53vw;
                  max-width: 512rem;
                  max-height: 512rem;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
              }
              &[aria-for='section-14'] {
                .text_wrap {
                  .main {
                    &-subpoint {
                      &:not(:last-child) {
                        margin-bottom: 12rem;
                      }
                    }
                    &-title {
                      &:not(:last-child) {
                        margin-bottom: 20rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.for_tech {
        .frame-container-bg {
          background-image: var(--img-tech-bg01-01-mo);
        }
        .frame-container-text {
          margin-top: -30px;
        }
        & + .frame-contents {
          .frame-contents__inner {
            overflow: hidden;
            &:first-child {
              display: flex;
              align-items: center;
              height: 100vh;
              margin: auto;
              padding: 24rem;
            }
            &[class*='sec-'] {
              min-height: 100vh;
              & > * {
                &.block_wrap {
                  max-width: 100%;
                  //margin: 120rem 0 auto;
                  margin: calc(100vh * 0.146341) 0 auto;
                  padding: 0 24rem;
                  .text_wrap {
                    .main {
                      &-smtitle {
                        &:not(:last-child) {
                          margin-bottom: 12rem;
                        }
                      }
                      &-title {
                        &:not(:last-child) {
                          margin-bottom: 30rem;
                        }
                      }
                    }
                  }
                }
              }
              .frame-contents__inner-bg {
                min-height: 100vh;
              }
            }
            &.sec {
              &-1 {
                & > * {
                  &.frame-contents__inner-bg {
                    background-image: var(--img-tech-01-mo);
                  }
                  .text_wrap {
                    min-width: auto;
                    max-width: 100%;
                    margin: 0;
                  }
                }
              }
              &-2 {
                & > * {
                  &.frame-contents__inner-bg {
                    background-image: var(--img-tech-02-mo);
                  }
                  .text_wrap {
                    min-width: auto;
                    max-width: 100%;
                    margin: 0;
                  }
                }
              }
              &-3 {
                & > * {
                  &.frame-contents__inner-bg {
                    background-image: var(--img-tech-03-mo);
                  }
                  .text_wrap {
                    min-width: auto;
                    max-width: 100%;
                    margin: 0;
                    transform: translate(0, calc(100vh * -0.012195));
                  }
                }
              }
              &-4 {
                margin-top: min(-200rem, -24.39vh);
                & > * {
                  &.frame-contents__inner-bg {
                    background-image: var(--img-tech-04-mo);
                  }
                  .text_wrap {
                    min-width: auto;
                    max-width: 100%;
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
      &.for_card {
        .frame-container-bg {
          background-image: var(--img-card-bg01-01-mo);
        }
        .frame-container-text {
          margin-top: -40px;
        }
      }
      &.for_emv {
        .frame-container-bg {
          background-image: var(--img-emv-bg01-01-mo);
        }
        .frame-container-text {
          margin-top: -40px;
        }
      }
      &.for_ota {
        .frame-container-bg {
          background-image: var(--img-ota-bg01-01-mo);
        }
        .frame-container-text {
          margin-top: -40px;
        }
      }
      &.for_blockchain {
        .frame-container-bg {
          background-image: var(--img-blockchain-bg01-01-mo);
        }
        .frame-container-text {
          margin-top: -40px;
        }
      }
      .for_pc {
        display: none !important;
      }
      .for_mo {
        display: block !important;
      }
    }
    &-contents {
      .for_pc {
        display: none !important;
      }
      .for_mo {
        display: block !important;
      }
      &__inner {
        div[class*='sec-'] {
          position: unset;
          padding: 0;
          background-color: transparent;
        }
        &.with_img {
          & > * {
            &.block_wrap {
              .text_wrap {
                .btn-sm {
                  display: inline-flex;
                  align-items: center;
                  width: auto;
                  height: 39rem;
                  max-height: 39rem;
                  padding: 10rem 20rem;
                }
              }
            }
          }
          .text_wrap {
            .main {
              &-mdtitle {
                &:not(:last-child) {
                  margin-bottom: 12rem;
                }
              }
              &-text {
                &:not(:last-child) {
                  margin-bottom: 30rem;
                }
              }
            }
          }
          .contents-img {
            &.tech {
              &-01 {
                content: var(--img-tech-01-mo);
              }
              &-02 {
                content: var(--img-tech-02-mo);
              }
              &-03 {
                content: var(--img-tech-03-mo);
              }
              &-04 {
                content: var(--img-tech-04-mo);
              }
            }
            &.card {
              &-01 {
                content: var(--img-card-01-mo);
              }
              &-02 {
                content: var(--img-card-02-mo);
              }
              &-03 {
                content: var(--img-card-03-mo);
              }
            }
            &.emv {
              &-01 {
                content: var(--img-emv-01-mo);
              }
              &-02 {
                content: var(--img-emv-02-mo);
              }
              &-03 {
                content: var(--img-emv-03-mo);
              }
            }
            &.ota {
              &-01 {
                content: var(--img-ota-01-mo);
              }
              &-02 {
                content: var(--img-ota-02-mo);
              }
              &-03 {
                content: var(--img-ota-03-mo);
              }
            }
            &.blockchain {
              &-01 {
                content: var(--img-blockchain-01-mo);
              }
              &-02 {
                content: var(--img-blockchain-02-mo);
              }
              &-03 {
                content: var(--img-blockchain-03-mo);
              }
            }
          }
        }
      }
    }
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
}
