.scrollmagic-pin-spacer {
  &:not(:nth-child(2)) {
    .scrollinner.for_careers {
      inset: 80px auto auto !important;
    }
  }
}
.frame {
  &-container {
    &__inner {
      &#fixedScroll_1 {
        height: 100% !important;
        .wrap {
          &[aria-for="section-1"] {
            height: 100%;
            background-color: $--konai-white;
          }
          &.hidden {
            * {
              display: none !important;
            }
          }
        }
      }
    }
    &.for_careers {
      .frame-container-bg {
        height: 100%;
        top: 0;
        background-color: $--konai-white;
        &.active {
          background-color: $--konai-black;
          transition: background-color 0.1s 0.65s ease-in-out;
          &:before {
            opacity: 1;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.dimmed {
          &:before {
            opacity: 0.4;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.fixed {
          position: fixed;
          top: 0;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 0;
          opacity: 0;
          transition: opacity 0.65s ease-in-out;
        }
        &.section {
          &_1 {
            &.type {
              &_1 {
                &:before {
                  background-image: var(--img-careers-bg01-01);
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
        }
      }
      div[class*='sec-'] {
        padding: 0;
        background-color: transparent;
      }
      &.scrollinner {
        height: 100%;
        top: -80px;
        .flex {
          .wrap {
            position: absolute;
            display: block;
            width: auto;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .text_wrap {
              * {
                color: $--konai-white;
              }
            }
            &[aria-for='section-2'] {
              white-space: nowrap;
            }
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
  }
  &-contents {
    .tab-contents {
      &__inner[aria-for*='careers_tab_'] {
        max-width: unset;
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
        .wrap {
          max-width: 1200px;
          margin: 0 auto;
          &[aria-for='section-3'] {
            width: 100%;
            margin-bottom: 180rem;
          }
          &[aria-for='section-4'] {
            max-width: unset;
            margin: unset;
            .step {
              &-wrap {
                display: flex;
                flex-direction: column;
                //max-width: 1200px;
                //margin-left: auto;
                //margin-right: auto;
              }
              &-elem {
                & > * {
                  //max-width: 1200px;
                  max-width: 1076px;
                  margin-left: auto;
                  margin-right: auto;
                }
                .circle {
                  &-wrap {
                    justify-content: flex-start;
                    margin-top: 52rem;
                    height: auto;
                    padding: 0;
                  }
                }
              }
            }
          }
          &[aria-for='section-5'] {
            .card {
              &-wrap {
                .swiper {
                  &-wrapper {
                    justify-content: center;
                  }
                }
              }
              &-elem {
                display: flex;
                flex-direction: column;
                .img_wrap {
                  width: 90rem;
                  min-width: 90rem;
                  height: 90rem;
                  min-height: 90rem;
                  &:not(:first-child) {
                    margin-left: auto;
                    //margin-left: 24rem;
                  }
                  .img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    &.career {
                      &.highfive {
                        content: var(--img-careers-01);
                      }
                      &.apply {
                        content: var(--img-careers-02);
                      }
                      &.story {
                        content: var(--img-careers-03);
                      }
                    }
                  }
                }
                .btn-shortcut {
                  width: auto;
                  align-self: flex-start;
                }
              }
            }
          }
          &[aria-for='section-6'] {
            .frame-contents__inner-bg {
              display: flex;
              width: 100%;
              height: 560rem;
              overflow: hidden;
              &:before {
                content: '';
                position: absolute;
                display: block;
                left: 0;
                width: 100%;
                height: 560rem;
                background-image: var(--img-careers-bg01-02);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                z-index: -2;
              }
              &:after {
                content: '';
                position: absolute;
                display: block;
                left: 0;
                width: 100%;
                height: 560rem;
                background-color: $--konai-gray-1;
                opacity: 0.15;
                z-index: -1;
              }
              & > .text_wrap {
                margin: auto;
                .main-title {
                  text-align: center;
                  color: $--konai-white;
                  &:not(:last-child) {
                    margin-bottom: 38rem;
                  }
                }
              }
            }
          }
          &[aria-for='section-7'] {
            max-width: unset;
            margin: unset;
            overflow: hidden;
            background-color: $--konai-lightgray-3;
            .flex {
              max-width: 1200px;
              margin: 180rem auto;
              .text_wrap {
                min-width: 279rem;
                margin-right: 150rem;
                text-align: left;
                * {
                  text-align: left;
                }
                .btn {
                  margin-top: 46rem;
                }
              }
              .card {
                &-wrap {
                  width: 100%;
                  //max-width: 100%;
                  max-width: calc((100% - 429rem) + (100vw - 1200rem)/2);
                  margin: 0;
                  .swiper {
                    margin-top: -20rem;
                    padding: 20rem 50rem 20rem 20rem;
                    &-slide {
                      width: 369rem;
                    }
                  }
                }
                &-elem {
                  &.konadaum {
                    margin: 0;
                    padding: 0;
                    border-radius: 12rem;
                    &:hover {
                      .text_wrap {
                        opacity: 1;
                      }
                    }
                    &.n {
                      &-1 {
                        background-image: var(--img-careers-konadaum-01);
                      }
                      &-2 {
                        background-image: var(--img-careers-konadaum-02);
                      }
                      &-3 {
                        background-image: var(--img-careers-konadaum-03);
                      }
                      &-4 {
                        background-image: var(--img-careers-konadaum-04);
                      }
                      &-5 {
                        background-image: var(--img-careers-konadaum-05);
                      }
                      &-6 {
                        background-image: var(--img-careers-konadaum-06);
                      }
                      &-7 {
                        background-image: var(--img-careers-konadaum-07);
                      }
                    }
                    .text_wrap {
                      position: absolute;
                      display: flex;
                      width: 369rem;
                      height: 100%;
                      padding: 20rem;
                      left: 0;
                      top: 0;
                      text-align: center;
                      background-color: rgba(0,0,0,0.8);
                      border-radius: 12rem;
                      opacity: 0;
                      transition: opacity 0.3s ease-in-out;
                      & > * {
                        margin: auto;
                        color: $--konai-white;
                        text-align: center;
                        &.card-text {
                          font-size: 20rem;
                          font-weight: 700;
                          letter-spacing: -0.4px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &[aria-for='section-8'] {
            & > .text_wrap {
              margin-top: 180rem;
              .main {
                &-title {
                  text-align: left;
                }
                &-text {
                  text-align: left;
                  &:not(:first-child) {
                    margin-top: 50rem;
                  }
                }
              }
            }
            .card {
              &-wrap {
                margin-top: 118rem;
              }
              &-elem {
                &.with_text {
                  .img_wrap {
                    .img {
                      &.card {
                        &.n {
                          &-1 {
                            content: var(--img-careers-04);
                          }
                          &-2 {
                            content: var(--img-careers-05);
                          }
                          &-3 {
                            content: var(--img-careers-06);
                          }
                          &-4 {
                            content: var(--img-careers-07);
                          }
                          &-5 {
                            content: var(--img-careers-08);
                          }
                          &-6 {
                            content: var(--img-careers-09);
                          }
                          &-7 {
                            content: var(--img-careers-10);
                          }
                        }
                      }
                    }
                  }
                }
              }
              &-text {
                word-break: keep-all;
              }
            }
          }
          &[aria-for='section-9'] {
            max-width: unset;
            margin: unset;
            overflow: hidden;
            background-color: $--konai-lightgray-3;
            .block_wrap {
              max-width: 1200px;
              margin: 180rem auto;
              .text_wrap {
                .main {
                  &-title {
                    text-align: center;
                  }
                  &-text {
                    text-align: center;
                    &:not(:first-child) {
                      margin-top: 50rem;
                    }
                  }
                }
              }
              .benefit {
                &-wrap {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  width: 100%;
                  height: auto;
                  margin-top: 118rem;
                }
                &-elem {
                  flex: 1 1 40%;
                  display: flex;
                  align-items: flex-start;
                  width: 100%;
                  height: auto;
                  &:not(:nth-child(1)):not(:nth-child(2)) {
                    margin-top: 90rem;
                  }
                  &:nth-child(2n) {
                    margin-left: 100rem;
                  }
                  .img_wrap {
                    display: block;
                    width: 124rem;
                    height: 124rem;
                    &:not(:last-child) {
                      margin-right: 40rem;
                    }
                    .img {
                      width: 100%;
                      min-width: 124rem;
                      height: 100%;
                      &.n {
                        &-1 {
                          content: var(--img-careers-ico-01);
                        }
                        &-2 {
                          content: var(--img-careers-ico-02);
                        }
                        &-3 {
                          content: var(--img-careers-ico-03);
                        }
                        &-4 {
                          content: var(--img-careers-ico-04);
                        }
                        &-5 {
                          content: var(--img-careers-ico-05);
                        }
                        &-6 {
                          content: var(--img-careers-ico-06);
                        }
                        &-7 {
                          content: var(--img-careers-ico-07);
                        }
                        &-8 {
                          content: var(--img-careers-ico-08);
                        }
                        &-9 {
                          content: var(--img-careers-ico-09);
                        }
                        &-10 {
                          content: var(--img-careers-ico-10);
                        }
                        &-11 {
                          content: var(--img-careers-ico-11);
                        }
                        &-12 {
                          content: var(--img-careers-ico-12);
                        }
                      }
                    }
                  }
                  .text_wrap {
                    width: auto;
                    margin: 0;
                  }
                }
                &-title {
                  font-size: 28rem;
                  font-weight: 800;
                  line-height: 1.29;
                  color: $--konai-black;
                  text-align: left;
                }
                &-text {
                  font-size: 22rem;
                  font-weight: 400;
                  line-height: 1.64;
                  color: $--konai-black;
                  text-align: left;
                  word-break: keep-all;
                  &:not(:first-child) {
                    margin-top: 10rem;
                  }
                }
              }
            }
          }
          &[aria-for='section-10'] {
            position: relative;
            max-width: unset;
            margin: unset;
            overflow: hidden;
            background-color: $--konai-gray-0;
            .block_wrap {
              position: relative;
              display: inline-block;
              width: 100%;
              height: 780rem;
              .text_wrap {
                position: relative;
                max-width: 1200px;
                //margin: 100rem auto 0 360rem;
                margin: 100rem auto 0;
                width: 100%;
                height: auto;
                z-index: 1;
                .main-point {
                  color: $--konai-white;
                  line-height: 1.1;
                  text-align: left;
                  pointer-events: none;
                  user-select: none;
                  span {
                    &:not(:first-child) {
                      display: inline-block;
                      transform: translate(485rem, 0);
                    }
                  }
                }
              }
              .falling-wrap {
                position: absolute;
                display: block;
                width: 100%;
                height: 780rem;
                top: 0;
                left: 0;
                z-index: 0;
              }
            }
          }
        }
        .main {
          &-subtitle {
            text-align: left;
          }
          &-title {
            text-align: left;
            &:not(:first-child) {
              margin-top: 20rem;
            }
          }
        }
      }
      &__inner[aria-for*='careers_tab_01'] {
        padding-bottom: 0;
      }
    }
  }
}


// 미디어쿼리 @media
@import './pages/careers_media';