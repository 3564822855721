// MO SET(SMALL)
@media screen and (max-width: 340px) {
}

@media screen and (min-width: 1100px) and (max-width: 1239px) {
}

// MO SET
@media screen and (max-width: 1239px) {
  .scrollmagic-pin-spacer {
    &:not(:nth-child(2)) {
      .scrollinner.for_did {
        inset: 60px auto auto !important;
      }
    }
  }
  .frame {
    &-container {
      &.for_did {
        .frame-container-bg {
          &.section {
            &_1 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-did-bg-mo);
                  }
                }
              }
            }
          }
        }
        &.scrollinner {
          height: 100%;
          top: -60px;
          .flex {
            .wrap {
              width: calc(100% - 48rem);
              .text_wrap {
                * {
                  white-space: normal;
                }
              }
              .img_wrap {
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-2'] {
            .main-text {
              &.fs-23 {
                font-weight: 700;
              }
            }
          }
        }
        .for_pc {
          display: none !important;
        }
        .for_mo {
          display: block !important;
        }
      }
    }
    &-contents {
      &__inner {
        &.for_did {
          .wrap {
            &[aria-for='section-3'] {
              padding: 120rem 24rem 0;
              .main {
                &-subtitle {
                  margin-bottom: 15rem !important;
                  font-size: 16rem;
                  font-weight: 800;
                  line-height: 1.5;
                }
                &-title {
                  margin-bottom: 36rem !important;
                }
              }
              .accordion {
                &-wrap {
                  width: calc(100% + 48rem);
                  margin-left: -24rem;
                  &:not(:first-child) {
                    margin-top: 120rem;
                  }
                }
                &-elem {
                  & > .flex {
                    max-width: unset;
                    width: 100%;
                    margin: 0;
                    .img_wrap {
                      .img {
                        width: 100%;
                        height: auto;
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-4'] {
              padding: 120rem 24rem 0;
              & > .block_wrap {
                .card {
                  &-wrap {
                    width: calc(100% + 48rem);
                    margin-top: 74rem;
                    margin-left: -24rem;
                  }
                  &-title {
                    font-size: 22rem;
                    line-height: 1.36;
                  }
                }
                .swiper {
                  width: 100%;
                  padding: 0 24rem;
                }
              }
            }
          }
          .for_pc {
            display: none !important;
          }
          .for_mo {
            display: block !important;
          }
        }
      }
      .tab {
        &-contents {
          &__inner {
            &[aria-for*='did_tab_'] {
              padding: 0;
              .flex {
                .wrap {
                  &[aria-for='section-5'] {
                    padding: 120rem 24rem 0;
                    & > .block_wrap {
                      flex-direction: column;
                      .img_wrap {
                        width: 100%;
                        max-width: unset;
                        height: 213rem;
                      }
                      .text_wrap {
                      }
                    }
                  }
                  &[aria-for='section-6'], &[aria-for='section-7'], &[aria-for='section-8'] {
                    margin-top: 74rem !important;
                    padding: 0 24rem 46rem;
                    & > .block_wrap {
                      flex-direction: column;
                      .img_wrap {
                        width: 100%;
                        min-width: unset;
                        max-width: unset;
                        height: 213rem;
                        margin-left: unset !important;
                        margin-right: unset !important;
                      }
                      .text_wrap {
                        margin-top: 36rem;
                        .main {
                          &-subtitle {
                            &.fs-24 {
                              margin-top: 0;
                              font-size: 16rem;
                              font-weight: 600;
                              line-height: 1.5;
                            }
                          }
                          &-title {
                            &.fs-48 {
                              margin-top: 10rem;
                            }
                          }
                          &-text {
                            margin-top: 18rem;
                            //word-break: break-all;
                            word-break: break-word;
                          }
                        }
                        .btn {
                          &-sm {
                            height: 39rem;
                            max-height: 39rem;
                            margin-top: 30rem;
                            padding: 10rem 20rem;
                            font-size: 14rem;
                            font-weight: 600;
                            line-height: 1;
                          }
                        }
                      }
                    }
                  }
                  &[aria-for='section-7'] {
                    & > .block_wrap {
                      flex-direction: column-reverse;
                    }
                  }
                  &[aria-for='section-8'] {
                    padding-bottom: 120rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
}
