// MO SET(SMALL)
@media screen and (max-width: 340px) {
}

@media screen and (min-width: 1100px) and (max-width: 1239px) {
}

// MO SET
@media screen and (max-width: 1239px) {
  .frame {
    &-container {
      &.for_ir {
        .frame-container-bg {
          background-image: var(--img-ir-bg01-01-mo);
        }
        .frame-container-text {
          margin-top: -30px;
        }
      }
      &.for_pr {
        .frame-container-bg {
          background-image: var(--img-pr-bg01-01-mo);
        }
        .frame-container-text {
          margin-top: -30px;
        }
      }
      .for_pc {
        display: none !important;
      }
      .for_mo {
        display: block !important;
      }
    }
    &-contents {
      .table {
        &-elem {
          tr {
            td {
              &.larger {
                font-size: 14rem;
                font-weight: 600;
                line-height: 1;
              }
            }
          }
          .ir {
            .f-clr-standby {
              transform: translate(8rem, 0);
            }
            .f-clr-increase {
              transform: translate(8rem, 0);
            }
            .f-clr-decrease {
              transform: translate(8rem, 0);
            }
          }
        }
      }
      .juga {
        &.table-title {
          & > .wrap {
            display: flex;
            flex-direction: column;
          }
          & + .flex {
            margin-top: 6rem;
          }
        }
        & + .flex {
          & > .table-wrap {
            &:first-child {
              .table-elem {
                &.data {
                  th, td {
                    padding: 15px 0;
                  }
                }
              }
            }
          }
        }
        &-title {
          font-size: 25rem;
          font-weight: 700;
          line-height: 1;
          &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 24rem;
          }
          &.ir {
            .f-clr-standby {
              padding-left: 30px;
              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                width: 20px;
                height: 20px;
                border-top: calc(10px * 1.732) solid transparent;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: calc(10px * 1.732) solid transparent;
                border-bottom-color: transparent;
                transform: translate(calc(-100% - 7px), -12px);
              }
            }
            .f-clr-increase {
              padding-left: 30px;
              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                width: 20px;
                height: 20px;
                border-top: calc(10px * 1.732) solid transparent;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: calc(10px * 1.732) solid transparent;
                border-bottom-color: $--konai-increase;
                transform: translate(calc(-100% - 7px), -12px);
              }
            }
            .f-clr-decrease {
              padding-left: 30px;
              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                width: 20px;
                height: 20px;
                border-top: calc(10px * 1.732) solid transparent;
                border-top-color: $--konai-decrease;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: calc(10px * 1.732) solid transparent;
                transform: translate(calc(-100% - 7px), 4px);
              }
            }
          }
        }
        &-text {
          font-size: 14rem;
          font-weight: 700;
          line-height: 1.88;
          &:not(:last-child) {
            margin-right: 8px;
          }
          .f-clr-increase {
            margin-left: 4px;
          }
          .f-clr-decrease {
            margin-left: 4px;
          }
          &.ir {
            .f-clr-increase {
              padding-left: 17px;
              &::before {
                width: 12px;
                height: 12px;
                border-top: calc(6px * 1.732) solid transparent;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: calc(6px * 1.732) solid transparent;
                border-bottom-color: $--konai-increase;
                transform: translate(calc(-100% - 5px), -2px);
              }
            }
            .f-clr-decrease {
              padding-left: 17px;
              &::before {
                width: 12px;
                height: 12px;
                border-top: calc(6px * 1.732) solid transparent;
                border-top-color: $--konai-decrease;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: calc(6px * 1.732) solid transparent;
                transform: translate(calc(-100% - 5px), 8px);
              }
            }
          }
        }
        &-date {
          font-size: 10rem;
          font-weight: 400;
          //line-height: 1.88;
          line-height: 26rem;
        }
        .for_pc {
          display: none !important;
        }
        .for_mo {
          display: block !important;
        }
      }
    }
  }
  .tab-contents__inner {
    &[aria-for*='ir_'] {
      .table {
        &-elem {
          &.data {
            &-6 {
              tbody {
                tr {
                  td {
                    &:nth-child(2n-1) {
                      padding-left: 40px; padding-right: 20px;
                    }
                    &:nth-child(2n) {
                      padding-right: 40px; padding-left: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .board-elem {
        .for_mo {
          display: table-cell !important;
        }
        .for_pc {
          display: none !important;
        }
        colgroup {
          .for_mo {
            display: table-column !important;
          }
        }
      }
    }
    &[aria-for='ir_tab_01'], &[aria-for='ir_tab_03'] {
      .flex {
        &:not(.vert) {
          flex-direction: column;
          & > .table-wrap {
            //width: 100%;
            width: calc(100% + 24rem);
            .table-elem {
              width: calc(100% - 24rem);
              margin-right: 24rem;
            }
            & + .table-wrap {
              //width: 100%;
              width: calc(100% + 24rem);
              margin-top: 30rem;
              margin-left: 0;
              .table-elem {
                width: calc(100% - 24rem);
                margin-right: 24rem;
              }
            }
          }
        }
      }
    }
    &[aria-for='ir_tab_01'] {
      .tab {
        &-title {
          margin: 0 0 37rem;
        }
      }
    }
    &[aria-for='ir_tab_02'], &[aria-for='ir_tab_04'] {
      .tab {
        &-title {
          margin: 0 0 75rem;
        }
      }
    }
    &[aria-for='ir_tab_03'] {
      .tab {
        &-title {
          margin: 0 0 80rem;
        }
      }
    }
    &[aria-for='pr_tab_01'], &[aria-for='pr_tab_02'] {
      .tab {
        &-title {
          margin: 0 0 60rem;
        }
      }
    }
    &[aria-for*='pr_'] {
      .board {
        &-detail {
          font-size: 16rem;
          line-height: 1.5;
          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }
      .table {
        &-wrap {
          width: 100%;
          overflow: unset;
        }
        &-elem {
          width: 100%;
          margin-right: 0;
        }
      }
      .board-elem {
        .for_mo {
          display: table-cell !important;
        }
        .for_pc {
          display: none !important;
        }
        colgroup {
          .for_mo {
            display: table-column !important;
          }
        }
      }
    }
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
}
