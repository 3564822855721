// MO SET(SMALL)
@media screen and (max-width: 340px) {
}

@media screen and (min-width: 1100px) and (max-width: 1239px) {
}

// MO SET
@media screen and (max-width: 1239px) {
  .scrollmagic-pin-spacer {
    &:not(:nth-child(2)) {
      .scrollinner.for_pBlockchain {
        inset: 60px auto auto !important;
      }
    }
  }
  //html {
  //  overflow-x: hidden;
  //}
  .frame {
    &-container {
      &.for_pBlockchain, &.for_pNcp {
        .frame-container-bg {
          &.section {
            &_1 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-pblockchain-bg-mo);
                  }
                }
              }
            }
            &_2 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-pncp-bg-mo);
                  }
                }
              }
            }
          }
        }
        &.scrollinner {
          height: 100%;
          top: -60px;
          .flex {
            .wrap {
              width: calc(100% - 48rem);
              .text_wrap {
              }
              .img_wrap {
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-2'] {
            .text_wrap {
              .main-text {
                white-space: normal;
                &.fs-23 {
                  font-weight: 700;
                }
              }
            }
          }
        }
        .for_pc {
          display: none !important;
        }
        .for_mo {
          display: block !important;
        }
      }
    }
    &-contents {
      &__inner {
        &.for_pBlockchain {
          .wrap {
            &[aria-for='section-3'] {
              padding: 120rem 24rem 0;
              .accordion {
                &-wrap {
                  width: calc(100% + 48rem);
                  margin-top: 120rem;
                  margin-left: -24rem;
                }
                &-elem {
                  & > .flex {
                    max-width: unset;
                    width: 100%;
                    margin: 0;
                    .img_wrap {
                      width: 100%;
                      height: auto;
                      max-height: 176rem;
                      text-align: left;
                      .img {
                        width: 100%;
                        height: 100%;
                        max-height: 176rem;
                        border-radius: 12rem;
                        object-fit: cover;
                        object-position: left center;
                      }
                    }
                    .text_wrap {
                      margin-left: 10rem;
                    }
                  }
                }
              }
            }
            &[aria-for='section-4'] {
              padding: 120rem 24rem 0;
              & > .block_wrap {
                .card-wrap {
                  width: calc(100% + 48rem);
                  margin-top: 74rem;
                  margin-left: -24rem;
                  margin-bottom: 85rem;
                }
                .swiper {
                  width: 100%;
                  padding: 0 24rem;
                  &-scrollbar {
                    width: calc(100% - 48rem);
                    margin: 0 -24rem;
                  }
                }
              }
            }
          }
          .for_pc {
            display: none !important;
          }
          .for_mo {
            display: block !important;
          }
        }
        &.for_pNcp {
          .img_wrap {
            .img {
              &.ncp {
                &-cont {
                  &-01 {
                    display: block;
                    max-width: 100%;
                    height: 213rem;
                    max-height: 213rem;
                    border-radius: 12rem;
                  }
                  &-02 {
                    display: block;
                    max-width: 100%;
                    height: 213rem;
                    max-height: 213rem;
                    border-radius: 12rem;
                  }
                  &-03 {
                    display: block;
                    max-width: 100%;
                    height: 213rem;
                    max-height: 213rem;
                    border-radius: 12rem;
                  }
                  &-04 {
                    display: block;
                    max-width: 100%;
                    height: 213rem;
                    max-height: 213rem;
                    border-radius: 12rem;
                  }
                }
              }
            }
          }
          .wrap {
            &[aria-for='section-3'] {
              padding: 120rem 24rem;
            }
            &[aria-for='section-4'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                & > .text_wrap {
                  margin-bottom: 74rem;
                }
                & > .flex {
                  flex-direction: column;
                  margin-top: 0;
                  .wrap {
                    margin-left: 0;
                    margin-right: 0;
                    &:not(:first-child) {
                      margin-top: 120rem;
                    }
                    & > .img_wrap {
                      margin-bottom: 36rem;
                      height: auto;
                    }
                    &-title {
                      font-size: 24rem;
                      font-weight: 800;
                      line-height: 1.42;
                      &:not(:last-child) {
                        margin-bottom: 18rem;
                      }
                    }
                    &-text {
                      min-height: unset;
                      font-size: 16rem;
                      font-weight: 400;
                      line-height: 1.5;
                    }
                  }
                }
              }
            }
            &[aria-for='section-5'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                & > .text_wrap {
                  .main {
                    &-title {
                      font-size: 24rem;
                      font-weight: 800;
                      line-height: 1.42;
                      &:not(:last-child) {
                        margin-bottom: 36rem;
                      }
                    }
                    &-text {
                      font-size: 16rem;
                      font-weight: 400;
                      line-height: 1.5;
                    }
                  }
                }
              }
            }
          }
          .for_pc {
            display: none !important;
          }
          .for_mo {
            display: block !important;
          }
        }
      }
      .tab-contents {
        &__inner[aria-for*='blockchain_tab_'] {
          padding: 0;
          .for_pc {
            display: none !important;
          }
          .for_mo {
            display: block !important;
          }
          .flex {
            .wrap {
              &[aria-for='section-5'] {
                padding: 120rem 24rem 0;
                & > .block_wrap {
                  flex-direction: column;
                  .img_wrap {
                    width: 100%;
                    max-width: unset;
                    height: 213rem;
                  }
                  .text_wrap {
                  }
                }
              }
              &[aria-for='section-6'], &[aria-for='section-7'], &[aria-for='section-8'] {
                margin-top: 74rem !important;
                padding: 0 24rem 46rem;
                & > .block_wrap {
                  flex-direction: column;
                  width: 100%;
                  max-width: unset;
                  padding: 0;
                  .img_wrap {
                    width: 100%;
                    min-width: unset;
                    max-width: unset;
                    height: 213rem;
                    margin-left: unset !important;
                    margin-right: unset !important;
                  }
                  .text_wrap {
                    margin-top: 36rem;
                    .main {
                      &-subtitle {
                        &.fs-24 {
                          margin-top: 0;
                          font-size: 16rem;
                          font-weight: 600;
                          line-height: 1.5;
                        }
                      }
                      &-title {
                        &.fs-48 {
                          margin-top: 10rem;
                        }
                      }
                      &-text {
                        margin-top: 18rem;
                        word-break: break-all;
                      }
                    }
                    .btn {
                      &-sm {
                        height: 39rem;
                        max-height: 39rem;
                        margin-top: 30rem;
                        padding: 10rem 20rem;
                        font-size: 14rem;
                        font-weight: 600;
                        line-height: 1;
                      }
                    }
                  }
                }
              }
              &[aria-for='section-7'] {
                & > .block_wrap {
                  flex-direction: column-reverse;
                  .text_wrap {
                    & > .flex {
                      display: inline-block;
                      margin-top: 30rem;
                      .btn {
                        justify-content: center;
                        //min-width: 220rem;
                        margin-top: 0;
                        text-align: center;
                        &:not(:first-child) {
                          margin-top: 20rem !important;
                          margin-left: 0;
                        }
                      }
                    }
                  }
                }
              }
              &[aria-for='section-8'] {
                padding-bottom: 120rem;
              }
            }
          }
        }
      }
    }
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
}
