// MO SET(SMALL)
@media screen and (max-width: 340px) {
}

@media screen and (min-width: 1100px) and (max-width: 1239px) {
}

// MO SET
@media screen and (max-width: 1239px) {
  .scrollmagic-pin-spacer {
    &:not(:nth-child(2)) {
      .scrollinner.for_payment {
        inset: 60px auto auto !important;
      }
    }
  }
  .frame {
    &-container {
      &.for_payment, &.for_local {
        .frame-container-bg {
          &.section {
            &_1 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-payment-bg-mo);
                  }
                }
              }
            }
            &_2 {
              &.type {
                &_1 {
                  &:before {
                    background-image: var(--img-payment-local-bg-mo);
                  }
                }
              }
            }
          }
        }
        &.scrollinner {
          height: 100%;
          top: -60px;
          .flex {
            .wrap {
              width: calc(100% - 48rem);
              .text_wrap {
              }
              .img_wrap {
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-2'] {
            .text_wrap {
              .main-text {
                white-space: normal;
                &.fs-23 {
                  font-weight: 700;
                }
              }
            }
          }
        }
        .for_pc {
          display: none !important;
        }
        .for_mo {
          display: block !important;
        }
      }
    }
    &-contents {
      &__inner {
        &.for_payment {
          .wrap {
            &[aria-for='section-2'] {
              .text_wrap {
                .main-text {
                  &.fs-23 {
                    font-weight: 700;
                  }
                }
              }
            }
            &[aria-for='section-3'] {
              padding: 120rem 24rem 0;
              & > .block_wrap {
                .text_wrap {
                  .main {
                    &-subtitle {
                      margin-bottom: 15rem;
                      font-size: 16rem;
                      font-weight: 800;
                      line-height: 1;
                    }
                  }
                }
              }
              .accordion {
                &-wrap {
                  width: calc(100% + 48rem);
                  margin-top: 120rem;
                  margin-left: -24rem;
                }
                &-elem {
                  & > .flex {
                    max-width: unset;
                    width: 100%;
                    margin: 0;
                    .img_wrap {
                      .img {
                        width: 100%;
                        height: 100%;
                        max-height: 176rem;
                        border-radius: 12rem;
                        object-fit: cover;
                        object-position: left center;
                      }
                    }
                    .text_wrap {
                      margin-left: 10rem;
                    }
                  }
                }
              }
            }
            &[aria-for='section-4'] {
              padding: 120rem 24rem 0;
              & > .block_wrap {
                .text_wrap {
                  .main-text {
                    font-size: 16rem;
                    font-weight: 400;
                    line-height: 1.5;
                  }
                }
                .card {
                  &-wrap {
                    width: calc(100% + 48rem);
                    margin-top: 74rem;
                    margin-left: -24rem;
                    margin-bottom: 85rem;
                  }
                  &-title {
                    font-size: 22rem;
                    line-height: 1.36;
                  }
                }
                .swiper {
                  width: 100%;
                  padding: 0 24rem;
                  &-scrollbar {
                    width: calc(100% - 48rem);
                    margin: 0 -24rem;
                  }
                }
                & > .flex {
                  & > .flex.vert {
                    min-width: unset;
                  }
                }
              }
            }
          }
          .for_pc {
            display: none !important;
          }
          .for_mo {
            display: block !important;
          }
        }
        &.for_local {
          .img_wrap {
            .img {
              &.local {
                &-cont {
                  &-01 {
                    max-width: 100%;
                    height: 213rem;
                    max-height: 213rem;
                    object-fit: cover;
                    content: var(--img-local-01-mo);
                  }
                  &-02 {
                    max-width: 250rem;
                    max-height: 300rem;
                  }
                }
              }
            }
          }
          .wrap {
            &[aria-for='section-3'] {
              padding: 120rem 24rem;
            }
            &[aria-for='section-4'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                & > .flex {
                  flex-direction: column;
                  .flex.vert {
                    .block_box {
                      min-height: unset;
                      &:first-child {
                        margin-top: 48rem;
                      }
                      &:not(:first-child) {
                        margin-top: 32rem;
                      }
                      .text_wrap {
                        .main {
                          &-title {
                            font-size: 36rem;
                            font-weight: 800;
                            line-height: normal;
                            text-align: center;
                            &:not(:last-child) {
                              margin-bottom: 6rem;
                            }
                          }
                          &-text {
                            font-size: 16rem;
                            font-weight: 700;
                            line-height: 1.38;
                            text-align: center;
                            &.description {
                              font-size: 14rem;
                              font-weight: 400;
                              line-height: 1.57;
                              letter-spacing: -0.035px;
                              margin-top: 40rem;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-5'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: unset;
                max-width: unset;
                margin: 0;
                & > .text_wrap {
                  .main {
                    &-title {
                      font-size: 24rem;
                      font-weight: 800;
                      line-height: 1.42;
                    }
                  }
                  & + .flex {
                    margin-top: 74rem;
                  }
                }
                & > .flex {
                  flex-direction: column;
                  .block_box {
                    min-width: unset;
                    margin: 0;
                    &:not(:first-child) {
                      margin-top: 48rem;
                    }
                    .img_wrap {
                      .img {
                        max-width: 108rem;
                        max-height: 108rem;
                      }
                    }
                    .text_wrap {
                      margin-top: 20rem;
                      .main {
                        &-subtitle {
                          &.fs-28 {
                            font-size: 20rem;
                            font-weight: 700;
                            line-height: 1.5;
                            text-align: center;
                          }
                        }
                        &-text {
                          font-size: 14rem;
                          font-weight: 400;
                          line-height: 1.57;
                          text-align: center;
                          &:not(:first-child) {
                            margin-top: 16rem;
                          }
                        }
                      }
                    }
                  }
                }
                & + .block_wrap {
                  margin-top: 120rem;
                }
              }
            }
            &[aria-for='section-6'] {
              padding: 120rem 24rem;
              & > .block_wrap {
                width: 100%;
                max-width: unset;
                margin: 0;
                & > .flex {
                  &:first-child {
                    flex-direction: column;
                    .text_wrap {
                      text-align: center;
                      * {
                        text-align: center;
                      }
                    }
                    .block_box {
                      margin-top: 36rem;
                      margin-bottom: 74rem !important;
                      .text_wrap {
                        .main-title {
                          font-size: 100rem;
                          line-height: normal;
                          .f-clr-point {
                            transform: translate(0, -35%);
                          }
                        }
                      }
                    }
                  }
                  &:nth-child(2) {
                    width: calc(100% + 48rem);
                    margin-left: -24rem;
                    padding-left: 24rem;
                    padding-bottom: 74rem;
                    overflow-x: auto;
                    overflow-y: visible;
                    -ms-overflow-style: none;
                    &::-webkit-scrollbar {
                      display: none;
                    }
                    & > .block_box {
                      width: 272rem;
                      min-width: 272rem;
                      height: auto;
                      min-height: 360rem;
                      box-shadow: 0 12rem 30rem rgba(112,144,176,0.2);;
                      &:not(:first-child) {
                        margin-left: 20rem;
                      }
                      &:last-child {
                        margin-right: 24rem;
                      }
                      .img_wrap {
                        .img {
                          max-height: 109rem;
                        }
                      }
                      .flex.vert {
                        min-height: 154rem;
                        .title {
                          font-size: 22rem;
                          font-weight: 800;
                          line-height: 1.36;
                          text-align: left;
                          &:not(:last-child) {
                            margin-bottom: 12rem;
                          }
                        }
                        .text {
                          font-size: 14rem;
                          font-weight: 400;
                          line-height: 1.57;
                          text-align: left;
                        }
                      }
                    }
                  }
                }
                .card {
                  &-wrap {
                    width: calc(100% + 48rem);
                    margin-top: 0;
                    margin-left: -24rem;
                    .swiper {
                      &-slide {
                        width: 240rem;
                        max-width: 240rem;
                      }
                    }
                  }
                  &-elem {
                    width: 240rem;
                    height: auto;
                    min-height: 260px;
                    max-height: 260px;
                    padding: 20rem;
                    .img_wrap {
                      .img {
                        max-width: 104rem;
                        max-height: 104rem;
                      }
                    }
                    .text_wrap {
                      min-height: 88rem;
                      margin-top: 14rem;
                      .title {
                        font-size: 18rem;
                        font-weight: 800;
                        line-height: normal;
                        text-align: left;
                        &:not(:last-child) {
                          margin-bottom: 10rem;
                        }
                      }
                      .text {
                        font-size: 14rem;
                        font-weight: 400;
                        line-height: 1.57;
                        letter-spacing: -0.035px;
                        text-align: left;
                        word-break: break-word;
                      }
                    }
                  }
                }
              }
            }
          }
          .for_pc {
            display: none !important;
          }
          .for_mo {
            display: block !important;
          }
        }
      }
      .tab-contents {
        &__inner[aria-for*='payment_tab_'] {
          padding: 0;
          .for_pc {
            display: none !important;
          }
          .for_mo {
            display: block !important;
          }
          .flex {
            .wrap {
              &[aria-for='section-5'] {
                padding: 120rem 24rem 0;
                & > .block_wrap {
                  flex-direction: column;
                  .img_wrap {
                    width: 100%;
                    max-width: unset;
                    height: 213rem;
                  }
                  .text_wrap {
                  }
                }
              }
              &[aria-for='section-6'], &[aria-for='section-7'], &[aria-for='section-8'], &[aria-for='section-9'] {
                margin-top: 74rem !important;
                padding: 0 24rem 46rem;
                & > .block_wrap {
                  flex-direction: column;
                  width: 100%;
                  max-width: unset;
                  padding: 0;
                  .img_wrap {
                    width: 100%;
                    min-width: unset;
                    max-width: unset;
                    height: 436rem;
                    //margin-left: unset !important;
                    //margin-right: unset !important;
                  }
                  .text_wrap {
                    margin-top: 36rem;
                    .main {
                      &-subtitle {
                        &.fs-24 {
                          margin-top: 0;
                          font-size: 16rem;
                          font-weight: 600;
                          line-height: 1.5;
                        }
                      }
                      &-title {
                        &.fs-48 {
                          margin-top: 10rem;
                        }
                      }
                      &-text {
                        margin-top: 18rem;
                        word-break: break-all;
                      }
                    }
                    .btn {
                      &-sm {
                        height: 39rem;
                        max-height: 39rem;
                        margin-top: 30rem;
                        padding: 10rem 20rem;
                        font-size: 14rem;
                        font-weight: 600;
                        line-height: 1;
                      }
                    }
                  }
                }
              }
              &[aria-for='section-7'] {
                & > .block_wrap {
                  flex-direction: column-reverse;
                }
              }
              &[aria-for='section-8'] {
              }
              &[aria-for='section-9'] {
                overflow-x: hidden;
                & > .block_wrap {
                  flex-direction: column-reverse;
                  padding-bottom: 120rem;
                  & > .img_wrap {
                    margin-top: 0;
                    height: 400rem;
                    & > .img {
                      max-width: 192rem;
                      max-height: 400rem;
                    }
                    .swiper {
                      width: calc(100% + 48rem);
                      max-width: calc(100% + 48rem) !important;
                      margin-left: -24rem;
                      margin-right: -24rem;
                      //padding-top: 110rem;
                      padding-top: 0 !important;
                      top: 50%;
                      transform: translate(0, -50%);
                      overflow: visible;
                      &-slide {
                        width: 113rem;
                        &-active {
                          transform: translate(0, -30rem);
                          .img {
                            max-width: 127rem;
                            max-height: 200rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
}
