.header.fixed {
    & + .main-container {
        height: 100vh;
        .main-visual__slide-bg {
            top: 0;
        }
    }
}

.main {
    width: 100%;
    height: 100%;
    &-container {
        position: relative;
        height: calc(100vh - 80px);
        padding: 0;
        z-index: 5;
    }
    &-fullpage {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        &__inner {
            position: relative;
            background: #f5f5f5;
        }
    }
    &-visual {
        width: 100%;
        height: 100%;
        &__container {
            width: 100%;
            height: 100%;
        }
        &__slide {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            &-bg {
                position: absolute;
                width: 100%;
                height: 100vh;
                left: 0;
                right: 0;
                top: -80px;
                bottom: 0;
                background-image: var(--01_main_01_01);
                background-size: cover;
                background-position: left center;
                background-repeat: no-repeat;
                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    background-color: $--konai-black;
                    //background-image: var(--01_main_01_01);
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                video {
                    position: absolute;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 9;
                    object-fit: cover;
                    &.for_pc, &.for_tablet {
                        display: block;
                        //source {
                        //    content: '/uploads/main_01_e4bcabfa05.mp4'
                        //}
                    }
                    &.for_mo {
                        display: none;
                        //source {
                        //    content: ''
                        //}
                    }
                }
            }
            &-text{
                position: absolute;
                top: 38%;
                left: 50%;
                transform: translate(-50%, -38%);
                text-align: center;
                h5{
                    font-size: 24px;
                    font-weight: 200;
                    line-height: 34px;
                }
                p{
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 200;
                }
            }
        }
    }
    &-contents {
        &.hidden {
            opacity: 0 !important;
            z-index: -100 !important;
            img, div {
                position: relative !important;
                opacity: 0 !important;
                z-index: -100 !important;
            }
            .main-visual__slide-bg {
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 5;
            }
        }
        &__inner {
            position: relative;
            z-index: 4;
            &__Scrollinner{
                position: relative;
                z-index: 3;
            }
            &.scrollinner{
                position: relative;
                //height: 200vh;
                z-index: 5;
            }
        }
        & > section {
            &:not(.ht-auto) {
                display: block;
                // min-height: 100vh;
                & > div {
                    width: 100%;
                    // height: 100vh;
                    margin: 0 auto;
                }
            }
        }
    }
    &-contacts {
        display: flex;
        width: 100%;
        height: auto;
        padding: 40px;
        background-color: $--konai-lightgray-3;
        & > .flex {
            justify-content: space-around;
            align-items: center;
            max-width: 1200px;
            margin: 0 auto;
            //padding: 0 20px;
            & > .flex, & > .btn {
                display: flex;
                width: auto;
                margin: 0 auto;
            }
        }
        .flex-bar {
            display: block;
            width: 2px;
            min-width: 1px;
            height: 92px;
            margin: auto 0;
            background-color: $--konai-gray-6;
        }
        .img {
            width: 100%;
            max-width: 80px;
            height: 100%;
            max-height: 80px;
            &.company {
                content: var(--img-contact-1);
            }
            &.inquiry {
                content: var(--img-contact-2);
            }
        }
        .text_wrap {
            margin: auto 0;
            text-align: left;
            &:not(:first-child) {
                margin-left: 36px;
            }
            .title {
                font-size: 24px;
                font-weight: 700;
                line-height: normal;
                color: $--konai-gray-1;
            }
            .text {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.28;
                color: $--konai-gray-3;
                &:not(:first-child) {
                    margin-top: 10px;
                }
            }
        }
    }
}

.mxwd {
    &-1268 {
        // max-width: 1268px;
        max-width: calc(100vw * 0.660417);
    }
}

.text_wrap {
    text-align: center;
}

.img_wrap {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
    &.n1 {
        display: flex;
        justify-content: space-between;
    }
}

.sec {
    &-1 {
        height: auto;
        padding: 109px 0 180px;
        background-color: $--konai-white;
        // padding: calc(100vw * 0.056771) 0 calc(100vw * 0.09375);
        .for_pc {
            display: block;
        }
        .for_mo {
            display: none;
        }
        & > .wrap {
            // min-width: 1268px;
            min-width: min(calc(100vw * 0.66), 1440px);
            .img_wrap {
                text-align: left;
                // margin-left: calc(-1/66vw * (66vw - 66vw * 0.71));
            }
        }
        &__spacing {
            &-1 {
                // margin-top: 20px;
                margin-top: calc(100vh * 0.018519);
            }
            &-2 {
                // margin-top: 50px;
                margin-top: calc(100vh * 0.046296);
            }
            &-3 {
                // margin-top: 192px;
                margin-top: calc(100vh * 0.177778);
            }
        }
        &__img {
            position: absolute;
            display: inline-block;
            width: 374px;
            // width: calc(100vw * 0.1947917);
            height: 374px;
            // height: calc(100vw * 0.1947917);
            left: 0;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            &.n1 {
                position: relative;
                background-image: var(--img-sec1-1);
                transform: translate(0, 0) scale(1);
            }
            &.n2 {
                background-image: var(--img-sec1-2);
                transform: translate(0, 0) scale(1);
            }
            &.n3 {
                background-image: var(--img-sec1-3);
                transform: translate(0, 0) scale(1);
            }
            &.n4 {
                background-image: var(--img-sec1-4);
                transform: translate(0, 0) scale(1);
                // transform: translate(0, 0) scale(1.1);
            }
        }
        &.activeAni {
            .sec-1 {
                &__img {
                    &.n1 {
                        animation: sec-1__img-n1 0.5s ease-in-out forwards;
                    }
                    &.n2 {
                        animation: sec-1__img-n2 0.5s ease-in-out forwards;
                    }
                    &.n3 {
                        animation: sec-1__img-n3 0.5s ease-in-out forwards;
                    }
                    &.n4 {
                        animation: sec-1__img-n4 0.5s ease-in-out forwards;
                    }
                }
            }
        }
    }
    &-2 {
        padding: 357px 0;
        background-color: $--konai-lightgray-3;
        &__spacing {
            &-1 {
                // margin-top: 100px;
                margin-top: calc(100vh * 0.092593);
            }
        }
        .for_pc {
            display: block;
        }
        .for_mo {
            display: none;
        }
    }
    &-3 {
        .event {
            position: relative;
            overflow: hidden;
            height: 100vh;
            width: 100%;
            .scrollmagic-pin-spacer{
                position: absolute !important;
                clip: rect(auto, auto, auto, auto);
            }
            .pinWrapper {
                height: 100% !important;
                width: 100% !important;
            }
            &.event1 {
                background-color: $--konai-white;
                .image {
                    top: 50%;
                    background-image: var(--img-sec3-1);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    position: fixed;
                }
            }
            &.event2 {
                .image {
                    top: 50%;
                    background-image: var(--img-sec3-2);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    position: fixed;
                }
            }
            .image {
                top: -50%;
                left: 50%;
                background-size: contain;
                position: absolute;
                height: 100%;
                width: 100%;
                transform: translate(-50%, -50%);
            }
            .scale-animation{
                position: absolute;
                width: 100%;
                left: 50%;
                top: 10%;
                transform: translate(-50%, -10%);
                .main-subtitle {
                    width: 100%;
                    color: $--konai-black;
                }
            } 
            .scale-animation2{
                position: absolute;
                width: 100%;
                left: 50%;
                bottom: 5%;
                transform: translate(-50%, -5%);
                text-align: center;
                .main-text {
                    width: 100%;
                    color: $--konai-black;
                }
            }
            .scale-animation3{
                position: absolute;
                width: 100%;
                left: 50%;
                top: 10%;
                transform: translate(-50%, -10%);
                .main-subtitle {
                    width: 100%;
                    color: $--konai-white;
                }
            } 
            .scale-animation4{
                position: absolute;
                width: 100%;
                left: 50%;
                bottom: 5%;
                transform: translate(-50%, -5%);
                text-align: center;
                .main-text {
                    width: 100%;
                    color: $--konai-white;
                }
            }
        }
        .for_pc {
            display: block;
        }
        .for_mo {
            display: none;
        }
    }
    &-5 {
        padding: 190px 0 513px;
        background-color: $--konai-black;
        background-image: var(--img-sec5-1);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        * {
            color: $--konai-white;
        }
        & > .wrap {
            display: flex;
        }
        .table_wrap {
            width: 100%;
            margin: 0 auto;
            padding-top: 92px;
            //border-top: 1px solid $--konai-white;
            table {
                display: inline-table;
                border: 0;
                border-collapse: separate;
                width: 100%;
                min-width: 791px;
                height: auto;
                margin: 0;
                padding: 0;
                tr {
                    width: 100%;
                    &:not(:last-child) {
                        td {
                            padding-bottom: 130px;
                        }
                    }
                    td {
                        width: auto;
                        height: auto;
                        &:nth-child(2n-1) {
                            width: calc(100vw * 0.135417);
                            padding-right: min(calc(100vw * 0.0625), 120px);
                            text-align: left;
                            .title {
                                min-width: 284rem;
                            }
                        }
                        &:nth-child(2n) {
                            width: calc(100vw * 0.135417);
                            padding-left: min(calc(100vw * 0.0625), 120px);
                            text-align: left;
                            .title {
                                min-width: 284rem;
                            }
                        }
                        .title {
                            font-size: 66px;
                            font-weight: 800;
                            line-height: 1;
                            letter-spacing: normal;
                            color: $--konai-white;
                            word-break: keep-all;
                            &:not(:last-child) {
                                margin-bottom: 26px;
                            }
                        }
                        .subtitle {
                            font-size: 24px;
                            font-weight: 700;
                            line-height: normal;
                            letter-spacing: normal;
                            color: $--konai-red;
                            word-break: keep-all;
                            &:not(:last-child) {
                                margin-bottom: 22px;
                            }
                        }
                        .text {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 1.62;
                            letter-spacing: normal;
                            color: $--konai-gray-4;
                            word-break: keep-all;
                        }
                    }
                }
            }
        }
        .for_pc {
            display: block;
        }
        .for_mo {
            display: none;
        }
    }
    &-6, &-7 {
        flex-direction: column;
        position: absolute;
        height: 100vh;
        margin-top: -1px !important;
        background-color: $--konai-black;
        * {
            color: $--konai-white;
        }
        .wrap {
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            &[aria-for='sec-7'] {
                // padding-bottom: 50px;
                &::before {
                    content: '';
                    // justify-self: center;
                    // align-self: center;
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    margin: 0;
                    padding: 0;
                    background-color: $--konai-black;
                    background-image: var(--img-sec7-1);
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    z-index: 0;
                    opacity: 1;
                }
            }
            & > div {
                margin: auto;
            }
        }
        .main-title {
            font-family: 'Konai', sans-serif;
            font-weight: 400;
        }
        &__spacing {
            &-1 {
                // margin-top: 20px;
                margin-top: calc(100vh * 0.018519);
            }
            &-2 {
                // margin-top: 50px;
                margin-top: calc(100vh * 0.046296);
            }
            &-3 {
                // margin-top: 100px;
                margin-top: calc(100vh * 0.092593);
            }
        }
        .for_pc {
            display: block;
        }
        .for_mo {
            display: none;
        }
    }
    &-8 {
        &__spacing {
            &-1 {
                //margin-top: min(20rem, 0.18519vh);
                margin-top: 20rem;
            }
            &-2 {
                //margin-top: min(50rem, 0.46296vh);
                margin-top: 50rem;
            }
            &-3 {
                //margin-top: min(4rem, 0.03704vh);
                 margin-top: 4rem;
                line-height: 1.53;
            }
            &-4 {
                //margin-top: min(12rem, 0.11111vh);
                 margin-top: 8rem;
            }
        }
        &.growth_section {
            display: flex;
            justify-content: center;
            position: relative;
            height: 2300rem;
            .for_pc {
                display: block;
            }
            .for_mo {
                display: none;
            }
            &.is_active1 {
                .growth-bg-wrap .bg {
                    background-image: var(--img-sec8-1), var(--img-sec8-2);
                    transition: background-image 0.1s ease-in-out;
                }
            }
            &.is_active2 {
                //.section_head {
                //    .main {
                //        &-title {
                //            color: $--konai-white;
                //        }
                //        &-text {
                //            color: $--konai-white;
                //        }
                //    }
                //}
                .growth-bg-wrap .bg {
                    background-image: var(--img-sec8-2), var(--img-sec8-3);
                    transition: background-image 0.1s ease-in-out;
                }
            }
            &.is_active3 {
                .growth-bg-wrap .bg {
                    background-image: var(--img-sec8-3),  var(--img-sec8-4);
                    transition: background-image 0.1s ease-in-out;
                }
            }
            &.is_active4 {
                //.section_head {
                //    .main {
                //        &-subpoint {
                //            span {
                //                color: $--konai-white !important;
                //            }
                //        }
                //        &-title {
                //            color: $--konai-white;
                //        }
                //        &-text {
                //            color: $--konai-white;
                //        }
                //    }
                //}
                .growth-bg-wrap .bg {
                    background-image: var(--img-sec8-4), var(--img-sec8-3);
                    transition: background-image 0.1s ease-in-out;
                }
            }
            .section_wrap {
                position: sticky;
                z-index: 1;
                top: 0;
                display: flex;
                flex-wrap: wrap;
                max-width: 1440px;
                height: 100vh;
                margin-left: auto;
                margin-right: auto;
                padding-top: 166px;
            }
            .sp_container {
                height: 100%;
                .section_wrap {
                    max-width: 1200px;
                    .section_head {
                        width: auto;
                        margin-right: auto;
                    }
                    .section_body {
                        min-width: 718px;
                    }
                }
            }
            .section_head {
                display: flex;
                flex-direction: column;
                align-items: self-start;
                justify-content: start;
                width: 42%;
                // padding-right: calc(100vw * 0.015625);
                position: sticky;
                height: 700px;
                left: 10%;
                .main {
                    &-subpoint {
                        text-align: left;
                    }
                    &-title {
                        text-align: left;
                    }
                    &-text {
                        text-align: left;
                    }
                }
            }
            .section_body {
                will-change: contents;
                padding-top: 450px;
                width: 50%;
                .card {
                    .main {
                        &-subpoint {
                            font-size: 16rem;
                            text-align: left;
                        }
                        &-title {
                            font-size: 30rem;
                            text-align: left;
                        }
                        &-text {
                            font-size: 16rem;
                            text-align: left;
                        }
                    }
                    &:first-of-type {
                        align-self: flex-start;
                    }
                    &:nth-of-type(2) {
                        margin-top: 250px;
                        margin-left: 30px;
                    }
                    &:nth-of-type(3) {
                        align-self: flex-start;
                        margin-top: -220px;
                    }
                    &:nth-of-type(4) {
                        margin-top: 30px;
                        margin-left: 30px;
                    }
                }
            }
        }
        .growth-bg-wrap {
            width: 100%;
            height: 100%;
            position: relative;
            .bg-wrap {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                max-width: 100vw;
                height: 100%;
            }
            .bg {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                transition-duration: 0.3s;
                transition: 0.3s;
            }
            .bg {
                background-image: var(--img-sec8-5);
            }
        }
        .fixed_motion {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            transition-duration: 1.5s;
            .column {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &:first-child {
                    margin-bottom: 100px;
                    margin-right: 30px;
                    .card_box{
                        margin-bottom: 30px;
                    }
                }
                &:last-child {
                    margin-top: 100px;
                }
            }
            a {
                color: $--konai-black;
                &.is_active, &.is_active1, &.is_active2, &.is_active3, &.is_active4 {
                    .card_box {
                        background: $--konai-white;
                        border: 3px solid $--konai-red;
                        box-shadow: 0 20px 30px rgba(41,41,41,0.6);
                    }
                }
                .card_box {
                    display: flex;
                    max-width: 344px;
                    min-height: 460px;
                    // padding: 40px;
                    padding: min(2.0833vw, 40rem);
                    border-radius: 12px;
                    background: $--konai-lightgray-4;
                    border: 3px solid $--konai-trans;
                    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.10);
                    transition: all 0.3s ease-in-out;
                    .inner {
                        & > div {
                            height: 100%;
                            position: relative;
                            flex: 1;
                            .bottom_box {
                                position: absolute;
                                bottom: 0;
                                ul {
                                    li {
                                        & + li {
                                            //margin-top: 10rem;
                                            margin-top: min(0.5208vw, 10rem);
                                        }
                                        & > div {
                                            padding: 0px 13rem;
                                            border-radius: 100rem;
                                            display: inline-block;
                                            border: 1px solid $--konai-black;
                                            & + div {
                                                // margin-left: 10rem;
                                                margin-left: min(0.5208vw, 10rem);
                                            }
                                            &.color {
                                                background-color: $--konai-red;
                                                border: 1px solid $--konai-red;
                                                span {
                                                    color: $--konai-white;
                                                }
                                            }
                                            span {
                                                font-size: 16rem;
                                                font-weight: 400;
                                                line-height: 34rem;
                                                color: $--konai-black;
                                                text-transform: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-9 {
        margin: 0;
        padding: 180px 0;
        background-color: $--konai-white;
        .for_mo {
            display: none;
        }
        & > .wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            & > div {
                width: 100%;
                &.flex {
                    height: auto;
                    justify-content: space-between;
                    &:not(:first-child) {
                        margin-top: 118px;
                    }
                }
            }
            & > .btn {
                margin-left: auto;
                margin-right: auto;
            }
        }
        &__spacing {
            &-1 {
                // margin-top: 50px;
                margin-top: calc(100vh * 0.046296);
            }
            &-2 {
                // margin-top: 118px;
                margin-top: calc(100vh * 0.109259);
            }
            &-3 {
                // margin-top: 80px;
                margin-top: calc(100vh * 0.074074);
            }
            &-4 {
                // margin-top: 150px;
                margin-top: calc(100vh * 0.138889);
            }
        }
        .block_box, .swiper-slide {
            //width: 100%;
            width: 320px;
            max-width: 320px;
            background-size: 0;
            background-position: 50%;
            transition: all 0.2s ease-in-out;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: -1;
                background-color: $--konai-black;
                border-radius: 12px;
                transition: opacity 0.2s ease-in-out;
            }
            &:not(:hover) {
                background-image: unset !important;
                transition: all 0.3s ease-in-out;
            }
            &:hover {
                position: relative;
                background-color: $--konai-black;
                z-index: 2;
                background-size: cover;
                background-position: calc(50% - 20px) 50%;
                background-repeat: no-repeat;
                transition: all 0.3s ease-in-out;
                overflow: hidden;
                .title, .text, .date {
                    color: $--konai-white;
                }
                &::before {
                    opacity: 0.5;
                }
            }
            .flex {
                height: auto;
                justify-content: space-between;
                &:not(:first-child) {
                    margin-top: 26px;
                }
            }
            &.black {
                &:before {
                    background-color: $--konai-gray-1;
                }
            }
            &.lightgray {
                .btn-shortcut {
                    background-image: var(--icon-shortcut-g);
                }
            }
            .tag {
                font-size: 14rem;
                font-weight: 400;
                line-height: 1;
                color: $--konai-black;
                text-align: center;
            }
            .title {
                font-size: 24rem;
                font-weight: 800;
                line-height: 1.42;
                text-align: left;
            }
            .text {
                font-size: 16rem;
                font-weight: 300;
                line-height: 1.5;
                text-align: left;
            }
            .date {
                font-size: 18rem;
                font-weight: 400;
                line-height: 1.67;
                text-align: left;
            }
        }
        .swiper {
            &-container {
                position: relative;
                display: inline-block;
                width: 100%;
                padding: 0 85rem;
            }
            &-button {
                &-prev {
                    display: inline-flex;
                    width: 40rem;
                    height: 40rem;
                    left: 0;
                    background-image: var(--swiper-arr-l-g);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    &:after {
                        display: none;
                    }
                }
                &-next {
                    display: inline-flex;
                    width: 40rem;
                    height: 40rem;
                    right: 0;
                    background-image: var(--swiper-arr-r-g);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    &:after {
                        display: none;
                    }
                }
            }
        }
        .title {
            display: -webkit-box;
            word-wrap: break-word;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            width: 100%;
            height: 142px;
            font-size: 26px;
            font-weight: 800;
            line-height: 1.38;
            letter-spacing: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: keep-all;
            &:not(:first-child) {
                margin-top: 20px;
            }
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        .text {
            display: -webkit-box;
            word-wrap: break-word;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            width: 100%;
            height: 104px;
            font-size: 16px;
            font-weight: 300;
            line-height: 1.62;
            letter-spacing: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: keep-all;
            //숨김처리
            opacity: 0;
        }
        .date {
            display: flex;
            align-items: flex-end;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.33;
            letter-spacing: normal;
            text-transform: capitalize;
        }
        .ir_wrap {
            padding: 44px 100px;
            border-top: 1px solid $--konai-red;
            border-bottom: 1px solid $--konai-red;
            .block_wrap {
                width: auto;
            }
            .title {
                height: unset;
                margin: 0;
                font-family: 'Konai', sans-serif;
                font-size: 40px;
                font-weight: 400;
                line-height: 64px;
                letter-spacing: normal;
                color: $--konai-black;
                &.ir {
                    display: inline-block;
                    margin: 0;
                    padding-left: 48px;
                    font-family: 'Pretendard', sans-serif;
                    font-size: 38px;
                    font-weight: 600;
                    line-height: 64px;
                    letter-spacing: normal;
                    &.f-clr-increase {
                        color: $--konai-increase;
                        &::before {
                            content: '';
                            display: inline-block;
                            position: absolute;
                            width: 38px;
                            height: 38px;
                            border-top: calc(19px * 1.732) solid transparent;
                            border-left: 19px solid transparent;
                            border-right: 19px solid transparent;
                            border-bottom: calc(19px * 1.732) solid transparent;
                            border-bottom-color: $--konai-increase;
                            transform: translate(calc(-100% - 10px), -15px);
                        }
                    }
                    &.f-clr-decrease {
                        color: $--konai-decrease;
                        &::before {
                            content: '';
                            display: inline-block;
                            position: absolute;
                            width: 38px;
                            height: 38px;
                            border-top: calc(19px * 1.732) solid transparent;
                            border-top-color: $--konai-decrease;
                            border-left: 19px solid transparent;
                            border-right: 19px solid transparent;
                            border-bottom: calc(19px * 1.732) solid transparent;
                            transform: translate(calc(-100% - 10px), 15px);
                        }
                    }
                }
            }
            .flex {
                &.vert {
                    width: auto;
                    min-width: 80px;
                    &:not(:first-child) {
                        margin-left: 49px;
                    }
                }
                &.end {
                    justify-content: flex-end;
                }
            }
            .dt {
                font-family: 'Pretendard', sans-serif;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: normal;
                color: $--konai-gray-3;
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
            .dd {
                display: inline-block;
                margin: 0;
                font-family: 'Pretendard', sans-serif;
                font-size: 22px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: normal;
                &.f-clr-increase {
                    color: $--konai-increase;
                }
                &.f-clr-decrease {
                    color: $--konai-decrease;
                }
                &.ir {
                    padding-left: 19px;
                    &.f-clr-increase {
                        &::before {
                            content: '';
                            display: inline-block;
                            position: absolute;
                            width: 16px;
                            height: 16px;
                            border-top: calc(8px * 1.732) solid transparent;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            border-bottom: calc(8px * 1.732) solid transparent;
                            border-bottom-color: $--konai-increase;
                            transform: translate(calc(-100% - 3px), -4px);
                        }
                    }
                    &.f-clr-decrease {
                        &::before {
                            content: '';
                            display: inline-block;
                            position: absolute;
                            width: 16px;
                            height: 16px;
                            border-top: calc(8px * 1.732) solid transparent;
                            border-top-color: $--konai-decrease;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            border-bottom: calc(8px * 1.732) solid transparent;
                            transform: translate(calc(-100% - 3px), 8px);
                        }
                    }
                }
            }
        }
    }
    &-10 {
        padding: 180px 0;
        background-color: $--konai-red;
        .for_pc {
            display: block;
        }
        .for_mo {
            display: none;
        }
        & > .wrap {
            max-width: 1200px;
        }
        .main-title, .main-text {
            color: $--konai-white;
        }
        &__spacing {
            &-1 {
                // margin-top: 50px;
                margin-top: calc(100vh * 0.046296);
            }
            &-2 {
                // margin-top: 100px;
                margin-top: calc(100vh * 0.092593);
            }
            &-3 {
                // margin-top: 150px;
                margin-top: calc(100vh * 0.138889);
                min-width: 1200px;
                justify-content: space-between;
            }
        }
        .block_box {
            position: relative;
            display: flex;
            width: 100%;
            width: 572px;
            padding: 32px;
            text-align: left;
            .subtext {
                font-family: 'Konai', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: normal;
                color: $--konai-red;
                &:not(:last-child) {
                    margin-bottom: 19px;
                }
            }
            .subtitle {
                font-size: 24px;
                font-weight: 700;
                line-height: 1.5;
                letter-spacing: normal;
                &:not(:last-child) {
                    margin-bottom: 43px;
                }
            }
            .btn-arrow {
                color: $--konai-red;
                span {
                    color: $--konai-red;
                }
            }
            .img_wrap {
                display: flex;
                width: auto;
                .img {
                    width: auto;
                    height: 100%;
                    margin-top: auto;
                    &.konadaum {
                        &-1 {
                            height: 130px;
                            content: var(--img-sec10-1);
                        }
                        &-2 {
                            height: 118px;
                            content: var(--img-sec10-2);
                        }
                    }
                }
            }
            &.dimgray {
                background-color: $--konai-gray-0;
            }
        }
    }
    &-11 {
        padding: 180px 0;
        &__spacing {
            &-1 {
                // margin-top: 20px;
                margin-top: calc(100vh * 0.018519);
            }
            &-2 {
                // margin-top: 118px;
                margin-top: calc(100vh * 0.109259);
            }
        }
        .partner_wrap {
            margin-top: 118px;
            border-top: 1px solid $--konai-gray-4;
            border-bottom: 1px solid $--konai-gray-4;
            .flex {
                max-width: 1200px;
                margin: 0 auto;
                flex-wrap: wrap;
                li {
                    padding: 40px 0 20px;
                    margin-right: auto;
                    margin-bottom: 30px;
                }
            }
            .partner_img {
                width: 100%;
                max-width: 180px;
                height: 100%;
                max-height: 110px;
                &.visa {
                    content: var(--img-sec11-1);
                }
                &.master {
                    content: var(--img-sec11-2);
                }
                &.american {
                    content: var(--img-sec11-3);
                }
                &.bccard {
                    content: var(--img-sec11-4);
                }
                &.kbcard {
                    content: var(--img-sec11-5);
                }
                &.shinhancard {
                    content: var(--img-sec11-6);
                }
                &.hanacard {
                    content: var(--img-sec11-7);
                }
                &.samsungcard {
                    content: var(--img-sec11-8);
                }
                &.lottecard {
                    content: var(--img-sec11-9);
                }
                &.wooricard {
                    content: var(--img-sec11-10);
                }
                &.kakaobank {
                    content: var(--img-sec11-11);
                }
                &.toss {
                    content: var(--img-sec11-12);
                }
                &.tmoney {
                    content: var(--img-sec11-13);
                }
                &.samsung {
                    content: var(--img-sec11-14);
                }
                &.lgelectronics {
                    content: var(--img-sec11-15);
                }
                &.lguplus {
                    content: var(--img-sec11-16);
                }
                &.welcom {
                    content: var(--img-sec11-17);
                }
                &.iqoocca {
                    content: var(--img-sec11-18);
                }
                &.modubiz {
                    content: var(--img-sec11-19);
                }
                &.royalcanin {
                    content: var(--img-sec11-20);
                }
                &.cleanlab {
                    content: var(--img-sec11-21);
                }
                &.travelwallet {
                    content: var(--img-sec11-22);
                }
                &.nvh {
                    content: var(--img-sec11-23);
                }
                &.webcash {
                    content: var(--img-sec11-24);
                }
                &.creativeparty {
                    content: var(--img-sec11-25);
                }
                &.contentsblue {
                    content: var(--img-sec11-26);
                }
                &.tmnumbers {
                    content: var(--img-sec11-27);
                }
                &.smartech {
                    content: var(--img-sec11-28);
                }
                &.gyeonggi {
                    content: var(--img-sec11-29);
                }
                &.incheon {
                    content: var(--img-sec11-30);
                }
                &.ulsan {
                    content: var(--img-sec11-31);
                }
                &.gangreung {
                    content: var(--img-sec11-32);
                }
                &.cheonan {
                    content: var(--img-sec11-33);
                }
                &.cheongju {
                    content: var(--img-sec11-34);
                }
                &.bithumb {
                    content: var(--img-sec11-35);
                }
                &.mlno {
                    content: var(--img-sec11-36);
                }
            }
        }
    }
}

// 애니메이션
// SECTION-1 img-1
@keyframes sec-1__img-n1 {
    0% {
        transform: translate(0, 0) scale(1);
    }
    to {
        transform: translate(0, 0) scale(1);
    }
}
// SECTION-1 img-2
@keyframes sec-1__img-n2 {
    0% {
        transform: translate(0, 0) scale(1);
        left: 0;
    }
    to {
        transform: translate(0, 0) scale(1);
        left: 28%;
    }
}
// SECTION-1 img-3
@keyframes sec-1__img-n3 {
    0% {
        transform: translate(0, 0) scale(1);
        left: 0;
    }
    to {
        transform: translate(0, 0) scale(1);
        left: 48%;
    }
}
// SECTION-1 img-4
@keyframes sec-1__img-n4 {
    0% {
        transform: translate(0, 0) scale(1.1);
        left: 0;
    }
    to {
        transform: translate(0, 0) scale(1);
        left: 71%;
    }
}
// SECTION-8
@keyframes slideInFromLeft {
    0% {
        transform: translate3d(100%, 0, 0);
        opacity: 0;
    }
    5% {
        transform: translate3d(0, 0, 0);
        opacity: 0;
    }
    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

// 미디어쿼리 @media
@import './pages/main_media';