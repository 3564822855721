// @use '../base/variables';

html {
	font-size: 1px;
	&.hidden {
		overflow: hidden !important;
		body {
			overflow: hidden !important;
		}
	}
}
body {
	#header{
		* {
			font-family: 'Konai', 'Pretendard', -serif;
		}
    }
	.header {
		position: sticky;
		max-height: 80px;
		top: 0;
		z-index: 9;
		transition: all 1s ease-in-out;
		&.hidden {
			top: -80px;
			//transition: all 1s ease-in-out;
		}
		&.fixed {
			position: fixed;
			width: 100%;
			//&:before {
			//	position: fixed;
			//	display: block;
			//	content: '';
			//	width: 100%;
			//	height: 100vh;
			//	top: 0;
			//	left: 0;
			//	background-color: $--konai-black;
			//	z-index: -999;
			//	opacity: 0;
			//	transition: opacity 0.1s ease-in-out;
			//	pointer-events: none;
			//}
			&.clr-bg {
				&.onmouseOver {
					&::before {
						pointer-events: auto;
						z-index: -1;
						opacity: 0.67;
						transition: opacity 0.3s ease-in-out;
					}
				}
			}
		}
		&__inner {
			position: relative;
			display: inline-flex;
			width: 100%;
			min-height: 80px;
			height: 100%;
			max-height: 80px;
			// padding: 0 200px;
			padding: 0 calc(100vw * 0.104167);
			& > * {
				margin: auto 0;
			}
			&-bg {
				position: fixed;
				width: 100%;
				height: 100%;
				max-height: 80px;
				top: 0;
				left: 0;
				z-index: -1;
				opacity: 0;
				background-color: $--konai-white;
				transition: all 0.1s 0.1s ease-in-out;
				pointer-events: none;
				&::after {
					position: absolute;
					display: inline-block;
					content: '';
					width: 100%;
					height: 1px;
					top: 80px;
					background-color: $--konai-gray-4;
					opacity: 0;
					transition: opacity 0.1s linear;
					pointer-events: none;
				}
				&.active {
					max-height: 380px;
					box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
					opacity: 1;
					transition: all 0.3s ease-in-out;
					&::after {
						opacity: 1;
						transition: opacity 0.3s linear;
					}
				}
			}
			.logo {
				&-main {
					display: block;
					width: 100%;
					max-width: 120px;
					min-height: 25px;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-image: var(--logo-konai);
					&.white {
						background-image: var(--logo-konai-w);
					}
					&.black {
						background-image: var(--logo-konai-b);
					}
				}
			}
			.menu {
				display: flex;
				align-self: center;
				width: auto;
				//height: 180px;
				height: 80px;
				margin: 0 auto;
				z-index: 5;
				&-container {
					display: flex;
					width: auto;
					height: auto;
				}
				&-item {
					display: flex;
					width: auto;
					height: auto;
					& > a {
						display: flex;
						align-items: center;
						height: 100%;
						// padding: 0 35px;
						padding: 0 calc(100vw * 0.018229);
						font-size: 18rem;
						color: $--konai-black;
						cursor: default;
						& > span {
							pointer-events: none;
							&::after {
								content: '';
								display: block;
								position: relative;
								width: 100%;
								max-width: 0;
								height: 2px;
								margin-top: -2px;
								transform: translate(0, 31px);
								background-color: $--konai-red;
								opacity: 0;
								transition: opacity 0.2s ease-in-out;
							}
						}
					}
					&:first-child {
						margin-left: 0;
					}
					&:last-child {
						margin-right: 0;
					}
					&.active {
						& > a {
							& > span {
								&::after {
									max-width: 100%;
									opacity: 1;
									transition: opacity 0.2s ease-in-out;
								}
							}
						}
					}
				}
			}
			.menu_mo {
				display: block;
				position: fixed;
				width: 100%;
				height: 100%;
				margin: 0;
				padding-top: 60px;
				top: 0;
				left: 0;
				background-color: $--konai-white;
				z-index: -99;
				pointer-events: none;
				opacity: 0;
				transition: opacity 0.3s ease-in-out;
				li, .for_header {
					pointer-events: none !important;
				}
				a {
					pointer-events: none !important;
					cursor: default !important;
				}
				&.open {
					opacity: 1;
					z-index: -1;
					pointer-events: auto;
					transition: opacity 0.3s ease-in-out;
					li, .for_header {
						pointer-events: auto !important;
					}
					a {
						pointer-events: auto !important;
						cursor: pointer !important;
					}
				}
				.menu {
					&-container {
						position: relative;
						display: block;
						//flex-direction: column;
						//flex-wrap: nowrap;
						max-height: 100%;
						padding: 20px 24px;
						overflow-y: auto;
					}
					&-item {
						margin: 0;
						padding: 0;
						flex-direction: column;
						& > a {
							display: flex;
							align-items: center;
							width: 100%;
							padding: 19px 0;
							cursor: pointer;
							&::after {
								content: '';
								display: inline-block;
								width: 16px;
								height: 9px;
								margin-left: auto;
								background-image: var(--icon-arr-dn-b);
								background-size: contain;
								background-position: center;
								background-repeat: no-repeat;
							}
							&.active {
								color: $--konai-red;
								&::after {
									background-image: var(--icon-arr-up-r);
								}
								& + .sub-menu {
									position: relative;
									display: block;
									width: calc(100% + 48px);
									max-width: unset;
									height: auto;
									max-height: 1000px;
									margin-left: -24px;
									padding: 20px 24px 40px;
									opacity: 1;
									transition: max-height 0.3s ease-in-out;
									background-color: $--konai-lightgray-3;
									z-index: 5;
									& > .sub-item {
										margin: 0;
										padding: 0;
										&:not(:first-child) {
											margin-top: 30px;
										}
										& > a {
											display: block;
											width: 100%;
											margin: 0;
											font-size: 18rem;
											font-weight: 800;
											line-height: 1.44;
											cursor: pointer;
											pointer-events: auto;
											& + .child-menu {
												padding-left: 24px;
											}
										}
										&:first-child:last-child {
											.child-menu {
												&:first-child {
													flex-direction: column;
												}
												.child-item {
													&:first-child{
														& > a {
															padding: 0 0 10px;
														}
													}
													&:last-child{
														& > a {
															padding: 10px 0 0;
														}
													}
													& > a {
														font-weight: 400;
														padding: 10px 0;
													}
												}
											}
										}
										.child-menu {
											.child-item {
												& > a {
													display: block;
													width: 100%;
													margin: 0;
													font-size: 18rem;
													font-weight: 400;
													line-height: 1.44;
													cursor: pointer;
													pointer-events: auto;
													transition: unset !important;
												}
											}
										}
									}
								}
							}
						}
						.sub-menu {
							max-height: 0;
							opacity: 0;
							transition: max-height 0.3s ease-in-out;
							.sub-item {
								& > a {
									transition: unset !important;
								}
							}
						}
					}
				}
			}
			.sub-menu {
				display: flex;
				position: absolute;
				width: 100%;
				height: 0;
				top: 0;
				left: 0;
				//padding: 0 260px 0 320px;
				opacity: 0;
				transition: opacity 0.1s 0.2s ease-in-out;
				z-index: -99;
				pointer-events: none;
				overflow: hidden;
				//& > *:first-child {
				//	margin-left: auto;
				//}
				//& > *:last-child {
				//	margin-right: auto;
				//}
				&.active {
					height: 300px;
					transform: translate(0, 80px);
					opacity: 1;
					transition: opacity 0.2s 0.1s ease-in-out;
					z-index: 3;
					pointer-events: auto;
				}
				.sub-item {
					margin-top: 38px;
					&:not(:first-child) {
						margin-left: 50px;
					}
					& > a {
						font-family: $--font-family-Pretendard !important;
						font-size: 16rem;
						font-weight: 900;
						color: $--konai-black;
						//margin: 0 35px;
						margin: 0;
						transition: color 0.2s ease-in-out;
						& > span {
							font-family: $--font-family-Pretendard !important;
							pointer-events: none;
						}
						&:hover {
							color: $--konai-red;
						}
					}
					&:first-child:last-child {
						.child-menu {
							display: flex;
							//flex-direction: row;
							flex-direction: column;
							.child-item {
								//margin-top: 0;
								&:first-child {
									margin-top: 0;
								}
								& > a {
									font-weight: 500;
								}
							}
						}
					}
				}
			}
			.child-menu {
				position: relative;
				display: flex;
				flex-direction: column;
				width: auto;
				height: auto;
				top: unset;
				left: unset;
				opacity: 1;
				transition: unset;
				z-index: 0;
				pointer-events: auto;
				.child-item {
					margin-top: 22px;
					white-space: nowrap;
					& > a {
						font-family: $--font-family-Pretendard !important;
						font-size: 15rem;
						font-weight: 500;
						color: $--konai-black;
						//margin: 0 35px;
						margin: 0;
						transition: color 0.2s ease-in-out;
						& > span {
							font-family: $--font-family-Pretendard !important;
							pointer-events: none;
						}
						&:hover {
							color: $--konai-red;
						}
					}
				}
			}
			.sub_link {
				&-container {
					display: flex;
				}
			}
			.lang-menu {
				.lang-link {
					display: none;
					justify-content: space-between;
					align-items: center;
					width: 62px;
					color: $--konai-white;
					font-size: 16rem;
					&::before {
						content: '';
						display: inline-block;
						width: 25px;
						min-width: 25px;
						height: 25px;
						min-height: 25px;
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						background-image: var(--lang-globe-b);
					}
					&.selected {
						display: flex;
					}
				}
			}
			.hamburger-menu {
				display: none;
				&:not(:first-child) {
					margin-left: 20px;
				}
				.hamburger {
					width: 22px;
					height: 17px;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-image: var(--btn-hamburger-w);
				}
			}
			.with_shortcut {
				display: flex;
				align-items: center;
				&:after {
					content: '';
					display: inline-block;
					width: 11px;
					height: 11px;
					margin-left: 6px;
					background-image: var(--icon-shortcut-l);
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
				}
			}
		}
		&.clr {
			&-w{
				transition: background-color 0.2s ease-in-out;
				.menu {
					&-item {
						& > a {
							color: $--konai-white;
						}
					}
				}
				.lang-menu {
					.lang-link {
						&::before {
							background-image: var(--lang-globe-w);
						}
					}
				}
				.hamburger-menu {
					.hamburger {
						background-image: var(--btn-hamburger-w);
					}
				}
			}
			&-b{
				transition: background-color 0.2s ease-in-out;
				.menu {
					&-item {
						& > a {
							color: $--konai-black;
						}
					}
				}
				.lang-menu {
					.lang-link {
						color: $--konai-black;
						&::before {
							background-image: var(--lang-globe-b);
						}
					}
				}
				.hamburger-menu {
					.hamburger {
						background-image: var(--btn-hamburger-b);
					}
				}
			}
			&-bg {
				transition: background-color 0.2s ease-in-out, top 0.4s linear;
				background-color: $--konai-white;
				border-bottom: 1px solid $--konai-gray-4;
				.menu {
					&-item {
						& > a {
							color: $--konai-black;
						}
					}
				}
				.lang-menu {
					.lang-link {
						color: $--konai-black;
						&::before {
							background-image: var(--lang-globe-b);
						}
					}
				}
				.hamburger-menu {
					.hamburger {
						background-image: var(--btn-hamburger-b);
					}
				}
			}
			&-bg-fixed {
				transition: background-color 0.2s ease-in-out, top 0.4s linear;
				background-color: $--konai-white !important;
				.menu {
					&-item {
						& > a {
							color: $--konai-black !important;
						}
					}
				}
				.lang-menu {
					.lang-link {
						color: $--konai-black !important;
						&::before {
							background-image: var(--lang-globe-b) !important;
						}
					}
				}
				.hamburger-menu {
					.hamburger {
						background-image: var(--btn-hamburger-b) !important;
					}
				}
			}
		}
		&.only-black {
			.menu {
				&-item {
					& > a {
						color: $--konai-black !important;
					}
				}
			}
			.lang-menu {
				.lang-link {
					color: $--konai-black !important;
					&::before {
						background-image: var(--lang-globe-b) !important;
					}
				}
			}
			.hamburger-menu {
				.hamburger {
					background-image: var(--btn-hamburger-b) !important;
				}
			}
		}
	}
}

// PC SET
@media screen and (min-width: 1280px) {
	body {
		.header {
			.menu_mo, .hamburger-menu {
				display: none !important;
			}
		}
	}
}
@media screen and (min-width: 1240px) and (max-width: 1279px) {
	body {
		.header {
			&__inner {
				padding: 0 130px !important;
			}
			.menu-item > a {
				padding: 0 25px !important;
			}
			.menu_mo, .hamburger-menu {
				display: none !important;
			}
		}
	}
}

// MO SET
@media screen and (max-width: 1239px) {
	body {
		.header {
			max-width: 100vw !important;
			max-height: 60px !important;
			&.hidden {
				top: -60px;
			}
			&__inner {
				width: 100%;
				min-height: 60px !important;
				max-height: 60px !important;
				padding: 0 24rem !important;
				&-bg {
					max-height: 60px !important;
					&::after {
						top: 80px !important;
					}
				}
				.logo-main {
					background-position: left !important;
					min-height: 17rem !important;
				}
				.sub_link {
					&-container{
						align-items: center;
						margin-left: auto;
						.lang-link {
							width: auto !important;
							&::before {
								width: 25rem !important;
								min-width: 25rem !important;
								height: 25rem !important;
								min-height: 25rem !important;
							}
							& > span {
								display: none;
							}
						}
						.hamburger-menu {
							display: block !important;
							.hamburger {
								width: 22rem !important;
								height: 17rem !important;
							}
						}
					}
				}
				.menu {
					display: none !important;
				}
				.menu_mo {
					padding-top: 60rem !important;
					&.open {
						.menu-item {
							& > a:not(.active) {
								color: $--konai-black !important;
							}
							.sub-item {
								&:first-child:last-child {
									padding-left: 24px;
									.child-menu {
										.child-item {
											&:not(:first-child) {
												margin: 0;
											}
										}
									}
								}
							}
						}
						& + .sub_link-container {
							.lang-menu .lang-link::before {
								background-image: var(--lang-globe-b) !important;
							}
							.hamburger-menu .hamburger {
								background-image: var(--btn-hamburger-b) !important;
							}
						}
					}
				}
			}
		}
	}
}