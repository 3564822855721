// DIV FRAME
.flex {
    display: flex;
    width: 100%;
    height: 100%;
    &.vert {
        flex-direction: column;
    }
    &.center {
        justify-content: center;
        align-items: center;
    }
    &.spaceBtw {
        justify-content: space-between;
        align-items: center;
    }
    .wrap {
        display: block;
        width: auto;
        height: auto;
        margin: auto;
    }
}

// TEXT ALIGN
.txt-align {
    &.left {
        text-align: left !important;
        .flex {
            span {
                text-align: left;
                //margin-right: auto;
            }
        }
    }
    &.center {
        text-align: center !important;
        .flex {
            span {
                text-align: center;
                //margin: 0 auto;
            }
        }
    }
    &.right {
        text-align: right !important;
        .flex {
            span {
                text-align: right;
                //margin-left: auto;
            }
        }
    }
    &.left, &.center, &.right {
        .flex {
            span {
                width: 100%;
                margin: 0 auto;
            }
        }
        &.wd85 {
            .flex {
                span {
                    max-width: 100px;
                    white-space: normal;
                }
            }
        }
        &.wd110 {
            .flex {
                span {
                    max-width: 110px;
                    white-space: normal;
                }
            }
        }
        &.wd160 {
            .flex {
                span {
                    max-width: 160px;
                    white-space: normal;
                }
            }
        }
    }
}

// EN
html {
    &[lang="en"] {
        .main-visual__slide-bg {
            background-image: var(--01_main_01_01-en);
        }
        .main-contents__inner__Scrollinner {
            .sec-8 {
                .fixed_motion {
                    a {
                        &:nth-child(2) {
                            .card_box {
                                .inner {
                                    & > div {
                                        .bottom_box {
                                            ul {
                                                li + li {
                                                    & > div {
                                                        &:not(:last-child) {
                                                            margin-right: min(0.5208vw, 10rem);
                                                        }
                                                        &:not(:first-child) {
                                                            margin-top: min(0.5208vw, 10rem);
                                                            margin-left: 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .frame-container {
            &.for_universe {
                &.scrollinner {
                    .flex {
                        .wrap {
                            .img_wrap {
                                .img.universe.diagram {
                                    background-image: var(--img-universe-diagram-en);
                                }
                                .img.universe.rotate-plate {
                                    background-image: var(--img-universe-rotate-plate-en);
                                }
                            }
                        }
                    }
                }
                &#fixedScroll_1 {
                    .frame-container__inner {
                        .flex.vert {
                            .wrap {
                                & > .text_wrap {
                                    white-space: nowrap;
                                }
                            }
                        }
                        .frame-container-text {
                            white-space: normal;
                            word-break: break-word;
                        }
                    }
                }
            }
            &.ci_cont_04 {
                .wrap {
                    &.type_3 {
                        & > .text_wrap {
                            & > div {
                                svg {
                                    & > path {
                                        &:nth-child(12) {
                                            transform: translate(35px, 25px);
                                        }
                                        &:nth-child(13) {
                                            transform: translate(275px, 25px);
                                        }
                                        &:nth-child(14) {
                                            transform: translate(525px, 25px);
                                        }
                                        &:nth-child(15) {
                                            transform: translate(775px, 25px);
                                        }
                                        &:nth-child(16) {
                                            transform: translate(1040px, 25px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.ci_cont_05 {
                .wrap {
                    &.type_2 {
                        & > .text_wrap {
                            & > div {
                                svg {
                                    & > path {
                                        &:nth-child(7) {
                                            transform: translate(0, 0);
                                        }
                                        &:nth-child(8) {
                                            transform: translate(330px, 0);
                                        }
                                        &:nth-child(9) {
                                            transform: translate(705px, 0);
                                        }
                                        &:nth-child(10) {
                                            transform: translate(840px, 0);
                                        }
                                        &:nth-child(11) {
                                            transform: translate(1120px, 0);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .frame-contents {
            .tab-contents__inner {
                &[aria-for*='careers_tab_'] {
                    .wrap {
                        &[aria-for='section-7'] {
                            .flex {
                                .card-elem.konadaum {
                                    &.n-1 {
                                        background-image: var(--img-careers-konadaum-01-en);
                                    }
                                    &.n-2 {
                                        background-image: var(--img-careers-konadaum-02-en);
                                    }
                                    &.n-3 {
                                        background-image: var(--img-careers-konadaum-03-en);
                                    }
                                    &.n-4 {
                                        background-image: var(--img-careers-konadaum-04-en);
                                    }
                                    &.n-5 {
                                        background-image: var(--img-careers-konadaum-05-en);
                                    }
                                    &.n-6 {
                                        background-image: var(--img-careers-konadaum-06-en);
                                    }
                                    &.n-7 {
                                        background-image: var(--img-careers-konadaum-07-en);
                                    }
                                }
                            }
                        }
                    }
                }
                &[aria-for*='ir_tab_03'] {
                    .table-elem {
                        &.data {
                            tbody {
                                tr {
                                    td {
                                        &:nth-child(1) {
                                            &.wd160 {
                                                .flex {
                                                    span {
                                                        max-width: 200rem;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &__inner {
                &.for_chip {
                    .wrap {
                        &[aria-for='section-4'] {
                            & > .block_wrap {
                                & > .flex {
                                    & > .flex.vert {
                                        min-width: 252rem;
                                    }
                                    .wrap-text {
                                        min-height: 120px;
                                    }
                                }
                            }
                        }
                    }
                }
                &.for_local {
                    .wrap {
                        &[aria-for='section-6'] {
                            & > .block_wrap {
                                .card-elem {
                                    .text_wrap {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                &.for_did {
                    .wrap {
                        &[aria-for='section-3'] {
                            .accordion {
                                &-elem {
                                    &:nth-child(2) {
                                        &.active {
                                            & > .flex, & > .flex:hover {
                                                max-height: 1000rem;
                                            }
                                        }
                                        & > .flex {
                                            max-height: 176rem;
                                            &:hover {
                                                max-height: 1000rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.for_mobility {
                    .wrap {
                        &[aria-for='section-3'] {
                            .accordion {
                                &-elem {
                                    &:first-child {
                                        &.active {
                                            & > .flex, & > .flex:hover {
                                                max-height: 1000rem;
                                            }
                                        }
                                        & > .flex {
                                            max-height: 176rem;
                                            &:hover {
                                                max-height: 1000rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .table-title {
            &.absolute {
                & + .table-elem {
                    &:not(:first-child) {
                        &.data-6 {
                            tbody {
                                tr {
                                    td {
                                        &:nth-child(1), &:nth-child(3) {
                                            .flex {
                                                span {
                                                    max-width: 150rem;
                                                    white-space: nowrap;
                                                    text-align: center;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .footer {
            .footer {
                &__inner {
                    .ft_menu {
                        .menu_ul {
                            width: 700px;
                            & > .menu-item > a + .sub-menu {
                                //max-width: 157px;
                                max-width: 128px;
                                white-space: nowrap;
                                & > .sub-item:first-child {
                                    white-space: normal;
                                    & > a {
                                        line-height: 1.4;
                                        &[href='/product/did'] {
                                            line-height: 1.4;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .logo_text {
                        .comm_info {
                            & > p {
                                line-height: 1.62;
                            }
                        }
                    }
                }
            }
        }
    }
}

// MO SET
@media screen and (max-width: 1239px) {
    html {
        &[lang="en"] {
            .main-contents__inner__Scrollinner {
                .sec-8 {
                    .fixed_motion {
                        a {
                            .card_box {
                                padding: 24rem;
                            }
                        }
                    }
                }
            }
            .main-visual__slide-bg {
                background-image: var(--01_main_01_01-mo-en);
            }
            .main-contents {
                .sec-8 {
                    &.growth_section {
                        .sp_container {
                            .section_wrap {
                                .section_body {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
            .main-contacts {
                .text_wrap {
                    min-width: 170px;
                }
            }
            .frame-container {
                &.for_universe {
                    &#fixedScroll_1 {
                        .frame-container__inner {
                            .flex.vert {
                                .wrap {
                                    & > .text_wrap {
                                        white-space: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .frame-contents {
                &__inner {
                    &.for_chip {
                        .wrap {
                            &[aria-for='section-4'] {
                                & > .block_wrap {
                                    & > .flex {
                                        .wrap-text {
                                            min-height: unset;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.for_local {
                        .wrap {
                            &[aria-for='section-6'] {
                                & > .block_wrap {
                                    .card-elem {
                                        .text_wrap {
                                            margin-top: 0;
                                            .text {
                                                letter-spacing: -0.2px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.for_did {
                        .wrap {
                            &[aria-for='section-3'] {
                                .accordion {
                                    &-elem {
                                        &:nth-child(2) {
                                            &.active {
                                                & > .flex, & > .flex:hover {
                                                    max-height: 1200rem;
                                                }
                                            }
                                            & > .flex {
                                                max-height: 120rem;
                                                &:hover {
                                                    max-height: 1200rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.for_mobility {
                        .wrap {
                            &[aria-for='section-3'] {
                                .accordion {
                                    &-elem {
                                        &:first-child {
                                            &.active {
                                                & > .flex, & > .flex:hover {
                                                    max-height: 1200rem;
                                                }
                                            }
                                            & > .flex {
                                                max-height: 120rem;
                                                &:hover {
                                                    max-height: 1200rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// DID Accordion 대응
@media screen and (max-width: 404px) {
    html {
        &[lang="en"] {
            .frame {
                &-contents {
                    &__inner {
                        &.for_did {
                            .wrap {
                                &[aria-for='section-3'] {
                                    .accordion {
                                        &-elem {
                                            &:nth-child(2) {
                                                &.active {
                                                    & > .flex, & > .flex:hover {
                                                        max-height: 1200rem;
                                                    }
                                                }
                                                & > .flex {
                                                    max-height: 150rem;
                                                    &:hover {
                                                        max-height: 1200rem;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// 메인 사이즈 대응
@media screen and (min-width: 799px) and (max-width: 1239px) {
    html {
        &[lang="en"] {
            .main-contents {
                .sec-8 {
                    &.growth_section {
                        .sp_container {
                            .section_wrap {
                                .section_body {
                                    width: 75%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

