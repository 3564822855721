// 색상
$--konai-black: #000;
$--konai-black-trans: rgba(0,0,0,0);
$--konai-dimgray-1: #535353;
$--konai-gray-0: #252525;
$--konai-gray-1: #333;
$--konai-gray-2: #7a7a7a;
$--konai-gray-3: #828282;
$--konai-gray-4: #bfbfbf;
$--konai-gray-5: #cdcdcd;
$--konai-gray-6: #d9d9d9;
$--konai-lightgray-1: #ebebeb;
$--konai-lightgray-2: #f1f1f1;
$--konai-lightgray-3: #f7f7f7;
$--konai-lightgray-4: #f2f2f2;
$--konai-lightgray-5: #f8f8f8;
$--konai-white: #fff;
$--konai-red: #ff3c42;
$--konai-red-trans: rgba(255,60,66,0);
$--konai-increase: #ff3c42;
$--konai-decrease: #0047c2;
$--konai-trans: rgba(255,255,255,0);

// 폰트
$--font-family-konafont: "Konai", "Pretendard", sans-serif;
$--font-family-konai: "Konai", sans-serif;
$--font-family-Pretendard: "Pretendard", sans-serif;

// 여백
$--spacing-xs: 4px;
$--spacing-s: 8px;
$--spacing-xm: 10px;
$--spacing-sm: 12px;
$--spacing-m: 16px;
$--spacing-l: 24px;
$--spacing-xl: 32px;
$--padding_180:180rem;
$--padding_360:360rem;

:root{

  // swiper
  --swiper-scrollbar-sides-offset: 0px;
  --swiper-scrollbar-drag-bg-color: #828282;

  // 공통 아이콘/이미지

  --logo-konai: url("/image/logo/konai.svg");
  --logo-konai-w: url("/image/logo/konai_w.svg");
  --logo-konai-b: url("/image/logo/konai_b.svg");
  --logo-ifaward: url("/image/logo/if_award.png");

  --lang-globe-b: url("/image/icon/globe_b.svg");
  --lang-globe-w: url("/image/icon/globe_w.svg");

  --btn-hamburger-b: url("/image/icon/hamburger_b.svg");
  --btn-hamburger-w: url("/image/icon/hamburger_w.svg");

  --btn-search-g: url("/image/icon/search_g.svg");

  --btn-download: url("/image/icon/ic_download.svg");

  --btn-goto-top: url("/image/icon/btn_goto_top.png");

  //--btn-nav-arr-lt: url("/image/icon/navi_arr_left.svg");
  //--btn-nav-arr-rt: url("/image/icon/navi_arr_right.svg");

  --btn-page-arr-first: url("/image/icon/page_arr_left_first.svg");
  --btn-page-arr-lt: url("/image/icon/page_arr_left.svg");
  --btn-page-arr-rt: url("/image/icon/page_arr_right.svg");
  --btn-page-arr-last: url("/image/icon/page_arr_right_last.svg");

  --icon-alert: url("/image/icon/img_error_01.svg");

  --icon-chk-box: url("/image/icon/ic_checkbox.svg");
  --icon-chk-box-on: url("/image/icon/ic_checkbox_checked.svg");

  --icon-pop-chk-box: url("/image/icon/pop_check_box_white.svg");
  --icon-pop-chk-box-on: url("/image/icon/pop_check_box_white_checked.svg");

  --icon-arr-rt-r: url("/image/icon/arr_right_r.svg");
  --icon-arr-up-r: url("/image/icon/arr_up_r.svg");
  --icon-arr-dn-b: url("/image/icon/arr_down_b.svg");
  --icon-arr-dn-w: url("/image/icon/arr_down_w.svg");
  --icon-arr-up-16-b: url("/image/icon/arr_up_b_16.svg");
  --icon-arr-dn-16-b: url("/image/icon/arr_down_b_16.svg");

  --icon-shortcut: url("/image/icon/icon_shortcut.svg");
  --icon-shortcut-l: url("/image/icon/icon_shortcut_arr.svg");
  --icon-shortcut-l-g: url("/image/icon/icon_shortcut_arr_gray.svg");
  --icon-shortcut-w: url("/image/icon/link_circle_white.svg");
  --icon-shortcut-g: url("/image/icon/link_circle_gray.svg");
  --icon-shortcut-r: url("/image/icon/link_circle_red.svg");
  --icon-shortcut-arr-r: url("/image/icon/icon_shortcut_arr_red.svg");
  --icon-shortcut-arr-w: url("/image/icon/icon_shortcut_arr_white.svg");

  --icon-sns-facebook: url("/image/icon/ic_socialmedia-02.png");
  --icon-sns-blog: url("/image/icon/ic_socialmedia-01.png");

  --swiper-arr-r-g: url("/image/icon/swiper_arr_r_g.svg");
  --swiper-arr-l-g: url("/image/icon/swiper_arr_l_g.svg");
  //--swiper-arr-r-b: url("/image/icon/swiper_arr_r_g.svg");
  //--swiper-arr-l-b: url("/image/icon/swiper_arr_l_g.svg");

  // 메인 이미지
  --img-sec1-1: url("/image/main/01_main_02_01.png");
  --img-sec1-2: url("/image/main/01_main_02_02.png");
  --img-sec1-3: url("/image/main/01_main_02_03.png");
  --img-sec1-4: url("/image/main/01_main_02_04.png");

  --img-sec5-1: url("/image/main/01_main_06_01.png");

  --img-sec8-5: url("/image/main/01_main_09_01.png");

  --img-sec10-1: url("/image/main/01_main_11_01.png");
  --img-sec10-2: url("/image/main/01_main_11_02.png");

  --img-sec11-1: url("/image/main/01_main_12_01.png");
  --img-sec11-2: url("/image/main/01_main_12_02.png");
  --img-sec11-3: url("/image/main/01_main_12_03.png");
  --img-sec11-4: url("/image/main/01_main_12_04.png");
  --img-sec11-5: url("/image/main/01_main_12_05.png");
  --img-sec11-6: url("/image/main/01_main_12_06.png");
  --img-sec11-7: url("/image/main/01_main_12_07.png");
  --img-sec11-8: url("/image/main/01_main_12_08.png");
  --img-sec11-9: url("/image/main/01_main_12_09.png");
  --img-sec11-10: url("/image/main/01_main_12_10.png");
  --img-sec11-11: url("/image/main/01_main_12_11.png");
  --img-sec11-12: url("/image/main/01_main_12_12.png");
  --img-sec11-13: url("/image/main/01_main_12_13.png");
  --img-sec11-14: url("/image/main/01_main_12_14.png");
  --img-sec11-15: url("/image/main/01_main_12_15.png");
  --img-sec11-16: url("/image/main/01_main_12_16.png");
  --img-sec11-17: url("/image/main/01_main_12_17.png");
  --img-sec11-18: url("/image/main/01_main_12_18.png");
  --img-sec11-19: url("/image/main/01_main_12_19.png");
  --img-sec11-20: url("/image/main/01_main_12_20.png");
  --img-sec11-21: url("/image/main/01_main_12_21.png");
  --img-sec11-22: url("/image/main/01_main_12_22.png");
  --img-sec11-23: url("/image/main/01_main_12_23.png");
  --img-sec11-24: url("/image/main/01_main_12_24.png");
  --img-sec11-25: url("/image/main/01_main_12_25.png");
  --img-sec11-26: url("/image/main/01_main_12_26.png");
  --img-sec11-27: url("/image/main/01_main_12_27.png");
  --img-sec11-28: url("/image/main/01_main_12_28.png");
  --img-sec11-29: url("/image/main/01_main_12_29.png");
  --img-sec11-30: url("/image/main/01_main_12_30.png");
  --img-sec11-31: url("/image/main/01_main_12_31.png");
  --img-sec11-32: url("/image/main/01_main_12_32.png");
  --img-sec11-33: url("/image/main/01_main_12_33.png");
  --img-sec11-34: url("/image/main/01_main_12_34.png");
  --img-sec11-35: url("/image/main/01_main_12_35.png");
  --img-sec11-36: url("/image/main/01_main_12_36.png");

  --img-contact-1: url("/image/main/01_main_13_01.png");
  --img-contact-2: url("/image/main/01_main_13_02.png");

  --img-dummy-1: url("/image/main/article_dummy.png");

  // universe 이미지
  --img-universe-circle: url("/image/universe/circle.png");
  --img-universe-earth: url("/image/universe/img_universe_00.png");
  --img-universe-earth2: url("/image/universe/img_universe_12.png");
  --img-universe-diagram: url("/image/universe/img_universe_09.png");
  --img-universe-diagram-en: url("/image/universe/img_universe_09_en.png");
  --img-universe-line-01: url("/image/universe/img_universe_01.png");
  --img-universe-line-02: url("/image/universe/img_universe_02.png");
  --img-universe-line-03: url("/image/universe/img_universe_03.png");
  --img-universe-line-04: url("/image/universe/img_universe_04.png");
  --img-universe-line-05: url("/image/universe/img_universe_05.png");
  --img-universe-line-06: url("/image/universe/img_universe_06.png");
  --img-universe-line-07: url("/image/universe/img_universe_07.png");
  --img-universe-line-08: url("/image/universe/img_universe_08.png");
  --img-universe-line-01-mo: url("/image/universe/img_universe_01_m.png");
  --img-universe-line-02-mo: url("/image/universe/img_universe_02_m.png");
  --img-universe-line-03-mo: url("/image/universe/img_universe_03_m.png");
  --img-universe-line-04-mo: url("/image/universe/img_universe_04_m.png");
  --img-universe-rotate-plate: url("/image/universe/img_universe_11.png");
  --img-universe-rotate-plate-en: url("/image/universe/img_universe_11_en.png");
  //--img-universe-rotate-plate: url("/image/universe/img_universe_11_bg.png");
  --img-universe-rotate-front: url("/image/universe/img_universe_10.png");

  // tech 이미지
  --img-tech-01: url("/image/universe/tech_img_01.png");
  --img-tech-02: url("/image/universe/tech_img_02.png");
  --img-tech-03: url("/image/universe/tech_img_03.png");
  --img-tech-04: url("/image/universe/tech_img_04.png");

  // card 이미지
  --img-card-01: url("/image/universe/card_img_01.png");
  --img-card-02: url("/image/universe/card_img_02.png");
  --img-card-03: url("/image/universe/card_img_03.png");

  // emv 이미지
  --img-emv-01: url("/image/universe/emv_img_01.png");
  --img-emv-02: url("/image/universe/emv_img_02.png");
  --img-emv-03: url("/image/universe/emv_img_03.png");

  // ota 이미지
  --img-ota-01: url("/image/universe/ota_img_01.png");
  --img-ota-02: url("/image/universe/ota_img_02.png");

  // blockchain 이미지
  --img-blockchain-01: url("/image/universe/blockchain_img_01.png");
  --img-blockchain-02: url("/image/universe/blockchain_img_02.png");
  --img-blockchain-03: url("/image/universe/blockchain_img_03.png");

  // careers 이미지
  --img-careers-01: url("/image/careers/careers_img_01.png");
  --img-careers-02: url("/image/careers/careers_img_02.png");
  --img-careers-03: url("/image/careers/careers_img_03.png");
  --img-careers-04: url("/image/careers/careers_13.png");
  --img-careers-05: url("/image/careers/careers_14.png");
  --img-careers-06: url("/image/careers/careers_15.png");
  --img-careers-07: url("/image/careers/careers_16.png");
  --img-careers-08: url("/image/careers/careers_17.png");
  --img-careers-09: url("/image/careers/careers_18.png");
  --img-careers-10: url("/image/careers/careers_19.png");

  --img-careers-ico-01: url("/image/careers/careers_20.svg");
  --img-careers-ico-02: url("/image/careers/careers_26.svg");
  --img-careers-ico-03: url("/image/careers/careers_21.svg");
  --img-careers-ico-04: url("/image/careers/careers_27.svg");
  --img-careers-ico-05: url("/image/careers/careers_22.svg");
  --img-careers-ico-06: url("/image/careers/careers_28.svg");
  --img-careers-ico-07: url("/image/careers/careers_23.svg");
  --img-careers-ico-08: url("/image/careers/careers_29.svg");
  --img-careers-ico-09: url("/image/careers/careers_24.svg");
  --img-careers-ico-10: url("/image/careers/careers_30.svg");
  --img-careers-ico-11: url("/image/careers/careers_25.svg");
  --img-careers-ico-12: url("/image/careers/careers_31.svg");

  --img-careers-konadaum-01: url("/image/careers/img_konadaum_01.png");
  --img-careers-konadaum-02: url("/image/careers/img_konadaum_02.png");
  --img-careers-konadaum-03: url("/image/careers/img_konadaum_03.png");
  --img-careers-konadaum-04: url("/image/careers/img_konadaum_04.png");
  --img-careers-konadaum-05: url("/image/careers/img_konadaum_05.png");
  --img-careers-konadaum-06: url("/image/careers/img_konadaum_06.png");
  --img-careers-konadaum-07: url("/image/careers/img_konadaum_07.png");

  --img-careers-konadaum-01-en: url("/image/careers/img_konadaum_01_en.png");
  --img-careers-konadaum-02-en: url("/image/careers/img_konadaum_02_en.png");
  --img-careers-konadaum-03-en: url("/image/careers/img_konadaum_03_en.png");
  --img-careers-konadaum-04-en: url("/image/careers/img_konadaum_04_en.png");
  --img-careers-konadaum-05-en: url("/image/careers/img_konadaum_05_en.png");
  --img-careers-konadaum-06-en: url("/image/careers/img_konadaum_06_en.png");
  --img-careers-konadaum-07-en: url("/image/careers/img_konadaum_07_en.png");

  // about 이미지
  --img-about-bg: url("/image/about/about_01_01.png");
  --img-about-bg-mo: url("/image/about/about_01_01.png");

  // ci 이미지
  --img-ci-bg: url("/image/ci/design_asset_01_01.png");
  --img-ci-bg-mo: url("/image/ci/design_asset_01_01_m.png");

  // product-did 이미지
  --img-did-bg: url("/image/product/img_product_did_01.png");
  --img-did-bg-mo: url("/image/product/img_product_did_01_m.png");
  --img-did-cont-01: url("/image/product/img_product_did_02.png");
  --img-did-cont-02: url("/image/product/img_product_did_03.png");
  --img-did-cont-03: url("/image/product/img_product_did_04.png");
  --img-did-card-01: url("/image/product/img_product_did_05.png");
  --img-did-card-02: url("/image/product/img_product_did_06.png");
  --img-did-card-03: url("/image/product/img_product_did_07.png");
  --img-did-01: url("/image/product/img_product_did_08.png");
  --img-did-02: url("/image/product/img_product_did_09.png");
  --img-did-03: url("/image/product/img_product_did_10.png");
  --img-did-card-dummy: url("/image/product/img_product_did_chip_dummy.png");
  --img-did-card-dummy-mo: url("/image/product/img_product_did_chip_dummy_m.png");

  --img-chip-bg: url("/image/product/img_product_did_chip_01.png");
  --img-chip-bg-mo: url("/image/product/img_product_did_chip_01_m.png");
  --img-chip-card-01: url("/image/product/img_product_did_chip_02.png");
  --img-chip-card-02: url("/image/product/img_product_did_chip_03.png");
  --img-chip-card-03: url("/image/product/img_product_did_chip_04.png");
  --img-chip-card-04: url("/image/product/img_product_did_chip_05.png");
  --img-chip-card-05: url("/image/product/img_product_did_chip_06.png");
  --img-chip-01: url("/image/product/img_product_did_chip_07.png");
  --img-chip-02: url("/image/product/img_product_did_chip_08.png");
  --img-chip-03: url("/image/product/img_product_did_chip_09.png");
  --img-chip-04: url("/image/product/img_product_did_chip_10.png");

  --img-smartcard-bg: url("/image/product/img_product_did_smartcard_01.png");
  --img-smartcard-bg-mo: url("/image/product/img_product_did_smartcard_01_m.png");
  --img-smartcard-01: url("/image/product/img_product_did_smartcard_02.png");
  --img-smartcard-02: url("/image/product/img_product_did_smartcard_03.png");
  --img-smartcard-03: url("/image/product/img_product_did_smartcard_04.png");
  --img-smartcard-04: url("/image/product/img_product_did_smartcard_05.png");
  --img-smartcard-05: url("/image/product/img_product_did_smartcard_06.png");
  --img-smartcard-06: url("/image/product/img_product_did_smartcard_07.png");
  --img-smartcard-07: url("/image/product/img_product_did_smartcard_08.png");
  --img-smartcard-08: url("/image/product/img_product_did_smartcard_09.png");
  --img-smartcard-09: url("/image/product/img_product_did_smartcard_10.png");
  --img-smartcard-10: url("/image/product/img_product_did_smartcard_11.png");
  --img-smartcard-11: url("/image/product/img_product_did_smartcard_13.png");
  --img-smartcard-12: url("/image/product/img_product_did_smartcard_12.png");
  --img-smartcard-13: url("/image/product/img_product_did_smartcard_14.png");
  --img-smartcard-14: url("/image/product/img_product_did_smartcard_15.png");
  --img-smartcard-15: url("/image/product/img_product_did_smartcard_16.png");

  // product-payment 이미지
  --img-payment-bg: url("/image/product/img_product_payment_01.png");
  --img-payment-bg-mo: url("/image/product/img_product_payment_01_m.png");
  --img-payment-card-01: url("/image/product/img_product_payment_05.png");
  --img-payment-card-02: url("/image/product/img_product_payment_06.png");
  --img-payment-card-03: url("/image/product/img_product_payment_07.png");
  --img-payment-card-04: url("/image/product/img_product_payment_08.png");
  --img-payment-01: url("/image/product/img_product_payment_09.png");
  --img-payment-02: url("/image/product/img_product_payment_10.png");
  --img-payment-03: url("/image/product/img_product_payment_11.png");
  --img-payment-04: url("/image/product/img_product_payment_14.png");
  --img-payment-05: url("/image/product/img_product_payment_12.png");
  --img-payment-06: url("/image/product/img_product_payment_13.png");
  --img-payment-07: url("/image/product/img_product_payment_15.png");
  --img-payment-08: url("/image/product/img_product_payment_16.png");
  --img-payment-09: url("/image/product/img_product_payment_17.png");
  --img-payment-10: url("/image/product/img_product_payment_18.png");
  --img-payment-11: url("/image/product/img_product_payment_19.png");
  --img-payment-12: url("/image/product/img_product_payment_20.png");
  --img-payment-01-01: url("/image/product/img_product_payment_02.png");
  --img-payment-01-02: url("/image/product/img_product_payment_03.png");
  --img-payment-01-03: url("/image/product/img_product_payment_04.png");
  --img-payment-local-bg: url("/image/product/img_product_payment_local_01.png");
  --img-payment-local-bg-mo: url("/image/product/img_product_payment_local_01_m.png");
  --img-local-01: url("/image/product/img_product_payment_local_02.png");
  --img-local-01-mo: url("/image/product/img_product_payment_local_02_m.png");
  --img-local-02: url("/image/product/img_product_payment_local_03.png");

  --img-local-03: url("/image/product/ic_product_payment_local_01.png");
  --img-local-04: url("/image/product/ic_product_payment_local_02.png");
  --img-local-05: url("/image/product/ic_product_payment_local_03.png");
  --img-local-06: url("/image/product/ic_product_payment_local_04.png");
  --img-local-07: url("/image/product/ic_product_payment_local_05.png");
  --img-local-08: url("/image/product/ic_product_payment_local_06.png");

  --img-local-09: url("/image/product/img_product_payment_local_04.png");
  --img-local-10: url("/image/product/img_product_payment_local_05.png");
  --img-local-11: url("/image/product/img_product_payment_local_06.png");

  --img-local-12: url("/image/product/img_product_payment_local_07.png");
  --img-local-13: url("/image/product/img_product_payment_local_08.png");
  --img-local-14: url("/image/product/img_product_payment_local_09.png");
  --img-local-15: url("/image/product/img_product_payment_local_10.png");
  --img-local-16: url("/image/product/img_product_payment_local_11.png");
  --img-local-17: url("/image/product/img_product_payment_local_12.png");
  --img-local-18: url("/image/product/img_product_payment_local_13.png");
  --img-local-19: url("/image/product/img_product_payment_local_14.png");

  // product-mobility 이미지
  --img-mobility-bg: url("/image/product/img_product_mobility_01.png");
  --img-mobility-bg-mo: url("/image/product/img_product_mobility_01_m.png");
  --img-mobility-01: url("/image/product/img_product_mobility_02.png");
  --img-mobility-01-mo: url("/image/product/img_product_mobility_02_m.png");
  --img-mobility-02: url("/image/product/img_product_mobility_03.png");
  --img-mobility-03: url("/image/product/img_product_mobility_04.png");
  --img-mobility-04: url("/image/product/img_product_mobility_05.png");
  --img-mobility-05: url("/image/product/img_product_mobility_06.png");
  --img-mobility-06: url("/image/product/img_product_mobility_07.png");
  --img-mobility-07: url("/image/product/img_product_mobility_08.png");

  --img-taxicall-bg: url("/image/product/img_product_mobility_taxi_01.png");
  --img-taxicall-bg-mo: url("/image/product/img_product_mobility_taxi_01_m.png");
  --img-taxicall-01: url("/image/product/img_product_mobility_taxi_02.png");

  --img-taxicall-02: url("/image/product/img_product_mobility_taxi_03.png");
  --img-taxicall-03: url("/image/product/img_product_mobility_taxi_04.png");
  --img-taxicall-04: url("/image/product/img_product_mobility_taxi_05.png");

  --img-taxicall-05: url("/image/product/ic_product_mobility_taxi_01.png");
  --img-taxicall-06: url("/image/product/ic_product_mobility_taxi_02.png");
  --img-taxicall-07: url("/image/product/ic_product_mobility_taxi_03.png");
  --img-taxicall-08: url("/image/product/ic_product_mobility_taxi_04.png");
  --img-taxicall-09: url("/image/product/ic_product_mobility_taxi_05.png");
  --img-taxicall-10: url("/image/product/ic_product_mobility_taxi_06.png");

  --img-taxicall-11: url("/image/product/btn_store_ios.png");
  --img-taxicall-12: url("/image/product/btn_store_aos.png");

  --img-appmeter-bg: url("/image/product/img_product_mobility_app meter_01.png");
  --img-appmeter-bg-mo: url("/image/product/img_product_mobility_app meter_01_m.png");
  --img-appmeter-01: url("/image/product/img_product_mobility_app meter_02.png");
  --img-appmeter-02: url("/image/product/img_product_mobility_app meter_03.png");
  --img-appmeter-03: url("/image/product/img_product_mobility_app meter_04.png");
  --img-appmeter-10: url("/image/product/img_product_mobility_app meter_05.png");

  --img-appmeter-04: url("/image/product/ic_product_mobility_app meter_01.png");
  --img-appmeter-05: url("/image/product/ic_product_mobility_app meter_02.png");
  --img-appmeter-06: url("/image/product/ic_product_mobility_app meter_03.png");
  --img-appmeter-07: url("/image/product/ic_product_mobility_app meter_04.png");
  --img-appmeter-08: url("/image/product/ic_product_mobility_app meter_05.png");
  --img-appmeter-09: url("/image/product/ic_product_mobility_app meter_06.png");

  --img-appmeter-11: url("/image/product/ic_product_mobility_app meter_07.png");
  --img-appmeter-12: url("/image/product/ic_product_mobility_app meter_08.png");

  // product-blockchain 이미지
  --img-pblockchain-bg: url("/image/product/img_product_blockchain_01_01.png");
  --img-pblockchain-bg-mo: url("/image/product/img_product_blockchain_01_01_m.png");
  --img-pblockchain-01: url("/image/product/img_product_blockchain_02_01.png");
  --img-pblockchain-02: url("/image/product/img_product_blockchain_02_02.png");
  --img-pblockchain-03: url("/image/product/img_product_blockchain_02_03.png");
  --img-pblockchain-04: url("/image/product/img_product_blockchain_03_01.png");
  --img-pblockchain-05: url("/image/product/img_product_blockchain_03_02.png");
  --img-pblockchain-06: url("/image/product/img_product_blockchain_03_03.png");
  --img-pblockchain-07: url("/image/product/img_product_blockchain_04_01.png");
  --img-pblockchain-08: url("/image/product/img_product_blockchain_04_02.png");
  --img-pblockchain-09: url("/image/product/img_product_blockchain_04_03.png");
  --img-pncp-bg: url("/image/product/img_product_blockchain_ncp_01.png");
  --img-pncp-bg-mo: url("/image/product/img_product_blockchain_ncp_01_m.png");
  --img-pncp-01: url("/image/product/img_product_blockchain_ncp_02.png");
  --img-pncp-02: url("/image/product/img_product_blockchain_ncp_03.png");
  --img-pncp-03: url("/image/product/img_product_blockchain_ncp_04.png");
  --img-pncp-04: url("/image/product/img_product_blockchain_ncp_05.png");
  --img-pncp-05: url("/image/product/img_product_blockchain_ncp_06.png");
}



// PC 이미지
@media screen and (min-width: 1240px) {
  :root{
    --01_main_01_01: url("/image/main/01_main_01.png");
    --01_main_01_01-en: url("/image/main/01_main_01_en.jpg");

    --img-sec3-1: url("/image/main/01_main_04_01.png");
    --img-sec3-2: url("/image/main/01_main_05_01.png");

    --img-sec7-1: url("/image/main/01_main_08_01.png");

    --img-sec8-1: url("/image/main/01_main_09_01.png");
    --img-sec8-2: url("/image/main/01_main_09_02.png");
    --img-sec8-3: url("/image/main/01_main_09_03.png");
    --img-sec8-4: url("/image/main/01_main_09_04.png");

    // ir 이미지
    --img-ir-bg01-01: url("/image/irpr/ir_juga_01.png");
    --img-ir-bg01-01-mo: url("/image/irpr/ir_juga_01_m.png");

    // pr 이미지
    --img-pr-bg01-01: url("/image/irpr/pr_news_01.png");
    --img-pr-bg01-01-mo: url("/image/irpr/pr_news_01_m.png");

    // univserse 이미지
    --img-universe-bg01-01: url("/image/universe/universe_main_01.png");
    --img-universe-bg01-01-mo: url("/image/universe/universe_main_01_m.png");
    --img-universe-bg01-02: url("/image/universe/universe_main_02.png");
    --img-universe-bg01-02-mo: url("/image/universe/universe_main_02_m.png");

    // tech 이미지
    --img-tech-bg01-01: url("/image/universe/tech_main_01.png");
    --img-tech-bg01-01-mo: url("/image/universe/tech_main_01_m.png");

    // card 이미지
    --img-card-bg01-01: url("/image/universe/card_main_01.png");
    --img-card-bg01-01-mo: url("/image/universe/card_main_01_m.png");

    // emv 이미지
    --img-emv-bg01-01: url("/image/universe/emv_main_01.png");
    --img-emv-bg01-01-mo: url("/image/universe/emv_main_01_m.png");

    // ota 이미지
    --img-ota-bg01-01: url("/image/universe/ota_main_01.png");
    --img-ota-bg01-01-mo: url("/image/universe/ota_main_01_m.png");

    // blockchain 이미지
    --img-blockchain-bg01-01: url("/image/universe/blockchain_main_01.png");
    --img-blockchain-bg01-01-mo: url("/image/universe/blockchain_main_01_m.png");

    // careers 이미지
    --img-careers-bg01-01: url("/image/careers/careers_01.png");
    --img-careers-bg01-01-mo: url("/image/careers/careers_01_m.png");
    --img-careers-bg01-02: url("/image/careers/careers_05.png");
    --img-careers-bg01-02-mo: url("/image/careers/careers_05_m.png");
  }
}

// TABLET 이미지
@media screen and (min-width: 801px) and (max-width: 1239px) {
  :root{
    --01_main_01_01-mo: url("/image/main/01_main_01.png");
    --01_main_01_01-mo-en: url("/image/main/01_main_01_en.jpg");

    --img-sec3-1-mo: url("/image/main/01_main_04_01.png");
    --img-sec3-2-mo: url("/image/main/01_main_05_01.png");

    --img-sec7-1: url("/image/main/01_main_08_01.png");

    --img-sec8-1-mo: url("/image/main/01_main_09_01.png");
    --img-sec8-2-mo: url("/image/main/01_main_09_02.png");
    --img-sec8-3-mo: url("/image/main/01_main_09_03.png");
    --img-sec8-4-mo: url("/image/main/01_main_09_04.png");

    // ir 이미지
    --img-ir-bg01-01: url("/image/irpr/ir_juga_01.png");
    --img-ir-bg01-01-mo: url("/image/irpr/ir_juga_01_m.png");

    // pr 이미지
    --img-pr-bg01-01: url("/image/irpr/pr_news_01.png");
    --img-pr-bg01-01-mo: url("/image/irpr/pr_news_01_m.png");

    // univserse 이미지
    --img-universe-bg01-01: url("/image/universe/universe_main_01.png");
    --img-universe-bg01-01-mo: url("/image/universe/universe_main_01_m.png");
    --img-universe-bg01-02: url("/image/universe/universe_main_02.png");
    --img-universe-bg01-02-mo: url("/image/universe/universe_main_02_m.png");

    // tech 이미지
    --img-tech-bg01-01: url("/image/universe/tech_main_01.png");
    --img-tech-bg01-01-mo: url("/image/universe/tech_main_01_m.png");
    --img-tech-01: url("/image/universe/tech_img_01.png");
    --img-tech-01-mo: url("/image/universe/tech_img_01_m.png");
    --img-tech-02: url("/image/universe/tech_img_02.png");
    --img-tech-02-mo: url("/image/universe/tech_img_02_m.png");
    --img-tech-03: url("/image/universe/tech_img_03.png");
    --img-tech-03-mo: url("/image/universe/tech_img_03_m.png");
    --img-tech-04: url("/image/universe/tech_img_04.png");
    --img-tech-04-mo: url("/image/universe/tech_img_04_m.png");

    // card 이미지
    --img-card-bg01-01: url("/image/universe/card_main_01.png");
    --img-card-bg01-01-mo: url("/image/universe/card_main_01_m.png");
    --img-card-01: url("/image/universe/card_img_01.png");
    --img-card-01-mo: url("/image/universe/card_img_01_m.png");
    --img-card-02: url("/image/universe/card_img_02.png");
    --img-card-02-mo: url("/image/universe/card_img_02_m.png");
    --img-card-03: url("/image/universe/card_img_03.png");
    --img-card-03-mo: url("/image/universe/card_img_03_m.png");

    // emv 이미지
    --img-emv-bg01-01: url("/image/universe/emv_main_01.png");
    --img-emv-bg01-01-mo: url("/image/universe/emv_main_01_m.png");
    --img-emv-01: url("/image/universe/emv_img_01.png");
    --img-emv-01-mo: url("/image/universe/emv_img_01_m.png");
    --img-emv-02: url("/image/universe/emv_img_02.png");
    --img-emv-02-mo: url("/image/universe/emv_img_02_m.png");
    --img-emv-03: url("/image/universe/emv_img_03.png");
    --img-emv-03-mo: url("/image/universe/emv_img_03_m.png");

    // ota 이미지
    --img-ota-bg01-01: url("/image/universe/ota_main_01.png");
    --img-ota-bg01-01-mo: url("/image/universe/ota_main_01_m.png");
    --img-ota-01: url("/image/universe/ota_img_01.png");
    --img-ota-01-mo: url("/image/universe/ota_img_01_m.png");
    --img-ota-02: url("/image/universe/ota_img_02.png");
    --img-ota-02-mo: url("/image/universe/ota_img_02_m.png");

    // blockchain 이미지
    --img-blockchain-bg01-01: url("/image/universe/blockchain_main_01.png");
    --img-blockchain-bg01-01-mo: url("/image/universe/blockchain_main_01_m.png");
    --img-blockchain-01: url("/image/universe/blockchain_img_01.png");
    --img-blockchain-01-mo: url("/image/universe/blockchain_img_01_m.png");
    --img-blockchain-02: url("/image/universe/blockchain_img_02.png");
    --img-blockchain-02-mo: url("/image/universe/blockchain_img_02_m.png");
    --img-blockchain-03: url("/image/universe/blockchain_img_03.png");
    --img-blockchain-03-mo: url("/image/universe/blockchain_img_03_m.png");

    // careers 이미지
    --img-careers-bg01-01: url("/image/careers/careers_01.png");
    --img-careers-bg01-01-mo: url("/image/careers/careers_01_m.png");
    --img-careers-bg01-02: url("/image/careers/careers_05.png");
    --img-careers-bg01-02-mo: url("/image/careers/careers_05_m.png");

  }
}

// MO 이미지
@media screen and (max-width: 800px) {
  :root{
    --01_main_01_01-mo: url("/image/main/01_main_01_m.png");
    --01_main_01_01-mo-en: url("/image/main/01_main_01_m_en.png");

    --img-sec3-1-mo: url("/image/main/01_main_04_01_m.png");
    --img-sec3-2-mo: url("/image/main/01_main_05_01_m.png");

    --img-sec7-1: url("/image/main/01_main_08_01_m.png");

    --img-sec8-1-mo: url("/image/main/01_main_09_01_m.png");
    --img-sec8-2-mo: url("/image/main/01_main_09_02_m.png");
    --img-sec8-3-mo: url("/image/main/01_main_09_03_m.png");
    --img-sec8-4-mo: url("/image/main/01_main_09_04_m.png");

    // ir 이미지
    --img-ir-bg01-01-mo: url("/image/irpr/ir_juga_01_m.png");

    // pr 이미지
    --img-pr-bg01-01-mo: url("/image/irpr/pr_news_01_m.png");

    // univserse 이미지
    --img-universe-bg01-01-mo: url("/image/universe/universe_main_01_m.png");
    --img-universe-bg01-02: url("/image/universe/universe_main_02.png");
    --img-universe-bg01-02-mo: url("/image/universe/universe_main_02_m.png");

    // tech 이미지
    --img-tech-bg01-01-mo: url("/image/universe/tech_main_01_m.png");
    --img-tech-01-mo: url("/image/universe/tech_img_01_m.png");
    --img-tech-02-mo: url("/image/universe/tech_img_02_m.png");
    --img-tech-03-mo: url("/image/universe/tech_img_03_m.png");
    --img-tech-04-mo: url("/image/universe/tech_img_04_m.png");

    // card 이미지
    --img-card-bg01-01-mo: url("/image/universe/card_main_01_m.png");
    --img-card-01-mo: url("/image/universe/card_img_01_m.png");
    --img-card-02-mo: url("/image/universe/card_img_02_m.png");
    --img-card-03-mo: url("/image/universe/card_img_03_m.png");

    // emv 이미지
    --img-emv-bg01-01-mo: url("/image/universe/emv_main_01_m.png");
    --img-emv-01-mo: url("/image/universe/emv_img_01_m.png");
    --img-emv-02-mo: url("/image/universe/emv_img_02_m.png");
    --img-emv-03-mo: url("/image/universe/emv_img_03_m.png");

    // ota 이미지
    --img-ota-bg01-01-mo: url("/image/universe/ota_main_01_m.png");
    --img-ota-01-mo: url("/image/universe/ota_img_01_m.png");
    --img-ota-02-mo: url("/image/universe/ota_img_02_m.png");

    // blockchain 이미지
    --img-blockchain-bg01-01-mo: url("/image/universe/blockchain_main_01_m.png");
    --img-blockchain-01-mo: url("/image/universe/blockchain_img_01_m.png");
    --img-blockchain-02-mo: url("/image/universe/blockchain_img_02_m.png");
    --img-blockchain-03-mo: url("/image/universe/blockchain_img_03_m.png");

    // careers 이미지
    --img-careers-bg01-01-mo: url("/image/careers/careers_01_m.png");
    --img-careers-bg01-02-mo: url("/image/careers/careers_05_m.png");
  }
}

// MO LANDSCAPE 이미지
@media (orientation: landscape) {
  :root{
    --01_main_01_01: url("/image/main/01_main_01.png");
    --01_main_01_01-mo: url("/image/main/01_main_01.png");
    --01_main_01_01-en: url("/image/main/01_main_01_en.jpg");
    --01_main_01_01-mo-en: url("/image/main/01_main_01_en.jpg");

    //--img-sec7-1: url("/image/main/01_main_08_01_m.png");

    --img-sec8-1: url("/image/main/01_main_09_01.png");
    --img-sec8-1-mo: url("/image/main/01_main_09_01.png");
    --img-sec8-2: url("/image/main/01_main_09_02.png");
    --img-sec8-2-mo: url("/image/main/01_main_09_02.png");
    --img-sec8-3: url("/image/main/01_main_09_03.png");
    --img-sec8-3-mo: url("/image/main/01_main_09_03.png");
    --img-sec8-4: url("/image/main/01_main_09_04.png");
    --img-sec8-4-mo: url("/image/main/01_main_09_04.png");
  }
}