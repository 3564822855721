.scrollmagic-pin-spacer {
  &:nth-child(2) {
    .scrollinner.for_about {
      inset: 0 auto auto !important;
    }
  }
}
.frame {
  &-container {
    &.for_about {
      height: 100%;
      background-color: $--konai-black;
      .frame-container-bg {
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0;
        right: 0;
        top: -80px;
        bottom: 0;
        //background-image: var(--img-about-bg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: -1;
          background-color: $--konai-black;
          background-image: var(--img-about-bg);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
        video {
          position: absolute;
          display: inline-block;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 9;
          object-fit: cover;
        }
        &.fixed {
          position: fixed;
          top: 0;
          z-index: -1;
        }
      }
      &.scrollinner {
        height: 100%;
        top: -80px;
      }
      .flex {
        .wrap {
          position: absolute;
          display: block;
          width: auto;
          height: auto;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &.type_3,&.type_4{
            opacity: 0;
            .main-text {
              font-size: 23rem;
              font-weight: 700;
              line-height: 1.48;
            }
          }
        }
      }
      .frame-container__inner{
        position: absolute;
        width: 100%;
        height: calc(100%);
        top: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgb(0 0 0 / 30%);
        .flex {
          .wrap{
            width: 100%;
            &.type_3, &.type_4 {
              //opacity: 0;
              //visibility: hidden;
              .text_wrap{
                .main-title{
                  margin: 20rem 0 50rem;
                }
              }
            }
            .text_wrap{
              .frame-subpoint{
                margin-bottom: 0;
              }
              .main-title{
                color: #fff;
                //margin: 2rem 0 40rem;
              }
              .main-text{
                color: #fff;
              }
            }
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
    .for_about {
      display: block;
    }
  }
}
.about-contents{
  //position: relative;
  margin-top: -80px;
  //z-index: 5;
  .for_pc {
    display: block;
  }
  .for_mo {
    display: none;
  }
  & > *:not(.about_attached){
    position: relative;
    height: auto;
    z-index: 5;
  }
  .ceo-container{
    background-color: #EBEBEB;
    padding: 180rem 0;
    height: auto;
    .inner_wrap{
      max-width: 1200px;
      margin: auto;
      .flex{
        display: flex;
        .wrap{
          margin: unset;
          flex: 1;
          .main-subpoint{
            text-align: left;
          }
          .main-title{
            text-align: left;
            margin: 20rem 0 50rem;
          }
          .main-text{
            text-align: left;
            .sign_box{
              margin: 50rem 0 0 ;
              span{
                display: inline-block;
                vertical-align: bottom;
                font-size: 24rem;
                font-weight: 700;
                line-height: normal;
              }
              img{
                vertical-align: text-bottom;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
  .kona-container{
    background-color: #f7f7f7;
    padding: 180rem 0;
    .inner_wrap{
      max-width: 1200px;
      margin: auto;
      .flex{
        .wrap{
          .main-title{
          }
          .main-text{
            margin: 35rem 0 0;
          }
          &.flex{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            height: auto;
            margin-top: 118rem;
            .box{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              text-align: center;
              &:last-child{
                margin: 100rem 0 0;
              }
              & > div{
                flex: 1;
                &:first-child{
                  margin-right: 170rem;
                }
              }
              img{
                margin: 0 0 15rem;
              }
              .main-subpoint{
                font-size: 16rem;
                line-height: 22px;
                color: #ff3c42;
              }
              .main-title{
                font-size: 30rem;
                line-height: 46px;
                margin: 12px 0;
              }
              .main-text{
                font-size: 16rem;
                line-height: 26px;
                margin: 0;
              }
            }
          }
        }
      }
    }
    .for_pc {
      display: block;
    }
    .for_mo {
      display: none;
    }
  }
  .history-container {
    background-color: #000000;
    padding-top: 180rem;
    padding-bottom: 180rem;
    &>.container {
      max-width: 1200px;
      margin: 0 auto;
      h3 {
        color: #ffffff;
        text-align: left;
      }
      .history-list {
        .history-list-item {
          padding-top: 200rem;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          max-width: 1200rem;
          .sticky {
            flex: 1;
            position: sticky;
            top: 100px;
            left: 0;
            align-self: flex-start;
            display: flex;
            .tit_box {
              flex: 1;
              span {
                font-weight: 800;
                text-align: left;
                color: #FF3C42;
                font-size: 26rem;
              }
              h2 {
                text-align: left;
                color: #ffffff;
                margin: 0 0 27rem;
              }
              p {
                text-align: left;
                color: #ffffff;
              }
            }
          }
          .year-content {
            padding-top: 55rem;
            //flex: 0 0 50%;
            //max-width: 50%;
            width: 50%;
            max-width: 458rem;
            position: relative;
            &>li {
              &+li {
                margin-top: 70rem;
              }
              .month {
                font-size: 26rem;
                color: #ffffff;
                display: block;
                line-height: 36px;
                margin-bottom: 10px;
              }
              p {
                text-align: left;
                color: #ffffff;
                line-height: 30px;
                & + p{
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .swiper-container{
    background-color: #FF3C42;
    padding:  0 0 180rem;
    .title_box{
      max-width: 1200px;
      padding: 180rem 180rem 60rem;
      .main-title{
        text-align: left;
        color: #fff;
      }
      .main-text{
        text-align: left;
        color: #fff;
        margin: 50rem 0 0;
      }
    }
    .swiper {
      position: relative;
      height: 100%;
      padding:  0 0 180rem;
      .swiper-wrapper{
        padding: 35px 0;
        .swiper-slide {
          max-width: 300px;
          border-radius: 12px;
          background-color: var(--konai-red, #FF3C42);
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
          bottom: 0;
          &.swiper-slide-active{
            background-color:#ffffff;
            bottom: 35px;
            transition: bottom .3s ease-in-out;
            .item{
              .top{
                border-radius: 50px;
                background: var(--konai-red, #FF3C42);
                padding: 5px 20px;
                width: max-content;
              }
              .bottom{
                p{
                  color: var(--konai-black, #000);
                }
              }
            }
          }
          .item{
            min-height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 25rem;
            .top{
              border-radius: 50px;
              background: var(--konai-red, #FF3C42);
              padding: 5px 20px;
              width: max-content;
              border: 1px solid var(--konai-white, #FFF);
              h5{
                color: var(--konai-white, #FFF);
                font-size: 24rem;
                font-weight: 700;
                line-height: 36px; /* 150% */
              }
            }
            .bottom{
              p{
                color: var(--konai-white, #fff);
                font-size: 24rem;
                font-weight: 700;
                line-height: 36px; /* 150% */
                word-break: break-word;
              }
            }
          }
        }

      }
      .swiper_button_box{
        position: absolute;
        display: grid;
        bottom: 15px;
        width: 100%;
        padding: 0rem 0rem 0rem 180rem;
        grid-template-columns: 200px 1fr;
        .box_1{
          flex: 1;
          display: flex;
          align-self: center;
          .swiper-button-next{
            position: relative;
            flex: 1;
            right: 0;
            text-align: left;
            display: block;
            &::after{
              background: url(../../image/about/02_about_11_02.svg)no-repeat;
              background-size: 50px;
              font-size: 0;
              width: 50px;
              height: 50px;
              display: inline-block;
            }
          }
          .swiper-button-prev{
            position: relative;
            flex: 1;
            left: 0;
            // text-align: right;
            display: block;
            &::after{
              background: url(../../image/about/02_about_11_01.svg)no-repeat;
              background-size: 50px;
              font-size: 0;
              width: 50px;
              height: 50px;
              display: inline-block;

            }
          }
        }
        .box_2{
          flex: 0 0 70%;
          align-self: center;
          .swiper-pagination{
            position: relative;
            background:#ffffff;
            height: 1px;
            .swiper-pagination-progressbar-fill{
              background: #fff;
              height: 3px;
              bottom: 0px;
              top: unset;
            }
          }
        }
      }
    }
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .about_attached{
    background: linear-gradient(90deg, #D9D9D9 49.83%, #EBEBEB 99.5%);
    .footer-attached__inner{
      .footer-attached-title{
        br{
          display: none;
        }
      }
      h4{
        color: #000000;
      }
      .btn-md{
        background-color: var(--konai-red, #FF3C42);
        color: #ffffff;
      }
    }
  }
}


.parent{
  position: absolute;
  display: block;
  width: 100%;
  //height: 100%;
  height: auto;
  min-height: 1102px;
  max-height: 1102px;
  &.parent_1{
    right: 0;
    .child{
      display: block;
      width: 100%;
      height: 100%;
      max-width: 800px;
      background: url(../../image/about/about_04_01.png) no-repeat;
      background-size: contain;
      position: absolute;
      right: 0;
      opacity: 0.3;
    }
  }
  &.parent_2{
    right: 0;
    .child{
      display: block;
      width: 100%;
      height: 100%;
      max-width: 525px;
      min-height: 733px;
      max-height: 733px;
      background: url(../../image/about/about_04_02.png) no-repeat;
      background-size: contain;
      position: absolute;
      left: 35%;
      top: 15%;
      opacity: 0.3;
      z-index: -1;
    }
  }
  &.parent_3{
    right: 0;
    .child{
      display: block;
      width: 100%;
      height: 100%;
      max-width: 652px;
      min-height: 909px;
      max-height: 909px;
      background: url(../../image/about/about_04_03.png) no-repeat;
      background-size: contain;
      position: absolute;
      right: 0;
      top: -5%;
      opacity: 0.3;
    }
  }
}


// 미디어쿼리 @media
@import './pages/about_media';