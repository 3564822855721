*, *:before, *:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
}
html {
  scroll-behavior: smooth;
}
html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  text-size-adjust: none;
}
// html::-webkit-scrollbar {
//   width: 5px;
// }

// html::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// html::-webkit-scrollbar-thumb {
//   background: #888;
// }

// html::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

/* 파이어폭스를 위한 설정 */
html {
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
footer, header, nav, section, main {
  display: block;
}
section{
  // overflow: hidden;
}
body {
  width: 100%;
  height: 100%;
  //margin: 0 auto;
  line-height: 1;
  font-family: 'Pretendard', sans-serif;
}
.wrap{
  width: 100%;
  height: 100%;
}
.slide_box{
  overflow: hidden;
}
ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

img {
  border: 0;
  user-select: none;
}

a, label {
  text-decoration: none;
  user-select: none;
  &:focus, &:active, &:-webkit-any-link {
    color: inherit;
    text-decoration: inherit;
  }
}

a:not(.tag) {
  pointer-events: auto;
  cursor: pointer;
}

input, textarea, button {
  font-family: 'Pretendard', sans-serif;
  // font-weight: 400;
  // font-size: 18px;
}

input[type='number']::-webkit-inner-spin-button, 
input[type='number']::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}

button{
  border: 0;
  outline: 0;
  background-color: unset;
  cursor: pointer;
  user-select: none;
  margin: 0;
  padding: 0;
  &:focus, &:active, &:-webkit-any-link {
    color: inherit;
    text-decoration: inherit;
  }
}

.swiper {
  max-width: 100% !important;
}


// PC SET
@media screen and (min-width: 1240px) and (pointer: fine) and (hover: hover) {
	.header, .footer, section {
    // min-width: 1240px;
	}
}

// MO SET
@media screen and (max-width: 1239px) {
  .header, .footer, .wrap {
    max-width: 100%;
  }
}
