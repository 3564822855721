.scrollmagic-pin-spacer {
  &:not(:nth-child(2)) {
    .scrollinner.for_did {
      inset: 80px auto auto !important;
    }
  }
}
.frame {
  &-container {
    &__inner {
      &#fixedScroll_1 {
        height: 100% !important;
        .wrap {
          &[aria-for="section-1"] {
            height: 100%;
          }
          &.hidden {
            * {
              display: none !important;
            }
          }
        }
      }
    }
    &.for_did {
      .frame-container-bg {
        height: 100%;
        top: 0;
        &.active {
          background-color: $--konai-black;
          transition: background-color 0.1s 0.65s ease-in-out;
          &:before {
            opacity: 1;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.dimmed {
          &:before {
            opacity: 0.4;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.fixed {
          position: fixed;
          top: 0;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 0;
          opacity: 0;
          transition: opacity 0.65s ease-in-out;
        }
        &.section {
          &_1 {
            &.type {
              &_1 {
                &:before {
                  background-image: var(--img-did-bg);
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
        }
      }
      div[class*='sec-'] {
        padding: 0;
        background-color: transparent;
      }
      &.scrollinner {
        height: 100%;
        top: -80px;
        .flex {
          .wrap {
            position: absolute;
            display: block;
            width: auto;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .text_wrap {
              * {
                color: $--konai-white;
                white-space: nowrap;
              }
            }
            &[aria-for='section-2'] {
              white-space: nowrap;
            }
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
  }
  &-contents {
    &__inner {
      &.for_did {
        padding: 0;
        & > .flex.vert {
          width: 100%;
          max-width: unset;
          margin: 0;
        }
        .img_wrap {
          .img {
            &.did {
              &-cont {
                &-01 {
                  content: var(--img-did-cont-01);
                  width: 100%;
                  height: auto;
                }
                &-02 {
                  content: var(--img-did-cont-02);
                  width: 100%;
                  height: auto;
                }
                &-03 {
                  content: var(--img-did-cont-03);
                  width: 100%;
                  height: auto;
                }
              }
              &-card {
                &-01 {
                  content: var(--img-did-card-01);
                }
                &-02 {
                  content: var(--img-did-card-02);
                }
                &-03 {
                  content: var(--img-did-card-03);
                }
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-3'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0 0;
            background-color: $--konai-white;
            .text_wrap {
              max-width: 1200rem;
              margin: 0 auto;
              text-align: left;
              & > * {
                text-align: left;
              }
              .main {
                &-subtitle {
                  margin-bottom: 20rem;
                }
                &-text {
                  &:not(:first-child) {
                    marign-top: 50rem;
                  }
                }
              }
            }
            .accordion {
              &-wrap {
                &:not(:first-child) {
                  margin-top: 180rem;
                }
              }
              &-elem {
                & > .flex {
                  max-width: 1200rem;
                  margin: 0 auto;
                }
              }
            }
          }
          &[aria-for='section-4'] {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset;
            margin: 0;
            padding: 180rem 0;
            &:before {
              content: '';
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: -1;
              background-color: $--konai-lightgray-3;
            }
            .block_wrap {
              & > .text_wrap {
                max-width: 1200rem;
                margin: 0 auto;
              }
              .card-wrap {
                margin-top: 114rem;
                margin-bottom: 0;
                .swiper {
                  &-wrapper {
                    justify-content: center;
                  }
                  &-slide {
                    max-width: 276rem;
                  }
                }
              }
            }
          }
        }
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
    }
    .tab-contents {
      &__inner[aria-for*='did_tab_'] {
        .img_wrap {
          .img {
            &.did {
              &-cont {
                &-01 {
                  content: var(--img-did-01);
                }
                &-02 {
                  content: var(--img-did-02);
                }
                &-03 {
                  content: var(--img-did-03);
                }
              }
            }
          }
        }
        .wrap {
          &[aria-for='section-6'] {
            margin: 80rem 0 0 !important;
            padding: 100rem 0;
          }
          &[aria-for='section-7'], &[aria-for='section-8'] {
            margin: 0 !important;
            padding: 100rem 0;
          }
          &.with_img {
            & > .block_wrap {
              display: flex;
              align-items: flex-start;
              width: 100%;
              max-width: 1200rem;
              & > .text_wrap {
                display: inline-block;
                width: 100%;
                text-align: left;
                * {
                  text-align: left;
                  &:nth-child(2){
                    margin-top: 20rem;
                  }
                  &:nth-child(3){
                    margin-top: 30rem;
                  }
                  &:nth-child(4){
                    margin-top: 50rem;
                  }
                }
              }
              & > .img_wrap {
                width: 550rem;
                min-width: 550rem;
                height: 450rem;
                &:first-child {
                  margin-right: 100rem;
                }
                &:last-child {
                  margin-left: 100rem;
                }
                .img {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 12rem;
                }
              }
            }
            &:not(:first-child) {
              margin-top: 200rem;
            }
          }
        }
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
    }
  }
}


// 미디어쿼리 @media
@import './pages/did_media';