// MO SET(SMALL)
@media screen and (max-width: 340px) {
    html {
        font-size: 0.8px;
    }
}

@media screen and (min-width: 341px) and (max-width: 380px) and (min-height: 640px) and (max-height: 669px) {
    .main {
        &-contents {
            .sec-8 {
                &.growth_section {
                    .section_body {
                        .fixed_motion {
                            a.card {
                                scale: 0.8;
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 620px) {
    .main {
        &-contents {
            .sec-8 {
                &.growth_section {
                    .section_body {
                        .fixed_motion {
                            a.card {
                                scale: 0.9;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 341px) and (max-width: 380px) and (min-height: 600px) and (max-height: 639px) {
    .main {
        &-contents {
            .sec-8 {
                &.growth_section {
                    .section_body {
                        .fixed_motion {
                            a.card {
                                scale: 0.6;
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 560px) {
    .main {
        &-contents {
            .sec-8 {
                &.growth_section {
                    .section_body {
                        .fixed_motion {
                            a.card {
                                scale: 0.75;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1100px) and (max-width: 1239px) {
    .main {
        &-contents {
            &__inner {
                .sec-9 {
                    .swiper {
                        &-wrapper {
                            justify-content: center;
                        }
                        &-scrollbar {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// MO SET
@media screen and (max-width: 1239px) {
    .main {
        &-container {
            height: calc(100vh - 60px);
        }
        &-visual__slide-bg {
            top: -60px;
            background-image: var(--01_main_01_01-mo);
            video {
                &.for_pc, &.for_tablet {
                    display: none;
                    //content: '';
                }
                &.for_mo {
                    display: block;
                    //source {
                    //    content: '/uploads/main_01_m_c8ebe65877.mp4'
                    //}
                }
            }
        }
        &-contents {
            &__inner:not(.scrollinner) {
                overflow-x: hidden;
                overflow-y: unset;
            }
            .sec {
                &-1, &-2, &-4, &-5, &-6, &-7, &-9, &-10, &-11 {
                    padding: 0 24px;
                }
                &-1 {
                    padding: 167px 0;
                    height: auto;
                    min-height: 100vh;
                    .for_pc {
                        display: none !important;
                    }
                    .for_mo {
                        display: block;
                    }
                    &__spacing {
                        &-1 {
                            // margin-top: 12px;
                            margin-top: calc(100vh * 0.01875);
                        }
                        &-2 {
                            // margin-top: 22px;
                            margin-top: calc(100vh * 0.034375);
                        }
                        &-3 {
                            // margin-top: 80px;
                            margin-top: calc(100vh * 0.125);
                        }
                    }
                    .img_wrap {
                        width: calc(100% + 80rem);
                        margin-left: -40rem;
                    }
                    &__img {
                        width: 134rem;
                        height: 134rem;
                    }
                    .text_wrap {
                        padding: 0 24px;
                    }
                }
                &-2 {
                    min-height: 100vh;
                    &__spacing {
                        &-1 {
                            // margin-top: 40px;
                            margin-top: calc(100vh * 0.0625);
                        }
                    }
                    .for_pc {
                        display: none;
                    }
                    .for_mo {
                        display: block;
                    }
                }
                &-3 {
                    .event {
                        &.event1 {
                            .image {
                                background-image: var(--img-sec3-1-mo);
                            }
                        }
                        &.event2 {
                            .image {
                                background-image: var(--img-sec3-2-mo);
                            }
                        }
                        .scale-animation, .scale-animation3 {
                            top: 50%;
                            transform: translate(-50%, calc(-150% - 105rem));
                        }
                        .scale-animation2, .scale-animation4 {
                            bottom: 50%;
                            transform: translate(-50%, calc(150% + 100rem));
                            .main-point {
                                &:not(:last-child) {
                                    margin-bottom: 8rem;
                                }
                            }
                        }
                    }
                    .for_pc {
                        display: none;
                    }
                    .for_mo {
                        display: block;
                    }
                }
                &-5 {
                    min-height: 100vh;
                    padding-top: 30px;
                    .table_wrap {
                        padding-top: 50px;
                        padding-bottom: 86px;
                        table {
                            min-width: unset;
                            tr {
                                &:not(:last-child) {
                                    td {
                                        padding-bottom: 76px;
                                    }
                                }
                                td {
                                    max-width: 220px;
                                    &:nth-child(2n-1) {
                                        width: calc(60% - (100vw * 0.027778));
                                        min-width: max(calc(125px + (100vw * 0.027778)), 168rem);
                                        padding-right: calc(100vw * 0.027778);
                                        .title {
                                            min-width: unset;
                                        }
                                    }
                                    &:nth-child(2n) {
                                        width: calc(40% - (100vw * 0.027778));
                                        min-width: max(calc(125px + (100vw * 0.027778)), 168rem);
                                        padding-left: calc(100vw * 0.027778);
                                        .title {
                                            min-width: unset;
                                        }
                                    }
                                }
                            }
                        }
                        .title {
                            font-size: 36rem;
                            line-height: normal;
                            &:not(:last-child) {
                                margin-bottom: 5px;
                            }
                        }
                        .subtitle {
                            font-size: 16rem;
                            line-height: normal;
                            &:not(:last-child) {
                                margin-bottom: 13px;
                            }
                        }
                        .text {
                            font-size: 14rem;
                            line-height: 1.57;
                            letter-spacing: -0.035px;
                            &:not(:last-child) {
                                margin-bottom: 5px;
                            }
                        }
                    }
                    .for_pc {
                        display: none;
                    }
                    .for_mo {
                        display: block;
                    }
                }
                &-6, &-7 {
                    .wrap {
                        //width: calc(100% - 48px);
                        &[aria-for='sec-7'] {
                            padding-top: 0;
                            // padding-bottom: 100px;
                            &::before {
                                width: calc(100% + 48px);
                                margin-left: -24px;
                                background-image: var(--img-sec7-1);
                                background-position: center;
                            }
                        }
                        & > div {
                            margin: auto;
                        }
                    }
                    &__spacing {
                        &-1 {
                            margin-top: 12px !important;
                        }
                        &-2 {
                            margin-top: 20px !important;
                        }
                        &-3 {
                            margin-top: 40px !important;
                        }
                    }
                    .for_pc {
                        display: none;
                    }
                    .for_mo {
                        display: block;
                    }
                }
                &-8 {
                    &__spacing {
                        &-1 {
                            margin-top: 12px !important;
                        }
                        &-2 {
                            margin-top: 22px !important;
                        }
                    }
                    &.growth_section {
                        .for_pc {
                            display: none;
                        }
                        .for_mo {
                            display: block;
                        }
                        .growth-bg-wrap .bg {
                            background-image: var(--img-sec8-1-mo), var(--img-sec8-2-mo);
                            transition: background-image 0.1s ease-in-out;
                        }
                        &.is_active1{
                            .growth-bg-wrap .bg {
                                background-image: var(--img-sec8-1-mo), var(--img-sec8-2-mo);
                                transition: background-image 0.1s ease-in-out;
                            }
                        }
                        &.is_active2{
                            .growth-bg-wrap .bg {
                                background-image: var(--img-sec8-2-mo), var(--img-sec8-3-mo);
                                transition: background-image 0.1s ease-in-out;
                            }
                        }
                        &.is_active3{
                            .growth-bg-wrap .bg {
                                background-image: var(--img-sec8-3-mo), var(--img-sec8-4-mo);
                                transition: background-image 0.1s ease-in-out;
                            }
                        }
                        &.is_active4{
                            .growth-bg-wrap .bg {
                                background-image: var(--img-sec8-4-mo), var(--img-sec8-3-mo);
                                transition: background-image 0.1s ease-in-out;
                            }
                        }
                        .sp_container {
                            .section_wrap {
                                max-width: unset;
                                .section_head {
                                    margin-right: unset;
                                }
                                .section_body {
                                    min-width: unset;
                                }
                            }
                        }
                        .section_wrap {
                            padding-top: 100rem;
                        }
                        .section_head {
                            width: 55%;
                            left: 24rem;
                        }
                        .section_body {
                            width: 40%;
                            .card {
                                // visibility: hidden;
                                opacity: 0;
                                &:not(.is_active) {
                                    pointer-events: none;
                                }
                                &.is_active{
                                    // visibility: visible;
                                    animation: slideInFromLeft 0.5s ease-out forwards;
                                }
                                &:first-of-type {
                                    align-self: flex-start;
                                }
                                &:nth-of-type(2) {
                                    margin-top: 30px;
                                    margin-left: 0;
                                }
                                &:nth-of-type(3) {
                                    margin-top: 30px;
                                }
                                &:nth-of-type(4) {
                                    margin-top: 30px;
                                    margin-left: 0;
                                }
                                &_box {
                                    max-width: min(calc(100vw * 0.672222), 280rem);
                                    min-height: min(324rem, 40vh);
                                    //padding: 30rem;
                                    padding: min(8vw, 30rem);
                                    border-width: 2px;
                                    .inner {
                                        & > div {
                                            .bottom_box {
                                                ul {
                                                    li {
                                                        display: inline-block;
                                                        margin-bottom: 7rem;
                                                        margin-right: 0;
                                                        & > div {
                                                            display: inline-block;
                                                            padding: 0 10rem;
                                                            white-space: nowrap;
                                                        }
                                                        & + li {
                                                            margin-top: 0;
                                                        }
                                                    }
                                                }
                                            }
                                            span {
                                                font-size: 13rem;
                                                font-weight: 400;
                                                line-height: 26rem;
                                                color: $--konai-black;
                                            }
                                            & + div {
                                                // margin-left: 7rem;
                                                margin-left: calc(100vw * 0.018667);
                                            }
                                        }
                                    }
                                }
                                .main {
                                    &-subpoint {
                                        font-size: 12rem;
                                        span {
                                            font-size: 12rem;
                                        }
                                    }
                                    &-title {
                                        font-size: 22rem;
                                        span {
                                            font-size: 22rem;
                                        }
                                    }
                                    &-text {
                                        font-size: 14rem;
                                        span {
                                            font-size: 14rem;
                                        }
                                    }
                                }
                            }
                            .fixed_motion {
                                .card_box {
                                    height: auto !important;
                                    min-height: 342rem;
                                    .inner {
                                        & > div {
                                            display: flex;
                                            flex-direction: column;
                                            .main-text {
                                                &:not(:last-child) {
                                                    margin-bottom: 10rem;
                                                }
                                            }
                                            .bottom_box {
                                                position: relative;
                                                margin-top: auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &-9 {
                    min-height: 100vh;
                    padding: 120px 24px;
                    .for_pc {
                        display: none !important;
                    }
                    .for_mo {
                        display: block;
                        &.flex {
                            display: flex;
                            width: calc(100% + 48px);
                            margin-left: -24px;
                            padding: 0 24px;
                            & > .block_box {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                & > .text {
                                    display: none;
                                    height: 0;
                                    & + .flex {
                                        margin-top: auto;
                                    }
                                }
                            }
                        }
                    }
                    & > .wrap {
                        & > .flex {
                            width: calc(100% + 24px);
                            height: 411rem;
                            //padding-bottom: 16px;
                            overflow-x: auto;
                            -ms-overflow-style: none;
                            &::-webkit-scrollbar {
                                display: none;
                            }
                        }
                    }
                    &__spacing {
                        &-1 {
                            // margin-top: 22px;
                            margin-top: calc(100vh * 0.034375);
                        }
                        &-2 {
                            // margin-top: 74px;
                            margin-top: calc(100vh * 0.115625);
                        }
                        &-3 {
                            // margin-top: 64px;
                            margin-top: calc(100vh * 0.1);
                        }
                        &-4 {
                            // margin-top: 150px;
                            margin-top: calc(100vh * 0.138889);
                        }
                    }
                    .swiper {
                        width: calc(100% + 48px);
                        margin-left: -24px;
                        //padding: 0 24px 16px 0;
                        padding: 0 0 16px;
                        user-select: none;
                        &-container {
                            padding: 0;
                            width: 100%;
                        }
                        &-wrapper {
                            box-sizing: unset;
                            padding: 0 24px;
                        }
                        &-scrollbar {
                            display: none;
                        }
                        &-slide {
                            &:last-child {
                                margin-right: 0 !important;
                            }
                        }
                    }
                    .block_box, .swiper-slide {
                        // min-width: 280px;
                        width: min(calc(100vw * 0.746667), 280px);
                        min-width: min(calc(100vw * 0.746667), 280px);
                        max-width: min(calc(100vw * 0.746667), 280px);
                        max-height: 411px;
                        // margin-right: 22px;
                        margin-right: min(calc(100vw * 0.061111), 32px);
                        background-image: unset !important;
                        &:hover {
                            background-image: unset !important;
                            background-color: inherit !important;
                            &.red {
                                background-color: $--konai-red !important;
                            }
                            &.black {
                                background-color: $--konai-black !important;
                            }
                            &.lightgray {
                                background-color: $--konai-lightgray-1 !important;
                                .title, .text, .date {
                                    color: $--konai-black !important;
                                }
                            }
                        }
                        &::before {
                            display: none;
                        }
                        .title {
                            height: 112px;
                            font-size: 20px;
                            line-height: 1.4;
                        }
                        .text {
                            height: 110px;
                            -webkit-line-clamp: 5;
                            font-size: 14px;
                            line-height: 1.57;
                            letter-spacing: -0.035px;
                        }
                        .date {
                            font-size: 16px;
                            line-height: 1.25;
                        }
                    }
                    .ir_wrap {
                        padding: 20px;
                        & > .flex {
                            flex-direction: column;
                            .flex {
                                &.vert {
                                    min-width: 76px;
                                    &:not(:first-child) {
                                        margin-left: auto;
                                    }
                                }
                            }
                            .end {
                                justify-content: center;
                                margin-top: 30px;
                            }
                        }
                        .title {
                            font-size: 26rem;
                            line-height: 36px;
                            &:not(.ir) {
                                width: 100%;
                            }
                            &.ir {
                                width: auto;
                                min-width: 120rem;
                                padding-left: 28px;
                                white-space: nowrap;
                                text-align: right;
                                &.f-clr-increase {
                                    &::before {
                                        width: 20px;
                                        height: 20px;
                                        border-top: calc(10px * 1.732) solid transparent;
                                        border-left: 10px solid transparent;
                                        border-right: 10px solid transparent;
                                        border-bottom: calc(10px * 1.732) solid transparent;
                                        border-bottom-color: $--konai-increase;
                                        transform: translate(calc(-100% - 6px), -7px);
                                    }
                                }
                                &.f-clr-decrease {
                                    &::before {
                                        width: 20px;
                                        height: 20px;
                                        border-top: calc(10px * 1.732) solid transparent;
                                        border-top-color: $--konai-decrease;
                                        border-left: 10px solid transparent;
                                        border-right: 10px solid transparent;
                                        border-bottom: calc(10px * 1.732) solid transparent;
                                        transform: translate(calc(-100% - 6px), 10px);
                                    }
                                }
                            }
                        }
                        .dt {
                            font-size: 14rem;
                            text-align: left;
                            &:not(:last-child) {
                                margin-bottom: 4px;
                            }
                        }
                        .dd {
                            font-size: 16rem;
                            text-align: left;
                            &.ir {
                                &.f-clr-increase {
                                    &::before {
                                        width: 12px;
                                        height: 12px;
                                        border-top: calc(6px * 1.732) solid rgba(0,0,0,0);
                                        border-left: 6px solid rgba(0,0,0,0);
                                        border-right: 6px solid rgba(0,0,0,0);
                                        border-bottom: calc(6px * 1.732) solid rgba(0,0,0,0);
                                        border-bottom-color: $--konai-increase;
                                        transform: translate(calc(-100% - 3px), 0);
                                    }
                                }
                                &.f-clr-decrease {
                                    &::before {
                                        width: 12px;
                                        height: 12px;
                                        border-top: calc(6px * 1.732) solid rgba(0,0,0,0);
                                        border-top-color: $--konai-decrease;
                                        border-left: 6px solid rgba(0,0,0,0);
                                        border-right: 6px solid rgba(0,0,0,0);
                                        border-bottom: calc(6px * 1.732) solid rgba(0,0,0,0);
                                        transform: translate(calc(-100% - 3px), 8px);
                                    }
                                }
                            }
                        }
                    }
                }
                &-10 {
                    min-height: 100vh;
                    padding: 120px 24px;
                    .for_pc {
                        display: none !important;
                    }
                    .for_mo {
                        display: block;
                    }
                    & > .wrap {
                        max-width: unset;
                    }
                    .main-text {
                        word-break: keep-all;
                    }
                    .block_box {
                        padding: 20px;
                        & + .block_box {
                            margin: 20px 0 0;
                        }
                        .subtext {
                            font-size: 12rem;
                            line-height: normal;
                            &:not(:last-child) {
                                margin-bottom: 8px;
                            }
                        }
                        .subtitle {
                            font-size: 17rem;
                            line-height: 1.29;
                            word-break: keep-all;
                            &:not(:last-child) {
                                margin-bottom: 17px;
                            }
                        }
                        .img_wrap {
                            // margin-left: 16px;
                            margin-left: calc(100vw * 0.044444);
                            img {
                                &.konadaum-1 {
                                    height: 75rem;
                                }
                                &.konadaum-2 {
                                    height: 67rem;
                                }
                            }
                        }
                    }
                    &__spacing {
                        &-1 {
                            // margin-top: 22px;
                            margin-top: calc(100vh * 0.034375);
                        }
                        &-2 {
                            // margin-top: 74px;
                            margin-top: calc(100vh * 0.115625);
                        }
                        &-3 {
                            // margin-top: 70px;
                            margin-top: calc(100vh * 0.109375);
                            min-width: unset;
                            flex-direction: column;
                            justify-content: space-between;
                            .block_box {
                                width: 100%;
                            }
                        }
                    }
                }
                &-11 {
                    min-height: 100vh;
                    padding: 120px 24px;
                    .partner_wrap {
                        & > .flex {
                            padding: 20px 0;
                            li {
                                padding: 0;
                                margin-bottom: 14px;
                                flex: 1 1 30%;
                            }
                        }
                        .partner_img {
                            max-width: 100rem;
                            max-height: 60rem;
                        }
                    }
                    &__spacing {
                        &-1 {
                            // margin-top: 20px;
                            margin-top: calc(100vh * 0.018519);
                        }
                        &-2 {
                            // margin-top: 118px;
                            margin-top: calc(100vh * 0.109259);
                        }
                    }
                }
            }
        }
        &-contacts {
            padding: 0;
            & > .flex {
                flex-direction: column;
                padding: 22px 24px;
                & > .flex, & > .btn {
                    display: flex;
                    width: 100%;
                    min-width: 200px;
                    margin: 0;
                    & > img {
                        width: 60rem;
                        height: 60rem;
                    }
                }
                & > .btn {
                    width: auto;
                    margin: 0 auto;
                }
            }
            .text_wrap {
                &:not(:first-child) {
                    margin-left: 20px;
                }
                .title {
                    font-size: 18rem;
                    text-align: left;
                }
                .text {
                    font-size: 12rem;
                    line-height: 1.5;
                    text-align: left;
                }
            }
            .flex-bar {
                width: 100%;
                min-width: unset;
                height: 1px;
                min-height: 1px;
                margin: 22px 0;
                background-color: $--konai-lightgray-1;
            }
        }
    }
}

// PC SET(SMALL)
@media screen and (min-width: 1240px) and (max-width: 1359px) {
    .main {
        &-visual__slide-bg {
            top: -80px;
        }
        &-contents {
            .sec {
                &-8 {
                    &.growth_section {
                        .section_head {
                            width: 40%;
                        }
                        .section_body {
                            width: 55%;
                            .card {
                                opacity: 1;
                                &:nth-of-type(2) {
                                    margin-top: 250px;
                                    margin-left: 20px;
                                }
                                &:nth-of-type(3) {
                                    margin-top: -220px;
                                }
                                &:nth-of-type(4) {
                                    margin-left: 20px;
                                }
                                &_box {
                                    padding: 24rem;
                                    .inner {
                                        & > div {
                                            span {
                                                font-size: 14rem;
                                                line-height: 28rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// TABLET SET
@media screen and (min-width: 799px) and (max-width: 1239px) {
    html {
        font-size: 1.1px;
    }
    .main {
        &-visual__slide-bg {
            top: -60px;
            background-image: var(--01_main_01_01);
            video {
                &.for_pc, &.for_tablet {
                    display: block !important;
                    //source {
                    //    content: '/uploads/main_01_e4bcabfa05.mp4'
                    //}
                }
                &.for_mo {
                    display: none !important;
                    //content: '';
                }
            }
        }
        &-contents {
            .sec {
                &-8 {
                    &.growth_section {
                        .section_head {
                            width: 30%;
                        }
                        .section_body {
                            width: 65%;
                            .card {
                                opacity: 1;
                                &:nth-of-type(2) {
                                    margin-top: 250px;
                                    margin-left: 20px;
                                }
                                &:nth-of-type(3) {
                                    margin-top: -220px;
                                }
                                &:nth-of-type(4) {
                                    margin-left: 20px;
                                }
                                &_box {
                                    padding: 20rem;
                                    .inner {
                                        & > div {
                                            span {
                                                font-size: 11rem;
                                                line-height: 22rem;
                                            }
                                        }
                                        .bottom_box {
                                            ul {
                                                li {
                                                    &:not(:first-child) {
                                                        & > div {
                                                            margin-left: min(0.5208vw, 10rem);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &-11 {
                    .partner_wrap {
                        & > .flex{
                            li {
                                flex: 1 1 18% !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
