.scrollmagic-pin-spacer {
  &:nth-child(2), &:nth-child(3) {
    .scrollinner.for_universe {
      inset: 0 auto auto !important;
    }
  }
  &:nth-child(4) {
    .scrollinner.for_universe {
      //inset: 80px auto auto !important;
    }
  }
}
.frame {
  &-container {
    &#fixedScroll_1 {
      //height: 100% !important;
      &.for_universe {
        min-height: calc(100vh + 80px);
        height: 100vh !important;
        .frame-container__inner {
          .wrap {
            &[aria-for="section-1"] {
              .main-point.fs-80 {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
    &__inner {
      .wrap {
        &[aria-for="section-1"] {
          //height: 100%;
          //background-color: $--konai-black;
        }
        &.hidden {
          display: none !important;
        }
      }
    }
    &.for_universe {
      .frame-container-bg {
        height: 100%;
        //top: 0;
        //top: -80px;
        background-color: $--konai-black;
        &.section_3.type_1 {
          position: absolute;
          height: 100vh;
        }
        &.active {
          &.section_3.type_1 {
            position: sticky;
          }
          &:before {
            opacity: 1;
            transition: opacity 0.65s ease-in-out;
          }
        }
        &.fixed {
          position: fixed;
          top: 0;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 0;
          opacity: 0;
          transition: opacity 0.65s ease-in-out;
        }
        &.section {
          &_1 {
            &.type {
              &_1 {
                &:before {
                  background-image: var(--img-universe-bg01-01);
                  transition: all 0.3s ease-in-out;
                }
              }
              &_2 {
                &:before {
                  background-image: var(--img-universe-bg01-02);
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
          &_3 {
            &.type {
              &_1 {
                &:before {
                  opacity: 0;
                  background-image: var(--img-universe-earth2);
                  transition: all 0.3s ease-in-out;
                }
                &.active {
                  &:before {
                    opacity: 1;
                    transition: all 0.3s ease-in-out;
                  }
                }
              }
            }
          }
        }
      }
      &#fixedScroll_3 {
        .frame-container-bg {
          top: 0;
        }
      }
      div[class*='sec-'] {
        padding: 0;
        background-color: transparent;
      }
      &.scrollinner {
        height: 100%;
        top: -80px;
        .flex {
          .wrap {
            position: absolute;
            display: block;
            width: auto;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .text_wrap {
              * {
                color: $--konai-white;
              }
              .main {
                &-point {
                  &:not(:last-child) {
                    margin-bottom: 20rem;
                  }
                }
              }
            }
            .img_wrap {
              position: relative;
              .img {
                &.universe {
                  position: relative;
                  width: 100%;
                  height: 100%;
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                  &.bg {
                    position: relative;
                    display: flex;
                    width: 533rem;
                    height: 387rem;
                    //margin: auto;
                    margin: 0 auto -12rem;
                    background-image: var(--img-universe-earth);
                    .img_text {
                      position: relative;
                      margin: auto;
                      font-family: 'Konai', sans-serif;
                      font-size: 15rem;
                      font-weight: 400;
                      line-height: normal;
                      color: $--konai-red;
                    }
                  }
                  &.diagram {
                    position: absolute;
                    width: 960rem;
                    height: 960rem;
                    margin: auto;
                    background-image: var(--img-universe-diagram);
                    transform: translate(-50%, -50%);
                  }
                  &.line {
                    &-1 {
                      position: absolute;
                      width: 1000rem;
                      height: 61rem;
                      top: 84rem;
                      margin: 0 auto;
                      background-image: var(--img-universe-line-01);
                    }
                    &-2 {
                      position: absolute;
                      width: 840rem;
                      height: 186rem;
                      top: 94rem;
                      margin: 0 auto;
                      background-image: var(--img-universe-line-02);
                    }
                    &-3 {
                      position: absolute;
                      width: 745rem;
                      height: 228rem;
                      top: 204rem;
                      margin: 0 auto;
                      background-image: var(--img-universe-line-03);
                    }
                    &-4 {
                      position: absolute;
                      width: 650rem;
                      height: 275rem;
                      top: 279rem;
                      margin: 0 auto;
                      background-image: var(--img-universe-line-04);
                    }
                    &-5 {
                      align-self: center;
                      position: absolute;
                      width: 246rem;
                      height: 246rem;
                      margin: auto;
                      background-image: var(--img-universe-line-05);
                      transform: translate(-50%, -50%);
                    }
                    &-6 {
                      align-self: center;
                      position: absolute;
                      width: 482rem;
                      height: 482rem;
                      margin: auto;
                      background-image: var(--img-universe-line-06);
                      transform: translate(-50%, calc(-50% + 3rem));
                    }
                    &-7 {
                      align-self: center;
                      position: absolute;
                      width: 722rem;
                      height: 722rem;
                      margin: auto;
                      background-image: var(--img-universe-line-07);
                      transform: translate(-50%, calc(-50% + 3rem));
                    }
                    &-8 {
                      align-self: center;
                      position: absolute;
                      width: 964rem;
                      height: 964rem;
                      margin: auto;
                      background-image: var(--img-universe-line-08);
                      transform: translate(-50%, -50%);
                    }
                  }
                  &.rotate {
                    &-plate {
                      position: absolute;
                      //width: 1595rem;
                      width: 83vw;
                      max-width: 1595rem;
                      //height: 1595rem;
                      height: 83vw;
                      max-height: 1595rem;
                      margin: auto;
                      background-image: var(--img-universe-rotate-plate);
                      background-position: center;
                      //background-position: center;
                      z-index: -1;
                      transform: rotate(0deg);
                      transition: transform 0.3s ease-in-out;
                      //.section_wrap {
                      //  position: absolute;
                      //  display: block;
                      //  width: inherit;
                      //  height: inherit;
                      //  top: 50%;
                      //  left: 50%;
                      //  transform: rotate(0deg) translate(-50%, -50%);
                      //  transform-origin: 0 0;
                      //  transition: transform 0.3s ease-in-out;
                      //  div[class^='section'] {
                      //    position: absolute;
                      //    display: inline-block;
                      //    width: 100%;
                      //    height: 50%;
                      //    .line {
                      //      &-1 {
                      //        .section-text {
                      //          position: absolute;
                      //          display: inline-block;
                      //          left: 50%;
                      //          font-size: min(27rem, 1.40625vw);
                      //          font-weight: bold;
                      //          line-height: 1.25;
                      //          letter-spacing: normal;
                      //          color: $--konai-white;
                      //          transform: translate(-50%, 235rem);
                      //        }
                      //      }
                      //      &-2 {
                      //        .section-text {
                      //          position: absolute;
                      //          display: inline-block;
                      //          left: 50%;
                      //          font-size: min(24rem, 1.25vw);
                      //          font-weight: bold;
                      //          line-height: 1.25;
                      //          letter-spacing: normal;
                      //          color: $--konai-white;
                      //          transform: translate(-50%, 66rem);
                      //        }
                      //      }
                      //      &-3 {
                      //        .section-text {
                      //          position: absolute;
                      //          display: inline-block;
                      //          left: 50%;
                      //          font-size: min(21rem, 1.09375vw);
                      //          font-weight: 400;
                      //          line-height: 1.25;
                      //          letter-spacing: normal;
                      //          color: $--konai-white;
                      //          transform: translate(-50%, -105rem);
                      //        }
                      //      }
                      //      &-4 {
                      //        .section-text {
                      //          position: absolute;
                      //          display: inline-block;
                      //          left: 50%;
                      //          font-size: min(21rem, 1.09375vw);
                      //          font-weight: 300;
                      //          line-height: 1.25;
                      //          letter-spacing: normal;
                      //          color: $--konai-gray-4;
                      //          transform: translate(-50%, -290rem);
                      //        }
                      //      }
                      //    }
                      //    &.section {
                      //      &-digital {
                      //        top: 50%;
                      //        left: 50%;
                      //        transform: rotate(0deg) translate(-50%, -50%);
                      //        transform-origin: 0 0;
                      //        .line {
                      //          &-1 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-50%, 235rem);
                      //              }
                      //            }
                      //          }
                      //          &-2 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-185%, 85rem);
                      //              }
                      //              &:nth-child(2) {
                      //                transform: translate(75%, 85rem);
                      //              }
                      //            }
                      //          }
                      //          &-3 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-625%, -45rem);
                      //              }
                      //              &:nth-child(2) {
                      //                transform: translate(-50%, -95rem);
                      //              }
                      //              &:nth-child(3) {
                      //                transform: translate(275%, -45rem);
                      //              }
                      //            }
                      //          }
                      //          &-4 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-515%, -195rem);
                      //              }
                      //              &:nth-child(2) {
                      //                transform: translate(-260%, -260rem);
                      //              }
                      //              &:nth-child(3) {
                      //                transform: translate(-50%, -290rem);
                      //              }
                      //              &:nth-child(4) {
                      //                transform: translate(115%, -260rem);
                      //              }
                      //              &:nth-child(5) {
                      //                transform: translate(560%, -195rem);
                      //              }
                      //            }
                      //          }
                      //        }
                      //      }
                      //      &-pay {
                      //        top: 50%;
                      //        left: 50%;
                      //        transform: rotate(90deg) translate(-50%, -50%);
                      //        transform-origin: 0 0;
                      //        .line {
                      //          &-1 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-50%, 235rem);
                      //              }
                      //            }
                      //          }
                      //          &-2 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-50%, 66rem);
                      //              }
                      //            }
                      //          }
                      //          &-3 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-400%, -10rem);
                      //              }
                      //              &:nth-child(2) {
                      //                transform: translate(-185%, -75rem);
                      //              }
                      //              &:nth-child(3) {
                      //                transform: translate(45%, -75rem);
                      //              }
                      //              &:nth-child(4) {
                      //                transform: translate(185%, -10rem);
                      //              }
                      //            }
                      //          }
                      //          &-4 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-600%, -145rem);
                      //              }
                      //              &:nth-child(2) {
                      //                transform: translate(-445%, -215rem);
                      //              }
                      //              &:nth-child(3) {
                      //                transform: translate(-260%, -260rem);
                      //              }
                      //              &:nth-child(4) {
                      //                transform: translate(-50%, -290rem);
                      //              }
                      //              &:nth-child(5) {
                      //                transform: translate(165%, -260rem);
                      //              }
                      //              &:nth-child(6) {
                      //                transform: translate(750%, -215rem);
                      //              }
                      //              &:nth-child(7) {
                      //                transform: translate(480%, -145rem);
                      //              }
                      //            }
                      //          }
                      //        }
                      //      }
                      //      &-mobility {
                      //        top: 50%;
                      //        left: 50%;
                      //        transform: rotate(180deg) translate(-50%, -50%);
                      //        transform-origin: 0 0;
                      //        .line {
                      //          &-1 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-50%, 235rem);
                      //              }
                      //            }
                      //          }
                      //          &-2 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-50%, 66rem);
                      //              }
                      //            }
                      //          }
                      //          &-3 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-50%, -105rem);
                      //              }
                      //              &:nth-child(2) {
                      //                transform: translate(-50%, -105rem);
                      //              }
                      //              &:nth-child(3) {
                      //                transform: translate(-50%, -105rem);
                      //              }
                      //            }
                      //          }
                      //          &-4 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-50%, -290rem);
                      //              }
                      //              &:nth-child(2) {
                      //                transform: translate(-50%, -290rem);
                      //              }
                      //              &:nth-child(3) {
                      //                transform: translate(-50%, -290rem);
                      //              }
                      //            }
                      //          }
                      //        }
                      //      }
                      //      &-blockchain {
                      //        top: 50%;
                      //        left: 50%;
                      //        transform: rotate(270deg) translate(-50%, -50%);
                      //        transform-origin: 0 0;
                      //        .line {
                      //          &-1 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-50%, 235rem);
                      //              }
                      //            }
                      //          }
                      //          &-2 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-50%, 66rem);
                      //              }
                      //            }
                      //          }
                      //          &-3 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-50%, -105rem);
                      //              }
                      //            }
                      //          }
                      //          &-4 {
                      //            .section-text {
                      //              &:nth-child(1) {
                      //                transform: translate(-50%, -290rem);
                      //              }
                      //            }
                      //          }
                      //        }
                      //      }
                      //    }
                      //  }
                      //}
                    }
                    &-front {
                      position: relative;
                      display: block;
                      //width: 1595rem;
                      width: 83vw;
                      max-width: 1595rem;
                      //height: 1595rem;
                      height: 83vw;
                      max-height: 1595rem;
                      margin: auto;
                      background-image: var(--img-universe-rotate-front);
                      z-index: 0;
                    }
                  }
                }
              }
            }
            &[aria-for='section-3'] {
              .text_wrap {
                white-space: nowrap;
              }
            }
            &[aria-for='section-4'] {
              .circle-wrap {
                margin-top: 160rem;
              }
              .text_wrap {
                .main-point.fs-56 {
                  margin-bottom: 50rem;
                }
              }
            }
            &[aria-for='section-5'] {
              .img_wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                height: auto;
                min-height: 560rem;
                .universe.bg {
                  margin: 84rem auto 0;
                }
              }
              .text_wrap {
                .main-point.fs-56 {
                  margin-bottom: 50rem;
                }
              }
            }
            &[aria-for='section-11'], &[aria-for='section-12'] {
              .text_wrap {
                transform: translate(0, 54rem);
              }
            }
            &[aria-for='section-13'] {
              //margin-top: -80px;
              margin-top: 0;
              overflow-x: hidden;
              .img_wrap {
                display: block;
                width: 100%;
                height: 100%;
                //max-height: 800rem;
                max-height: 74.5vh;
                overflow: hidden;
                pointer-events: none;
              }
              .text_wrap {
                transform: translate(0, 110rem);
                * {
                  text-align: left;
                }
                .list-wrap {
                  display: block;
                  width: auto;
                  max-width: 300rem;
                  height: auto;
                  &:not(:first-child) {
                    margin-top: 50rem;
                  }
                  .list_elem {
                    display: inline-flex;
                    align-items: center;
                    width: 100%;
                    height: auto;
                    font-size: 20rem;
                    font-weight: 500;
                    line-height: 1.33;
                    span {
                      color: $--konai-gray-1;
                    }
                    &:not(:first-child) {
                      margin-top: 12rem;
                    }
                    &:before {
                      content: '';
                      display: inline-block;
                      width: 15rem;
                      height: 15rem;
                      margin-right: 18rem;
                      border-radius: 50%;
                      background-color: $--konai-gray-1;
                    }
                    &.active {
                      span {
                        font-weight: 800;
                        color: $--konai-red;
                        transition: all 0.5s ease-in-out;
                      }
                      &:before {
                        background-color: $--konai-red;
                        transition: all 0.5s ease-in-out;
                      }
                    }
                  }
                }
              }
            }
            &[aria-for='section-14'] {
              //margin-top: -60px;
              .text_wrap {
                .main {
                  &-subpoint {
                    &:not(:last-child) {
                      margin-bottom: 20rem;
                    }
                  }
                  &-title {
                    font-family: "Konai", sans-serif;
                    font-weight: 400;
                    letter-spacing: normal;
                    &:not(:last-child) {
                      margin-bottom: 50rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .img_wrap {
        &.section {
          &_4 {
            .img {

            }
          }
        }
      }
      & + .frame-contents, & ~ .footer {
        position: relative;
        z-index: 5;
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
    &.for_tech {
      .frame-container-bg {
        background-image: var(--img-tech-bg01-01);
      }
      .frame-container-text {
        margin-top: -40px;
      }
      & + .frame-contents {
        .frame-contents__inner {
          overflow-x: hidden;
          &:nth-child(2n-1), &:nth-child(2n) {
            background-color: $--konai-black;
            .main {
              &-title, &-mdtitle, &-smtitle, &-text {
                color: $--konai-white;
              }
            }
          }
          &:first-child {
            //padding: 300rem 200px;
            padding: 300rem calc(100vw * 0.104167);
          }
          &[class*='sec-'] {
            min-height: 1000px;
            padding: 0;
            background-color: $--konai-black;
            & > * {
              position: relative;
              align-items: unset;
              &.block_wrap {
                width: 100%;
                max-width: 1200rem;
                margin: auto;
                .text_wrap {
                  .main {
                    &-smtitle {
                      text-align: left;
                      &:not(:last-child) {
                        margin-bottom: 20rem;
                      }
                    }
                    &-title {
                      text-align: left;
                      &:not(:last-child) {
                        margin-bottom: 50rem;
                      }
                    }
                  }
                }
              }
              &:first-child:last-child {
                width: 100%;
                margin: auto;
              }
            }
            .frame-contents__inner-bg {
              position: absolute;
              display: block;
              width: 100%;
              height: 100%;
              min-height: 1000px;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              z-index: 0;
            }
          }
          &.sec {
            &-1 {
              & > * {
                &.frame-contents__inner-bg {
                  background-image: var(--img-tech-01);
                }
                .text_wrap {
                  width: auto;
                  min-width: 410rem;
                  max-width: 410rem;
                  margin: 0 0 0 auto;
                }
              }
            }
            &-2 {
              & > * {
                &.frame-contents__inner-bg {
                  background-image: var(--img-tech-02);
                }
                .text_wrap {
                  width: auto;
                  min-width: 410rem;
                  max-width: 410rem;
                  margin: 0 auto 0 0;
                }
              }
            }
            &-3 {
              & > * {
                &.frame-contents__inner-bg {
                  background-image: var(--img-tech-03);
                }
                .text_wrap {
                  width: auto;
                  min-width: 410rem;
                  max-width: 410rem;
                  margin: 0 0 0 auto;
                  transform: translate(0, 100rem);
                }
              }
            }
            &-4 {
              margin-top: -200rem;
              & > * {
                &.frame-contents__inner-bg {
                  background-image: var(--img-tech-04);
                }
                .text_wrap {
                  width: auto;
                  min-width: 410rem;
                  max-width: 410rem;
                  margin: 0 auto 0 0;
                }
              }
            }
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
    &.for_card {
      .frame-container-bg {
        background-image: var(--img-card-bg01-01);
      }
      .frame-container-text {
        margin-top: -40px;
      }
      & + .frame-contents {
        .frame-contents__inner {
          & > .block_wrap {
            align-items: center;
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
      & ~ .frame-contents {
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
    }
    &.for_emv {
      .frame-container-bg {
        background-image: var(--img-emv-bg01-01);
      }
      .frame-container-text {
        margin-top: -40px;
      }
      & + .frame-contents {
        .frame-contents__inner {
          & > .block_wrap {
            align-items: center;
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
      & ~ .frame-contents {
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
    }
    &.for_ota {
      .frame-container-bg {
        background-image: var(--img-ota-bg01-01);
      }
      .frame-container-text {
        margin-top: -40px;
      }
      & + .frame-contents {
        .frame-contents__inner {
          & > .block_wrap {
            align-items: center;
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
      & ~ .frame-contents {
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
    }
    &.for_blockchain {
      .frame-container-bg {
        background-image: var(--img-blockchain-bg01-01);
      }
      .frame-container-text {
        margin-top: -40px;
      }
      & + .frame-contents {
        .frame-contents__inner {
          & > .block_wrap {
            align-items: center;
          }
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
      & ~ .frame-contents {
        .for_pc {
          display: block;
        }
        .for_mo {
          display: none;
        }
      }
    }
    &.hidden {
      display: none;
    }
  }
  &-contents {
    &__inner {
      div[class*='sec-'] {
        position: unset;
        padding: 0;
        background-color: transparent;
      }
      &.with_img {
        .text_wrap {
          .main {
            &-mdtitle {
              font-weight: 800;
              text-align: left;
              &:not(:last-child) {
                margin-bottom: 20rem;
              }
            }
            &-text {
              text-align: left;
              &:not(:last-child) {
                margin-bottom: 50rem;
              }
            }
          }
        }
        .contents-img {
          &.hidden {
            opacity: 0;
            pointer-events: none;
          }
          &.tech {
            &-01 {
              content: var(--img-tech-01);
            }
            &-02 {
              content: var(--img-tech-02);
            }
            &-03 {
              content: var(--img-tech-03);
            }
            &-04 {
              content: var(--img-tech-04);
            }
          }
          &.card {
            &-01 {
              content: var(--img-card-01);
            }
            &-02 {
              content: var(--img-card-02);
            }
            &-03 {
              content: var(--img-card-03);
            }
          }
          &.emv {
            &-01 {
              content: var(--img-emv-01);
            }
            &-02 {
              content: var(--img-emv-02);
            }
            &-03 {
              content: var(--img-emv-03);
            }
          }
          &.ota {
            &-01 {
              content: var(--img-ota-01);
            }
            &-02 {
              content: var(--img-ota-02);
            }
            &-03 {
              content: var(--img-ota-03);
            }
          }
          &.blockchain {
            &-01 {
              content: var(--img-blockchain-01);
            }
            &-02 {
              content: var(--img-blockchain-02);
            }
            &-03 {
              content: var(--img-blockchain-03);
            }
          }
        }
      }
    }
  }
}

// 미디어쿼리 @media
@import './pages/universe_media';