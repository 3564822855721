.header {
  &.fixed {
    & + .frame-contents {
      padding-top: 80px;
    }
  }
}

.frame {
  &-contents {
    &.for_inquiry {
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
      .flex {
        height: auto;
        & > .block_wrap {
          max-width: 792rem;
          margin: 180rem auto;
        }
        &.spaceBtw {
          margin-top: 50rem;
        }
        &.center {
          & > .btn {
            &:not(:last-child) {
              margin-right: 25rem;
            }
            &:not(:first-child) {
              margin-left: 25rem;
            }
          }
        }
      }
      .block {
        &_wrap {
          & > .img_wrap {
            width: 100%;
            height: auto;
            text-align: center;
            &:not(:last-child) {
              margin-bottom: 100rem;
              & + .main-mdtitle {
                &:not(:last-child) {
                  margin-bottom: 50rem;
                }
              }
            }
            .img {
              max-width: 140rem;
              max-height: 118rem;
              object-fit: contain;
              &.alert {
                content: var(--icon-alert);
              }
            }
            & ~ *:last-child {
              margin-bottom: 180rem;
            }
          }
          .main {
            &-title {
              margin-bottom: 50rem;
            }
            &-mdtitle {
              margin-bottom: 100rem;
            }
            &-text {
              margin-bottom: 100rem;
            }
          }
          & > .flex.spaceBtw {
            align-items: center;
          }
        }
        &_inquiry {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: auto;
          .btn-text {
            line-height: 1;
          }
        }
        &_elem {
          flex: 1 1 40%;
          display: block;
          width: 100%;
          height: auto;
          &:not(:nth-child(1)):not(:nth-child(2)) {
            margin-top: 50rem;
          }
          &:nth-child(2n-1):not(:nth-last-child(1)):not(:nth-last-child(2)) {
            margin-right: 25rem;
          }
          &:nth-child(2n):not(:nth-last-child(1)):not(:nth-last-child(2)) {
            margin-left: 25rem;
          }
          &:nth-last-child(1), &:nth-last-child(2) {
            flex-basis: 100%;
          }
          &.error, .error {
            & ~.error-text, .error-text {
              display: block;
            }
          }
          .error-text {
            display: none;
            width: 100%;
            height: auto;
            margin-top: 10rem;
            font-size: 16rem;
            font-weight: 400;
            //line-height: 1.63;
            line-height: 1;
            color: $--konai-red;
          }
        }
        &_label {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 10rem;
          font-size: 16rem;
          font-weight: 400;
          //line-height: 1.63;
          line-height: 1;
          color: $--konai-black;
        }
        &_input, &_textarea {
          display: block;
          width: 100%;
          height: auto;
          max-height: 48rem;
          padding: 15rem 20rem;
          border-radius: 4rem;
          font-size: 16rem;
          font-weight: 400;
          //line-height: 1.63;
          line-height: 1;
          color: $--konai-black;
          border: 1px solid $--konai-gray-4;
          outline: 0;
        }
        &_textarea {
          display: block;
          min-height: 277rem;
          resize: none;
        }
        &_check {
          position: relative;
          display: none;
          pointer-events: none;
          box-shadow: none;
          &:checked {
            & + label {
              &:before {
                background-image: var(--icon-chk-box-on);
              }
            }
          }
          & + label {
            position: relative;
            display: flex;
            align-items: center;
            width: auto;
            height: auto;
            font-size: 16rem;
            font-weight: 400;
            line-height: 1.63;
            color: $--konai-black;
            pointer-events: auto;
            cursor: pointer;
            &:before {
              content: '';
              display: inline-block;
              width: 16rem;
              min-width: 16rem;
              height: 16rem;
              margin: 0 10rem 0 0;
              box-shadow: none;
              background-image: var(--icon-chk-box);
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              pointer-events: auto;
              cursor: pointer;
              transition: all 0.15s ease-in-out;
            }
          }
          &-wrap {
            text-align: left;
          }
        }
      }
      .btn.submit {
        background-color: $--konai-red;
        color: $--konai-white;
        font-weight: 400;
        pointer-events: auto;
        cursor: pointer;
        &.disabled {
          background-color: $--konai-gray-4;
          pointer-events: none;
          cursor: default;
        }
      }
    }
  }
}

// 미디어쿼리 @media
@import './pages/inquiry_media';