.frame {
  &-container {
    &.for_ir {
      .frame-container-bg {
        background-image: var(--img-ir-bg01-01);
      }
      .frame-container-text {
        margin-top: -40px;
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
    &.for_pr {
      .frame-container-bg {
        background-image: var(--img-pr-bg01-01);
      }
      .frame-container-text {
        margin-top: -40px;
      }
      .tab {
        &-title {
          font-size: 48rem;
        }
      }
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
  }
}

.flex {
  &:not(.vert) {
    & > .table-wrap {
      width: 64%;
      .table-elem {
        width: 100%;
        margin-right: 0;
      }
      & + .table-wrap {
        width: 36%;
        margin-top: 0;
        margin-left: 24rem;
        .table-elem {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}

.table {
  &-elem {
    tr {
      td {
        &.larger {
          font-size: 17rem;
          font-weight: 600;
          line-height: 2.11;
        }
      }
    }
    .ir {
      .f-clr-standby {
        padding-left: 17px;
        transform: translate(16rem, 0);
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 12px;
          height: 12px;
          border-top: calc(6px * 1.732) solid transparent;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: calc(6px * 1.732) solid transparent;
          border-bottom-color: transparent;
          transform: translate(calc(-100% - 5px), -6px);
        }
      }
      .f-clr-increase {
        padding-left: 17px;
        transform: translate(16rem, 0);
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 12px;
          height: 12px;
          border-top: calc(6px * 1.732) solid transparent;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: calc(6px * 1.732) solid transparent;
          border-bottom-color: $--konai-increase;
          transform: translate(calc(-100% - 5px), -6px);
        }
      }
      .f-clr-decrease {
        padding-left: 17px;
        transform: translate(16rem, 0);
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 12px;
          height: 12px;
          border-top: calc(6px * 1.732) solid transparent;
          border-top-color: $--konai-decrease;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: calc(6px * 1.732) solid transparent;
          transform: translate(calc(-100% - 5px), 4px);
        }
      }
    }
  }
}

.juga {
  &.table-title {
    display: flex;
    margin: 0 0 10rem;
    & + .flex {
      margin-top: 24rem;
      .for_pc {
        display: block;
      }
      .for_mo {
        display: none;
      }
    }
    & > .wrap {
      display: flex !important;
    }
    .wrap {
      display: block;
      width: 100%;
      margin: 0;
      text-align: left;
      align-self: flex-end;
      & > .wrap {
        display: inline-block;
      }
    }
  }
  * {
    font-family: 'Pretendard', sans-serif;
  }
  &-title {
    display: inline-block;
    width: auto;
    font-size: 50rem;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    word-break: keep-all;
    white-space: nowrap;
    color: $--konai-black;
    &:not(:last-child) {
      margin-right: 35rem;
    }
    &.ir {
      .f-clr-standby {
        padding-left: 48px;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 38px;
          height: 38px;
          border-top: calc(19px * 1.732) solid transparent;
          border-left: 19px solid transparent;
          border-right: 19px solid transparent;
          border-bottom: calc(19px * 1.732) solid transparent;
          border-bottom-color: transparent;
          transform: translate(calc(-100% - 10px), -20px);
        }
      }
      .f-clr-increase {
        padding-left: 48px;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 38px;
          height: 38px;
          border-top: calc(19px * 1.732) solid transparent;
          border-left: 19px solid transparent;
          border-right: 19px solid transparent;
          border-bottom: calc(19px * 1.732) solid transparent;
          border-bottom-color: $--konai-increase;
          transform: translate(calc(-100% - 10px), -20px);
        }
      }
      .f-clr-decrease {
        padding-left: 48px;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 38px;
          height: 38px;
          border-top: calc(19px * 1.732) solid transparent;
          border-top-color: $--konai-decrease;
          border-left: 19px solid transparent;
          border-right: 19px solid transparent;
          border-bottom: calc(19px * 1.732) solid transparent;
          transform: translate(calc(-100% - 10px), 10px);
        }
      }
    }
  }
  &-text {
    display: inline-block;
    width: auto;
    font-size: 18rem;
    font-weight: 700;
    line-height: 1.88;
    text-align: left;
    word-break: keep-all;
    color: $--konai-black;
    &:not(:last-child) {
      margin-right: 20rem;
    }
    .f-clr-increase {
      margin-left: 8rem;
    }
    .f-clr-decrease {
      margin-left: 8rem;
    }
    &.ir {
      .f-clr-increase {
        padding-left: 21px;
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 16px;
          height: 16px;
          border-top: calc(8px * 1.732) solid transparent;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: calc(8px * 1.732) solid transparent;
          border-bottom-color: $--konai-increase;
          transform: translate(calc(-100% - 5px), -4px);
        }
      }
      .f-clr-decrease {
        padding-left: 21px;
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 16px;
          height: 16px;
          border-top: calc(8px * 1.732) solid transparent;
          border-top-color: $--konai-decrease;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: calc(8px * 1.732) solid transparent;
          transform: translate(calc(-100% - 5px), 10px);
        }
      }
    }
  }
  &-date {
    align-self: flex-end;
    display: inline-block;
    width: auto;
    font-size: 18rem;
    font-weight: 400;
    line-height: 1.88;
    text-align: right;
    word-break: keep-all;
    color: $--konai-gray-4;
  }
  .for_pc {
    display: block;
  }
  .for_mo {
    display: none;
  }
}

.tab-contents__inner[aria-for*='ir_'] {
  .table {
    &-elem {
      &.data {
        &-6 {
          tbody {
            tr {
              &:first-child {
                td {
                  span {
                    font-weight: 700;
                  }
                }
              }
              td {
                &:nth-child(2n-1) {
                  padding-left: 40px; padding-right: 0;
                }
                &:nth-child(2n) {
                  padding-right: 40px; padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .board-elem {
    .for_pc {
      display: table-cell;
    }
    .for_mo {
      display: none;
    }
    colgroup {
      .for_pc {
        display: table-column;
      }
    }
  }
}

.tab-contents__inner[aria-for*='pr_'] {
  .table {
    &-wrap {
      width: 100%;
      overflow: unset;
    }
    &-elem {
      width: 100%;
      margin-right: 0;
      tr {
        td {
          &:not(.date):not(a):not(button):not(.center) {
            & > a {
              transition: color 0.3s ease-in-out;
            }
          }
          &:hover {
            &:not(.date):not(a):not(button):not(.center) {
              & > a {
                color: $--konai-red;
              }
            }
          }
        }
      }
    }
  }
  .board-elem {
    .for_pc {
      display: table-cell;
    }
    .for_mo {
      display: none;
    }
    colgroup {
      .for_pc {
        display: table-column;
      }
    }
  }
}

// 미디어쿼리 @media
@import './pages/irpr_media';