.footer {
    position: relative;
    width: 100%;
    padding: 72px 0 64px;
    border-top: 1px solid $--konai-gray-6;
    background-color: $--konai-white;
    z-index: 6;
    .ios-bottom {
        position: fixed;
        width: 100%;
        height: auto;
        left: 0;
        bottom: 0;
        z-index: -1;
        pointer-events: none;
        background-color: $--konai-white;
        //&:after {
        //    content: '';
        //    position: absolute;
        //    display: block;
        //    width: 100%;
        //    height: 100%;
        //    bottom: 0;
        //    left: 0;
        //    transform: translate(0, 100%);
        //    background-color: $--konai-white;
        //    z-index: 5;
        //    pointer-events: auto;
        //}
    }
    & > * {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }
    .for_header {
        display: none;
    }
    .flex {
        position: relative;
        display: flex;
        &:not(.vert) > *:last-child {
            margin-left: auto;
        }
        &.vert {
            flex-direction: column;
        }
        &.top {
            & > .flex:first-child {
                max-width: 444px;
            }
        }
        &.mid {
            width: 100%;
            margin-top: 42px;
            .logo_img {
                display: block;
                background-size: contain;
                background-repeat: no-repeat;
                &.if {
                    width: 122px;
                    height: 60px;
                    background-image: var(--logo-ifaward);
                    background-position: right center;
                }
            }
        }
        &.btm {
            width: 100%;
            margin-top: 30px;
            padding-top: $--spacing-m;
            &::before {
                content: '';
                position: absolute;
                display: inline-block;
                width: 100%;
                height: 0.5px;
                margin-top: calc(-1 * $--spacing-m);
                background-color: $--konai-gray-6;
            }
        }
    }
    &__inner {
        * {
            font-size: 14rem;
            color: $--konai-gray-3;
        }
        .sub-menu {
            .sub-item {
                & > a {
                    transition: color 0.2s ease-in-out;
                    span {
                        transition: color 0.2s ease-in-out;
                    }
                    &:hover {
                        color: $--konai-red;
                        span {
                            color: $--konai-red;
                        }
                    }
                    &:not(:last-child) {
                        display: inline-block;
                        margin-bottom: 12rem;
                    }
                    span {
                        font-weight: 700;
                        color: $--konai-gray-3;
                    }
                }
                & + .sub-item {
                    margin-top: 12rem;
                    & > a {
                        display: inline-block;
                        margin-top: 6rem;
                    }
                }
                .child-menu {
                    .child-item {
                        & > a {
                            transition: color 0.2s ease-in-out;
                            span {
                                transition: color 0.2s ease-in-out;
                            }
                            &:hover {
                                color: $--konai-red;
                                span {
                                    color: $--konai-red;
                                }
                            }
                        }
                        & + .child-item {
                            margin-top: 12rem;
                        }
                    }
                }
                a, span {
                    font-family: 'Pretendard', sans-serif;
                    font-size: 14rem;
                    font-weight: 400;
                    color: $--konai-gray-2;
                }
            }
        }
        .ft_logo {
            display: block;
            .logo_img {
                display: block;
                width: auto;
                height: 20px;
                background-image: var(--logo-konai);
                background-size: contain;
                background-position: left center;
                background-repeat: no-repeat;
            }
            &:not(:last-child) {
                margin-bottom: $--spacing-m;
            }
        }
        .logo_text {
            display: flex;
            flex-direction: column;
            height: 100%;
            .comm_info {
                display: block;
                & > p {
                    font-size: 16rem;
                    font-weight: 400;
                    line-height: 1.38;
                    & + div {
                        margin-top: 0 !important;
                    }
                }
                p, a {
                    word-break: keep-all;
                }
                div {
                    display: block;
                    p, a {
                        display: inline-block;
                        font-size: 16rem;
                        font-weight: 400;
                        line-height: 1.38;
                        &:not(:first-child) {
                            margin-left: 10rem;
                        }
                    }
                    & + div {
                        margin-top: 8px !important;
                        & > a, & > a span {
                            font-size: 18rem;
                            font-weight: 700;
                            line-height: 1.11;
                            color: $--konai-gray-3;
                        }
                    }
                }
                p, a, div {
                    & + p, & + a, & + div {
                        margin-top: $--spacing-xs;
                    }
                }
            }
            .agree_info {
                display: inline-block;
                &:not(:first-child) {
                    margin-top: auto;
                }
                & > div {
                    display: flex;
                }
                & > *:not(:first-child) {
                    margin-top: 8px;
                }
                & > div[data-for="mo"]{
                    margin-top: 12px;
                    & > a {
                        &:not(:first-child) {
                            margin-left: 10px;
                        }
                    }
                }
                p, a, strong, .popupLink {
                    display: inline-block;
                    width: auto;
                    font-size: 16rem;
                    font-weight: 500;
                    line-height: 1.25;
                    color: $--konai-gray-3;
                    word-break: keep-all;
                    &.with_line {
                        &::before {
                            content: '';
                            display: inline-block;
                            width: 1px;
                            min-width: 1px;
                            height: 11px;
                            margin: 0 7px;
                            background-color: $--konai-gray-3;
                            pointer-events: none;
                        }
                    }
                }
                .snsLink {
                    width: 20px;
                    height: 20px;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    &.facebook {
                        background-image: var(--icon-sns-facebook);
                    }
                    &.blog {
                        background-image: var(--icon-sns-blog);
                    }
                }
                strong {
                    font-weight: 800;
                    color: $--konai-red;
                }
            }
        }
        .icon {
            &-shortcut {
                display: inline-flex !important;
                align-items: center;
                width: auto;
                height: auto;
                font-weight: 700;
                & + .icon-shortcut {
                    margin-left: 12rem !important;
                }
                &::after {
                    content: '';
                    display: inline-block;
                    width: 18px;
                    min-width: 18px;
                    height: 18px;
                    min-height: 18px;
                    margin-left: $--spacing-xs;
                    background-image: var(--icon-shortcut);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        .ft_menu {
            display: block;
            padding-left: $--spacing-l;
            .menu_ul {
                display: flex;
                justify-content: space-between;
                width: 603px;
                height: auto;
                & > .menu-item {
                    display: block;
                    width: auto;
                    height: auto;
                    font-family: 'Konai', sans-serif;
                    & > a {
                        padding: 0;
                        cursor: default;
                        pointer-events: none;
                        font-size: 16rem;
                        font-weight: 400;
                        line-height: 1.2;
                        color: $--konai-gray-3;
                        span {
                            font-size: 16rem;
                            font-weight: 400;
                            line-height: 1.2;
                            color: $--konai-gray-3;
                        }
                        & + .sub-menu {
                            margin-top: 18rem;
                        }
                    }
                    .sub-menu {
                        display: block;
                        width: auto;
                        height: auto;
                        margin-top: $--spacing-m;
                        & > .menu-item {
                            font-family: 'Pretendard', sans-serif;
                            &:not(:first-child) {
                                margin-top: $--spacing-sm;
                            }
                        }
                    }
                }
            }
        }
        .with_shortcut {
            display: flex;
            align-items: center;
            &:after {
                content: '';
                display: inline-block;
                width: 11px;
                height: 11px;
                margin-left: 3px;
                background-image: var(--icon-shortcut-l-g);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        .copy_right {
            display: block;
            text-align: left;
        }
        .select-wrap.for_footer {
            display: inline-flex;
            flex-direction: column;
            width: auto;
            height: 32px;
            overflow: visible;
            .btn {
                &-input {
                    display: none;
                    position: absolute;
                    width: 0;
                    height: 0;
                    &:checked {
                        & + .btn-select {
                            &::after {
                                background-image: var(--icon-arr-dn-16-b);
                            }
                        }
                        & ~ .option-list {
                            max-height: 800px;
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }
                }
                &-select {
                    display: inline-flex;
                    justify-content: space-between;
                    min-width: 167px;
                    min-height: 32px;
                    padding: $--spacing-s $--spacing-sm $--spacing-s $--spacing-m;
                    background-color: $--konai-lightgray-2;
                    border-radius: 32px;
                    font-weight: 600;
                    outline: 0;
                    border: 0;
                    word-break: keep-all;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    cursor: pointer;
                    &::after {
                        content: '';
                        display: inline-block;
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                        margin-left: $--spacing-s;
                        background-image: var(--icon-arr-up-16-b);
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }
            .option-list {
                position: absolute;
                display: block;
                width: inherit;
                min-width: 167px;
                height: auto;
                max-height: 0;
                padding: $--spacing-xm $--spacing-m;
                transform: translate(0, calc(-100% - 8px));
                background-color: $--konai-lightgray-2;
                border-radius: 8px;
                opacity: 0;
                overflow: hidden;
                pointer-events: none;
                transition: max-height 0.5s ease-in-out;
                .btn {
                    &-option {
                        width: 100%;
                        height: auto;
                        padding: $--spacing-s 0;
                        word-break: keep-all;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        & > a {
                            display: inline-block;
                            width: 100%;
                        }
                        &:not(:first-child) {
                            border-top: 1px solid $--konai-gray-6;
                        }
                    }
                }
            }
        }
    }
    &-attached {
        position: relative;
        display: flex;
        width: 100%;
        height: 220rem;
        margin: 0;
        //padding: 0 200px;
        padding: 0 calc(100vw * 0.104167);
        bottom: 0;
        z-index: 6;
        background: linear-gradient(to right, #FF3C42 0%, #360D9E 100%);
        &.red {
            background: linear-gradient(to right, #FF3C42 50%, #FF7276 100%);
        }
        &__inner {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: 1200rem;
            height: auto;
            margin: auto;
        }
        &-title {
            font-size: 34rem;
            font-weight: 600;
            line-height: 1.65;
            text-align: left;
            color: $--konai-white;
        }
    }
    .btn {
        &.goto_top {
            position: fixed;
            width: 60rem;
            height: 60rem;
            right: 40rem;
            bottom: 50rem;
            border-radius: 60rem;
            box-shadow: 0 1px 5px rgba(0,0,0,0.2);
            background-image: var(--btn-goto-top);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
            cursor: default;
            z-index: 9;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            &.reveal {
                pointer-events: auto;
                cursor: pointer;
                opacity: 1;
            }
        }
    }
}

// PC SET
@media screen and (min-width: 1240px) {
    .footer {
        &-attached {
            .for_mo {
                display: none;
            }
        }
        .menu_mo, .hamburger-menu {
            display: none !important;
        }
        &__inner {
            div[data-for='mo']{
                display: none !important;
            }
            .agree_info {
                margin-top: 32px !important;
            }
        }
    }
}

// MO SET
@media screen and (max-width: 1239px) {
    .footer {
        padding: 48rem 24rem 30rem !important;
        &-attached {
            .for_mo {
                display: block;
            }
        }
        &__inner {
            .icon {
                &-shortcut {
                    font-size: 16rem !important;
                    & + .icon-shortcut {
                        margin-left: 8rem !important;
                    }
                    &::after {
                        width: 15px;
                        min-width: 15px;
                        height: 15px;
                        min-height: 15px;
                    }
                }
            }
            .ft_menu {
                display: none !important;
            }
            * {
                font-size: 14rem;
                // line-height: 1.67 !important;
            }
            .flex {
                width: 100%;
            }
            .flex.top {
                .ft_logo {
                    width: 100%;
                    height: auto;
                    .logo_img {
                        height: 16rem !important;
                    }
                    &:not(:last-child) {
                        margin-bottom: $--spacing-xm;
                    }
                }
                .comm_info {
                    margin-bottom: 36rem;
                    * {
                        line-height: 1.43;
                    }
                    & > p {
                        font-size: 14rem;
                        font-weight: 400;
                        line-height: 1.5;
                    }
                    div {
                        display: flex;
                        p, a {
                            font-size: 14rem;
                            font-weight: 400;
                            line-height: 1.5;
                            &:not(:first-child) {
                                margin-top: 0;
                                //margin-left: auto;
                                margin-left: 16rem;
                            }
                        }
                    }
                    p, a, div {
                        & + p, & + a, & + div {
                            margin-top: 2rem;
                        }
                    }
                }
                .snsLink {
                    display: none;
                    width: 20rem;
                    height: 20rem;
                }
            }
            .flex.mid {
                margin-top: 36px;
                .logo_img {
                    &.if{
                        height: 50rem;
                        margin-left: 0;
                        margin-right: auto;
                        background-position: left center;
                    }
                }
            }
            .flex.btm {
                flex-direction: column-reverse !important;
                margin-top: 0 !important;
                padding-top: 0 !important;
                &::before {
                    display: none !important;
                }
                .select-wrap {
                    width: 100%;
                    height: 45rem !important;
                    margin-top: 36rem !important;
                    .btn-select {
                        align-items: center;
                        min-height: 45rem !important;
                        padding: 8rem 12rem 8rem 16rem !important;
                        &::after {
                            width: 16rem;
                            min-width: 16rem;
                            height: 16rem;
                            margin-left: 8rem;
                        }
                    }
                    .option-list {
                        max-width: unset;
                        .btn-option {
                            display: flex;
                            align-items: center;
                            min-height: 37rem;
                            max-height: 37rem;
                            font-size: 12rem;
                        }
                    }
                }
                .copy_right {
                    margin-top: 20rem;
                    font-size: 12rem;
                    font-weight: 400 !important;
                }
            }
            .comm_info {
                & > p {
                    font-size: 14rem;
                    font-weight: 400;
                    line-height: 1.43;
                }
                div {
                    p, a {
                        font-size: 14rem;
                        font-weight: 400;
                        line-height: 1.43;
                    }
                    & + div {
                        margin-top: 4px !important;
                        & > a, & > a span {
                            font-size: 16rem !important;
                            font-weight: 700 !important;
                            line-height: 1.25 !important;
                        }
                    }
                }
            }
            .agree_info {
                p, a, strong, .popupLink {
                    font-size: 14rem !important;
                    line-height: 1.43 !important;
                }
            }
        }
        //&-attached:not(.red) {
        //    display: none;
        //}
        &-attached {
            height: 302rem;
            padding: 0 24rem;
            &__inner {
                flex-direction: column;
                max-width: unset;
                & > *:not(:first-child) {
                    margin-top: 30rem;
                }
            }
            &-title {
                font-size: 25rem;
                font-weight: 600;
                line-height: 1.52;
                text-align: center;
                word-break: keep-all;
            }
        }
        .for_pc {
            display: none;
        }
    }
}